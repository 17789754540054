import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Card from "react-bootstrap/Card";
import Modal from "@mui/material/Modal";
import QRCode from "react-qr-code";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import moment from "moment";

const axios = require("axios");

export default function ViewHistoryCard() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dsList, setDSList] = React.useState([]);
  
  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const params = useParams();

  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);

  const columns = [
    {
      id: "id",
      label: "Sr. No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "ulrNo",
      label: "ULR No.",
      align: "left",
      minWidth: 40,
    },
    {
      id: "certificateNumber",
      label: "Certificate Number",
      align: "left",
      minWidth: 40,
    },
    {
      id: "instrumentName",
      label: "Instrument Name",
      align: "left",
      minWidth: 40,
    },
    {
      id: "range",
      label: "Range",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "lc",
      label: "LC",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "locationOfInstrument",
      label: "Location Of Instrument",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "calDate",
      label: "Calibration Date",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
    {
      id: "nextDueDate",
      label: "Due Date",
      minWidth: 40,
      align: "left",
      format: (value) => value.toFixed(2),
    },
  ];

  const getDSList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=cert.certificates,_j,inst.instruments&_on1=(cert.srfInstrumentId,eq,inst.id)&_fields=cert.DUCID,inst.instrumentName,cert.calibrationDate,cert.id,inst.id,cert.locationOfInstrument"
      )
      .then((res) => {
        var finalArr = [];
        const splitIdArr = params.id.split(",");
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < splitIdArr.length; j++) {
            if (parseInt(splitIdArr[j]) == res.data[i].cert_id) {
              finalArr.push(res.data[i]);
            }
          }
        }
        setDSList(finalArr);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getDSList();
  }, []);

  const qrModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const renderQrModal = () => {
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={qrModalStyle}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            QR Code of Certificate
          </Typography>
          <hr />
          <div align="center">
            <QRCode value={qrCodeUrl} align="center" />
            <hr />
            <Button size="small" align="center">
              Print
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <h4 style={{ float: "center", padding: "10px" }}>Print Preview</h4>
      </TableContainer>
      <Card className="mt-3">
        <Card.Header class="text-center">
          <h2 style={{ color: "red" }}>Business Finder Infotech</h2>
          <br />
          <p style={{ fontSize: "15px" }}>
            Office No: 120, Vindya Commercial complex, Sector-11, Cbd Belapur,
            Navi Mumbai, Maharashtra
          </p>
        </Card.Header>
        <hr />
        <Card.Body>
          <h4 className="text-center">
            <u>Calibration Details</u>
          </h4>
          <br />
          <h4 className="text-center">
            <b>{dsList.length ? dsList[0].client_companyName : ""}</b>
          </h4>         
          <br />
          <Table
            stickyHeader
            aria-label="sticky table"
            class="table-responsive"
            size="small"
            id="datatable-keytable"
            style={{ textAlign: "center" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {dsList &&
                dsList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell>{index+1}</TableCell>
                        <TableCell>ULR12123</TableCell>
                        <TableCell>{row.cert_certificateNumber}</TableCell>
                        <TableCell>{row.inst_instrumentName}</TableCell>
                        <TableCell>{row.cert_ranges}</TableCell>
                        <TableCell>{row.cert_lc}</TableCell>
                        <TableCell>{row.inst_instrumentName}</TableCell>
                        <TableCell>
                          {row.cert_calibrationDate
                            ? moment(row.cert_calibrationDate).format(
                                "DD-MM-YYYY"
                              )
                            : ""}
                        </TableCell>
                        <TableCell>
                          {row.cert_nextDueDate
                            ? moment(row.cert_nextDueDate).format("DD-MM-YYYY")
                            : ""}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <br />
        </Card.Body>
      </Card>
      {renderQrModal()}
    </>
  );
}
