import { MAX_COLS } from "../../global";

const base = 1; // 1 minus actual columns for 0 based indexing

const maxColumns = MAX_COLS;

const getStandardRangeValue = (
  columnKey,
  allStandards,
  standardRangeId,
  stdRangePref = 0
) => {
  standardRangeId =
    standardRangeId?.split(",")[stdRangePref > 0 ? stdRangePref - 1 : 0];

  let range = allStandards.filter(
    (range) => range.pr_id == Number(standardRangeId)
  );
  // TODO: value and unit format should be same everywhere
  let value = null;
  switch (columnKey) {
    case "LC":
      value = range[0]?.pr_gravity ? range[0]?.pr_gravity.split("#")[0] : range[0]?.pr_gravity;
      break;
    case "accuracy":
      value = range[0]?.pr_accuracy ? range[0]?.pr_accuracy.split("#")[0] : range[0]?.pr_accuracy;
      break;
    case "all":
      value = range[0]
      break;
    default:
      value = null;
  }
  return value;
};

function resolveFormulaConditions(
  formulas,
  config,
  datasheetId,
  referenceData,
  tableIndex
) {
  let selectedFormulas = {};
  Object.keys(formulas).forEach((column) => {
    if (typeof formulas[column] === "string") {
      selectedFormulas[column] = formulas[column];
    } else {
      Object.keys(formulas[column]).forEach((condition) => {
        if (condition != "default") {
          // validate condition
          let tokens = condition.split(" ");
          // for single comparison
          if (tokens.length === 3) {
            let lhs = tokens[0].split(".");
            let rhs =
              typeof tokens == "string" ? tokens[2].split(".") : tokens[2];

            // find LHS value
            let lhs_val = null;
            if (lhs.length === 1) {
              lhs_val = lhs;
            } else if (lhs.length === 2) {
              lhs_val = referenceData[lhs[0]]?.[lhs[1]];
            } else if (lhs.length === 3) {
              lhs_val = referenceData[lhs[0]]?.[lhs[1]];
              lhs_val = lhs_val?.split("||");
              if (lhs[2] === "unit" && lhs_val) {
                lhs_val = lhs_val[tableIndex]?.split("#")[1];
              }
            }

            // find RHS value
            let rhs_val = null;
            if (typeof rhs == "string") {
              rhs_val = rhs;
            } else {
              if (rhs.length === 1) {
                rhs_val = rhs[0];
              } else if (rhs.length === 2) {
                rhs_val = referenceData[rhs[0]][rhs[1]];
              } else if (rhs.length === 3) {
                rhs_val = referenceData[rhs[0]][rhs[1]];
                if (rhs[2] === "unit") {
                  rhs_val = rhs_val.split(" ")[1];
                }
              }
            }

            if (lhs_val == rhs_val) {
              selectedFormulas[column] = formulas[column][condition];
            }
          }
        }
      });
      if (!selectedFormulas[column]) {
        selectedFormulas[column] = formulas[column]["default"];
      }
    }
  });

  return selectedFormulas;
}

const resolveReferenceTableColumns = (
  formula,
  element,
  referenceData,
  allStandards
) => {
  // 1. resolve standard ranges table cols
  formula = String(formula)?.toLowerCase();
  if (formula?.includes("standardranges")) {
    let fullColumn = formula.match(/standardranges.[a-zA-Z.]+/);
    fullColumn?.forEach((column) => {
      let value = 0;
      switch (column.split(".")?.[1]) {
        case "accuracy":
          value = getStandardRangeValue(
            "accuracy",
            allStandards,
            element[element.length - 2]
          );
          value = String(value)?.split("#")?.[0]
          break;
        default:
          value = 0;
      }
      formula = formula.replace(column, value);
    });
  }

  return formula;
};

function resolveFormulas(
  readings,
  config,
  precisionCount,
  datasheet,
  referenceData,
  allStandards
) {
  config.forEach((config) => {
    let tableId = config.tableId;
    let formulas = config.formulas;
    let tableIndex = config.index;

    formulas = resolveFormulaConditions(
      formulas,
      config,
      datasheet.id,
      referenceData,
      tableIndex
    );

    Object.keys(formulas).forEach((column, rIndex) => {
      let index = Number(column.split("c")[1]) + base;
      for (const element of readings) {
        // skip for units
        if (String(element[index])?.includes("_unit_")) continue;

        let formula = formulas[column]?.toLowerCase();
        if (element[0] === tableId) {
          // replace table columns with actual values
          formula = resolveReferenceTableColumns(
            formula,
            element,
            referenceData,
            allStandards
          );

          // replace datasheet fields with values
          Object.keys(datasheet || {}).forEach((key) => {
            if (formula.includes(key)) {
              let dsKey = datasheet[key];
              // pick 1 value from multi values if any
              dsKey = String(dsKey)?.replace("\n", "").split("||");
              dsKey = dsKey[tableIndex] || dsKey[0];
              formula = formula.replaceAll(key, Number(dsKey) || 0);
            }
          });

          let level = 1;
          let isInit = true;
          do {
            // replace column representaitons with values
            for (let j = maxColumns - 1; j >= 0; j--) {
              let _formula = formula;
              if (formula?.includes("c" + j)) {
                _formula = formula.replaceAll("c" + j, element[j + base]);
              }
              if (isInit && formula !== _formula) level += 1;
              formula = _formula;
            }

            isInit = false;

            formula = formula.replaceAll("$", "");
            // resolve -- terms
            formula = formula.replaceAll("--", "+");
            // redefine math formulas
            for (const val of ["sqrt", "pow", "max", "min"]) {
              formula = formula.replaceAll(val, "Math." + val);
            }
            formula = formula.replaceAll(":", ",")

            // resolve custom functions
            if(formula.includes("avg")){
              let token = formula.match(/avg(.)/)
              token = token?.input
              let values = token.substring(4, token.length-1)
              values = values?.split(",")
              let sum = 0
              let count = 0
              values.forEach(value =>{
                if(value != "" && Number(value) == value){
                  sum += Number(value)
                  count += 1
                }
              })
              let avg = sum / count
              formula = formula.replace(token, avg)
            }

            try {
              // eslint-disable-next-line no-eval
              if (config.customPrecision[column].split(".")[0] == 7) {
                element[index] = Number(eval(formula)).toString();
                let elm = element[index].split(".")
                if (elm.length > 1 && elm[1].length > 4) {
                  elm[1] = elm[1].slice(0, 4)
                  element[index] = elm.join(".")
                }
              }
              else
                element[index] = Number(eval(formula)).toFixed(precisionCount);
            } catch {
              // do not update value
            }
            level--;
          } while (level > 0);
        }
      }
    });
  });
  return readings;
}

const resolvePrecision = (readings, config, datasheet, allStandards, uncertaintyIndex) => {
  config.forEach((config) => {
    let tableId = config.tableId;
    let tableIndex = config.index;
    let customPrecision = config.customPrecision;

    Object.keys(customPrecision || {})?.forEach((column) => {
      let index = -1
      if(column === "uncertainty")
        index = readings[0]?.length + uncertaintyIndex
      else
        index = Number(column.split("c")[1]) + base;

      for (const element of readings) {
        // skip for invalid cases
        if (element[0] != tableId) continue;
        if (String(element[index])?.includes("_unit_")) continue;
        if (String(element[index])?.includes("_rh_")) continue;

        // gather data of right side columns
        let r_value = String(customPrecision[column]).split(".");
        let rValue = 0;
        let rValueDecimalCount = 0;

        if (r_value[0] != 7) {
          if(r_value[0].includes("manual")){
            rValueDecimalCount = Number(r_value[1])
          }
          else{
            if (r_value[0].includes("ds")) {
              r_value = r_value[0].split("ds")[1].split(".")[0]; //eg r_value: ds1.1, ds2.null
              rValue = element[Number(r_value) + base];
            } else {
              let rIndex = Number(r_value[0]);
              let stdRangePref = Number(r_value[1] || "0");
              if (r_value[0] == 6) {
                rValue = [datasheet.lc,
                  (getStandardRangeValue(
                    "LC",
                    allStandards,
                    element[element.length - 2],
                    stdRangePref
                  ) || "0.0")]
              } else {
                rValue = [
                  0,
                  0,
                  datasheet.lc,
                  getStandardRangeValue(
                    "LC",
                    allStandards,
                    element[element.length - 2],
                    stdRangePref
                  ) || 0,
                  datasheet.accuracy,
                ][rIndex - 1];
              }
            }
            if (r_value[0] == 6) {
              rValue[0] = String(rValue[0])?.replace("\n", "").split("||")
              rValue[0] = rValue[0]?.length > tableIndex ? rValue[0][tableIndex] : rValue[0][0];
              rValue =  ((rValue[0].split(".")[1]  || "").length > (rValue[1].split(".")[1] || "").length) ? rValue[0] : rValue[1];
            } else {
              // pick 1 value from multi values if any
              rValue = String(rValue)?.replace("\n", "").split("||");
              rValue = rValue?.length > tableIndex ? rValue[tableIndex] : rValue[0];
            }
  
            // update reading col's value's precision according to soruce column
            rValueDecimalCount = 0;
            if (String(rValue).includes(".")) {
              rValueDecimalCount = String(rValue).split(".")[1];
              rValueDecimalCount = rValueDecimalCount.split("#")[0].length;
            }
          }
        } 

        element[index] = String(element[index]).replaceAll("$", "")
        if (r_value[0] == 7)
          element[index] = Number(element[index])
        else
          element[index] = Number(element[index]).toFixed(rValueDecimalCount);
      }
    });
  });
  return readings;
};

// datasheet ====================
export function prepareDatasheetReadings(props) {
  let {
    config,
    readings,
    uncertaintyIndex,
    precisionCount,
    datasheet,
    allStandards,
    referenceData,
  } = props;
  readings = resolveFormulas(
    readings,
    config,
    precisionCount,
    datasheet,
    referenceData,
    allStandards
  );
  return resolvePrecision(readings, config, datasheet, allStandards, uncertaintyIndex);
}

// certificates =================
function resolveRelations(datasheetReadings, config) {
  let certificateReadings;

  // init cert. readings
  let allCertificateReadings = [];
  config.forEach((config) => {
    let tableId = config.tableId;
    let totalColumns = Number(config.totalColumns);
    let relations = config.relations;

    certificateReadings = datasheetReadings.map((datasheetReading) => {
      if (datasheetReading[0] == tableId) {
        let certificateReading = [
          datasheetReading[0],
          datasheetReading[1],
          ...new Array(totalColumns).fill(null),
          0,
        ];

        Object.keys(relations || {}).forEach((key, rIndex) => {
          let leftIndex = Number(key.split("c")[1]);
          let rightIndex = Number(relations[key].split("c")[1]);
          certificateReading[leftIndex + base] =
            datasheetReading[rightIndex + base];
        });
        return certificateReading;
      }
    });
    allCertificateReadings.push(...certificateReadings);
  });

  certificateReadings = allCertificateReadings.filter(
    (row) => row != undefined
  );

  return certificateReadings;
}

export function prepareCertificateReadings(props) {
  let { config, datasheetReadings } = props;
  //   initiate rows

  let updatedReadingRows = resolveRelations(datasheetReadings, config);
  // TODO: cross check: whether we really need formula in cert? then only uncomment
  // return resolveFormulas(updatedReadingRows, config, precisionCount)
  return updatedReadingRows;
}

// typeB ==============================
export function prepareTypeBValues(typeBConfiguration, datasheetReading) {
  let typeBValues = {};
  Object.keys(typeBConfiguration).forEach((key) => {
    // structure whould be {uncertaintyFactor : value, ...}
    typeBValues[typeBConfiguration[key]] = datasheetReading[key];
  });
  return JSON.stringify(typeBValues);
}
