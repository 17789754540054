import React from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import Rating from "@mui/material/Rating";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
const axios = require("axios");

export default function CreateFeedback() {
  const [srfObject, createSrfObject] = React.useState({});
  const [executing, setExecuting] = React.useState(false);
  const [value1, setValue1] = React.useState("");
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const [value4, setValue4] = React.useState("");
  const [value5, setValue5] = React.useState("");
  const [value6, setValue6] = React.useState("");
  const [value7, setValue7] = React.useState("");
  const [value8, setValue8] = React.useState("");
  const [firstCol, setFirstCol] = React.useState("");
  const [secondCol, setSecondCol] = React.useState("");

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    setExecuting(true);

    let row = {
      date: srfObject.date
        ? moment(srfObject.date).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      name: srfObject.name,
      contactNumber: srfObject.contactNumber,
      p1: value1,
      p2: value2,
      p3: value3,
      p4: value4,
      p5: value5,
      p6: value6,
      p7: value7,
      p8: value8,
      firstCol: firstCol,
      secondCol: secondCol,
    };

    let url = BASE_URL;
    axios
      .post(url + "feedback", row)
      .then((res) => {
        toast("Feedback created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Feedback
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.date ? srfObject.date : new Date()}
              onChange={(newValue) => {
                updateSrfObject("date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("name", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Contact Number"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("contactNumber", e.target.value);
            }}
          />
        </Grid>{" "}
      </Grid>
      <br />
      <div className="border-dark p-5 border">
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">QUALITY OF SERVICE</Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value1}
              onChange={(event, newValue) => {
                setValue1(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              TIMELY DELIVERY OF CALIBRATED INSTRUMENTS
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value2}
              onChange={(event, newValue) => {
                setValue2(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              TIMELY DELIVERY OF CALIBRATED CERTIFICATES
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value3}
              onChange={(event, newValue) => {
                setValue3(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">CALIBRATION REPORTS</Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value4}
              onChange={(event, newValue) => {
                setValue4(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              ENGINEERS PERFORMANCE AT SITE
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value5}
              onChange={(event, newValue) => {
                setValue5(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              RESPONSE TIME OF YOUR ENQUIRIES
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value6}
              onChange={(event, newValue) => {
                setValue6(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              CONFORT LEVEL IN DEALING WITH NAGMAN
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value7}
              onChange={(event, newValue) => {
                setValue7(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">PACKING AND DISPATCH</Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={value8}
              onChange={(event, newValue) => {
                setValue8(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
      </div>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="If rated below 3 (Either 2 or 1) please inform us the reason"
            rows={3}
            InputLabelProps={{ shrink: true }}
            multiline
            value={firstCol}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setFirstCol(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Suggestions for improvement in quality and services"
            rows={3}
            InputLabelProps={{ shrink: true }}
            multiline
            value={secondCol}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setSecondCol(e.target.value)}
          />
        </Grid>
      </Grid>
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          disabled={executing}
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            onSubmit();
          }}
        >
          Save Feedback
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
