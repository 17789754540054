import { BASE_URL } from '../../global'
import axios from 'axios'


export const generateAudit = (tablename, time, user, action) => {
    axios
        .post(BASE_URL + "/audit", {
            tablename,
            time,
            user,
            action
        })
        .then(res => {
        console.log("audit log generated successful!")
        })
    .catch(err => console.log("audit error : ", err))
}


export default generateAudit;