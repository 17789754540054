import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button
} from "@mui/material";
const axios = require("axios");

export default function UpdateUnit(props) {
  const [disciplineId, setDisciplineId] = React.useState("");
  const [symbol, setSymbol] = React.useState("");
  const [dId, setDiscID] = React.useState("");
  const [disciplinesArray, setDisciplinesArray] = React.useState([]);

  const params = useParams();

  const getUnitList = () => {
    let url = BASE_URL;
    axios
      .get(url + `xjoin?_join=units.unit,_j,disc.discipline&_on1=(units.disciplineId,eq,disc.id)&_fields=units.symbol,disc.name,units.id,disc.id&_where=(units.id,eq,${params.id})`)
      .then((res) => {
        console.log(res.data)
        setSymbol(res.data[0].units_symbol);
        setDiscID(res.data[0].disc_id)
        setDisciplineId(
          res.data[0].disc_id + ", " + res.data[0].disc_name
        );
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getDisciplineList = () => {
    let url = BASE_URL;
    axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        setDisciplinesArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };


  useEffect(() => {
    getUnitList();
    getDisciplineList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    var jsonData = {
      disciplineId: disciplineId,
      symbol: symbol
    };

    let url = BASE_URL;

    axios
      .patch(url + `unit/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("Updated Unit!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Unit
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={disciplineId}
            options={disciplinesArray.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              setDisciplineId(parseInt(newInputValue.split(",")[0]))
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Symbol *"
            size="small"
            fullWidth
            variant="outlined"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
