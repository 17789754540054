import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const axios = require("axios");

export default function CreateProduct(props) {
  const navigate = useNavigate();

  var [instrumentArray, setInstrumentArray] = React.useState([]);
  const [prName, setPrName] = React.useState("");
  const [prRate, setPrRate] = React.useState("");

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getInstrumentsList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
    navigate("/master/product");
  };

  const saveProduct = (event) => {
    let row = {
      productName: prName,
      rate: prRate,
    };

    let url = BASE_URL;

    axios
      .post(url + "customProducts", row)
      .then((res) => {
        props.setLoader(false);
        toast("custom product created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Product
      </Typography>
      
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Product Name *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setPrName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Rate *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setPrRate(e.target.value);
              }}
            />
          </Grid>        
      
        </Grid>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            props.setLoader(true);
            saveProduct();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
