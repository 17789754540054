import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";

import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import Tooltip from "@mui/material/Tooltip";
const _clientId = localStorage.getItem("clientId");
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "clientName", label: "Company Name", minWidth: 40 },
  { id: "challanNo", label: "Challan No", minWidth: 40 },
  {
    id: "dcNumber",
    label: "DC Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "dcDate",
    label: "DC Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(id, clientName, challanNo, dcNumber, dcDate) {
  return {
    id,
    clientName,
    challanNo,
    dcNumber,
    dcDate,
  };
}

export default function ChallanList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [challanList, setChallanList] = React.useState([]);
  const [challansData, setChallansData] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `challanMaster/${id}`, jsonData)
      .then((res) => {
        toast("Delete Challan!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getChallansList = (event) => {
    let url = BASE_URL;
    if (userType == "1" && userType) {
      axios
        .get(
          url +
            `xjoin?_join=challan.challanMaster,_j,client.clients,_j&_on1=(client.id,eq,challan.clientId)&_fields=client.companyName,challan.challanNo,challan.challanDate,challan.dcNumber,challan.dcDate,challan.id&_where=(challan.status,eq,1)`
        )
        .then((res) => {
          initializeDataTable();
          setChallanList(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(
          url +
            `xjoin?_join=challan.challanMaster,_j,client.clients,_j&_on1=(client.id,eq,challan.clientId)&_fields=client.companyName,challan.challanNo,challan.challanDate,challan.dcNumber,challan.dcDate,challan.id&_where=(invoice.clientId,eq,${_clientId})`
        )
        .then((res) => {
          initializeDataTable();
          setChallanList(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initiatChallanList = () => {
    var rowData = [];
    for (let i = 0; i < challanList.length; i++) {
      rowData.push(
        createData(
          challanList[i].challan_id,
          challanList[i].client_companyName,
          challanList[i].challan_challanNo,
          challanList[i].challan_dcNumber,
          challanList[i].challan_dcDate
            ? moment(challanList[i].challan_dcDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }
    setChallansData(rowData);
  };

  useEffect(() => {
    initiatChallanList();
  }, [challanList]);

  useEffect(() => {
    getChallansList();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/challan/createChallan"
        >
          ADD NEW DELIVERY CHALLAN
        </Button>
      </Toolbar>
      <hr />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {challansData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Invoice" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/challan/editChallan/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Challan" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete Challan?")
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={challansData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
