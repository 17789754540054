import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
const axios = require("axios");

export default function CreateEnquiry(props) {
  const [srfObject, createSrfObject] = React.useState({});
  const [clientArray, setClientArray] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [checkedClient, setChecked] = React.useState(false);
  const [companyName, setCompanyName] = React.useState("");
  const [clientAddress, setClientAddress] = React.useState("");
  const userId = localStorage.getItem("id");
  const refresh = () => {
    window.location.reload(false);
  };

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const onSubmit = (event) => {
    let row = {
      clientId: srfObject.clientId,
      contactPersonName: srfObject.contactPersonName,
      emailId: srfObject.emailId,
      followUpDate: moment(srfObject.followUpDate).format("YYYY-MM-DD"),
      date: moment(srfObject.date).format("YYYY-MM-DD"),
      remark: srfObject.remark,
      rating: srfObject.rating,
      createdby: userId,
    };
    let url = BASE_URL;
    axios
      .post(url + "enquiry", row)
      .then((res) => {
        props.setLoader(false);
        toast("Enquiry created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  const saveClient = (event) => {
    let row = {
      companyName: companyName,
      contact: contact,
      address: clientAddress,
    };

    let url = BASE_URL;

    axios
      .post(url + "clients", row)
      .then((res) => {
        toast("client created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
      contact: clientArray[i].contact,
    });
  }

  useEffect(() => {
    getClientIdList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create New Enquiry
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "clientId",
                parseInt(newInputValue.split(",")[0])
              );
              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue.split(",")[0])) {
                  if (client.address) {
                    setAddress(client.address);
                    setContact(client.contact);
                  } else {
                    setAddress("");
                    setContact("");
                  }
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address *"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={address}
            variant="outlined"
            disabled
          />
        </Grid>{" "}
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Contact No."
            size="small"
            fullWidth
            value={contact}
            disabled
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
        </Grid>{" "} */}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Contact Person Name *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("contactPersonName", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="email Id *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("emailId", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("remark", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            renderInput={(params) => <TextField {...params} label="Rating" />}
            id="combo-box-demo"
            options={[
              {
                id: 1,
                label: "HOT",
              },
              {
                id: 2,
                label: "COLD",
              },
              {
                id: 3,
                label: "NOT INTERESTED",
              },
            ]}
            onInputChange={(event, newInputValue) => {
              console.log(newInputValue);
              updateSrfObject("rating", newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={srfObject.date ? new Date(srfObject.date) : ""}
              onChange={(newValue) => {
                updateSrfObject("date", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Followup Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                srfObject.followUpDate ? new Date(srfObject.followUpDate) : ""
              }
              onChange={(newValue) => {
                updateSrfObject("followUpDate", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
              />
            }
            label="Create client"
          />
        </Grid>
      </Grid>
      <br />
      {checkedClient == true ? (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Company Name *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setCompanyName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Contact No. *"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setContact(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="outlined-basic"
              label="Address"
              size="small"
              InputLabelProps={{ shrink: true }}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                setClientAddress(e.target.value);
              }}
            />
          </Grid>
          <Toolbar style={{ padding: "15px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={() => {
                saveClient();
              }}
            >
              Save
            </Button>
          </Toolbar>
        </Grid>
      ) : (
        ""
      )}
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
