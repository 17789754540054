import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseIcon from '@mui/icons-material/Close';

import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ClassicTable } from "./../../utils/components/Styles";
const axios = require("axios");

export default function ViewDispatch() {
  const [companyName, setCompanyName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [instruments, setInstruments] = React.useState("");
  const [dispatchMedium, setDispatchMedium] = React.useState("");
  const [dispatchDate, setDispatchDate] = React.useState("");
  const [dispatchData, serDispatchData] = React.useState([]);
  const [instrumentData, setInstrumentsData] = React.useState([]);
  const [instrumentQnt, setInstrumentQnt] = React.useState(0);

  const params = useParams();

  const getDispatchIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + `dispatch/${params.id}`)
      .then((res) => {
        setCompanyName(res.data[0].companyName);
        setAddress(res.data[0].address);
        setInstruments(res.data[0].instruments);
        setDispatchMedium(res.data[0].dispatchMedium);
        setDispatchDate(res.data[0].dispatchDate);

        var instrumentQnt = [];
        var instruments = [];
        res.data.map((instrument) => {
          instrumentQnt.push(res.data[0].instrumentIds.split(","));
          instruments.push(res.data[0].instrumentIds.split(","));
        });
        setInstrumentQnt(instrumentQnt[0].length);
        serDispatchData(res.data);
        axios
          .get(url + `instruments/bulk?_ids=${instruments.toString()}`)
          .then((res) => {
            setInstrumentsData(res.data);
          })
          .catch((error) => {
            toast.error("Something Went Wrong!");
          });
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getDispatchIdList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div>
        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
          Dispatch Report
        </Typography>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Instrument Quantity</TableCell>
                <TableCell>Dispatched Counter</TableCell>
                <TableCell>Pending</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>{params.instQnt}</TableCell>
                <TableCell>{instrumentQnt}</TableCell>
                <TableCell>{params.instQnt - instrumentQnt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />
      <div>
        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
          Dispatched
        </Typography>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Instrument Name</TableCell>
                <TableCell>Dispatch Date</TableCell>
                <TableCell>Dispatch status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {instrumentData.map((row, index) => (
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.instrumentName}</TableCell>
                  <TableCell>
                    {dispatchDate
                      ? moment(dispatchDate).format("DD-MM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell>
                    <DoneRoundedIcon color="success" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br/>
      <div>
        <Typography variant="h6" component="div" sx={{ mb: 2 }}>
          Pending
        </Typography>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sr. No.</TableCell>
                <TableCell>Instrument Name</TableCell>
                <TableCell>Dispatch Date</TableCell>
                <TableCell>Dispatch status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {instrumentData.map((row, index) => (
                <TableRow>
                  <TableCell>{index + 1}</TableCell>
                  {/* <TableCell>Guitar</TableCell>
                  <TableCell>
                    {dispatchDate
                      ? moment(dispatchDate).format("DD-MM-YYYY")
                      : ""}
                  </TableCell>
                  <TableCell>
                    <CloseIcon style={{color:'red'}} />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <ToastContainer />
    </Paper>
  );
}
