import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search"
import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import PrintIcon from "@mui/icons-material/Print";

import Tooltip from "@mui/material/Tooltip";
import { Autocomplete, TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { showComponent } from "../helper/helpers";
import '../helper/helper.css'

const _clientId = localStorage.getItem("clientId");
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "clientName", label: "Company Name", minWidth: 40 },
  {
    id: "poNumber",
    label: "PO Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "poDate",
    label: "PO Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(id, clientName, clientId, poNumber, poDate) {
  return {
    id,
    clientName,
    clientId,
    poNumber,
    poDate,
  };
}

export default function InvoiceList(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [invoiceList, setInvoiceList] = React.useState([]);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [from, setFrom] = React.useState(null)
  const [to, setTo] = React.useState(null)
  const [searchBy, setSearchBy] = React.useState("")
  const [searchKey, setSearchKey] = React.useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const submitSiteDelete = (rowIndex, id) => {
    props.setLoader(true)
    let newInvoiceData = [...invoiceData]

    if (newInvoiceData[rowIndex] != undefined) {
      axios
        .delete(BASE_URL + `customInvoice/${id}`)
        .then(res => {
          toast.success("deleted successfully")
          props.setLoader(false)
          newInvoiceData.splice(rowIndex, 1)
          setInvoiceData([...newInvoiceData])
        })
        .catch(err => {
          console.log("err : ", err)
          toast.error("err : ", err)
          props.setLoader(false)
        })
    }
  }

  //customInvoice

  const getInvoiceList = (event) => {

    let whereConditions = ""
    let pagination_settings = ""

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Company Name": "companyName",
        "PO Number": "poNumber"
      }[searchBy]
      whereConditions = `where ${colName} like '%${searchKey}%'`
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where "
      } else {
        whereConditions += " and "
      }

      whereConditions += ` invoice.poDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `
    }
    if (userType != '1')
      whereConditions += whereConditions.length > 0 ? `and invoice.clientId=${_clientId}` : `where invoice.clientId=${_clientId}`
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${rowsPerPage * Number(page)}`
    }
    let data = {
      query: `select client.companyName,invoice.clientId,invoice.poNumber,invoice.poDate,invoice.id from customInvoice as invoice join clients as client on invoice.clientId = client.id ${whereConditions} order by invoice.id DESC ${pagination_settings}`
    }

    console.log(data.query)
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
          setInvoiceList(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
  };


  const initiatInvoiceList = () => {
    var rowData = [];

    for (let i = 0; i < invoiceList.length; i++) {
      rowData.push(
        createData(
          invoiceList[i].id,
          invoiceList[i].companyName,
          invoiceList[i].clientId,
          invoiceList[i].poNumber,
          invoiceList[i].poDate
            ? moment(invoiceList[i].poDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }

    setInvoiceData(rowData);
  };

  const search = () => {
    getInvoiceList()
  }

  useEffect(() => {
    initiatInvoiceList();
  }, [invoiceList]);

  useEffect(() => {
    getInvoiceList();
  }, []);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        {showComponent("add") && <Button
          variant="contained"
          size="small"
          component={Link}
          to="/Invoice/createInvoice"
        >
          ADD NEW CUSTOME INVOICE
        </Button>}
      </Toolbar>
      <hr />

      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}>
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              label="From"
              value={from ? new Date(from) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              value={to ? new Date(to) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px"
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "companyName", label: "Company Name" },
            { key: "poNumber", label: "PO Number" }
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue)
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search()
          }}>
          <SearchIcon />
        </Button>
      </div>
      <br />


      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = page * rowsPerPage + index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}

                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group" className="action-container">
                      {showComponent("show") && <Tooltip title="Edit Invoice" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/Invoice/editInvoice/${row.clientId}/${row.poNumber}/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>}
                      {showComponent("delete") && <Tooltip title="Delete Invoice" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm("Really want to delete standard?")
                            ) {
                              submitSiteDelete(page * rowsPerPage + index, row.id);
                            }
                          }}
                          style={{
                            borderColor: "#dc3545",
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={invoiceData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
