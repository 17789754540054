import React, { Component } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
const axios = require("axios");
const materialtypeOp = [
  "steel",
  "carbite",
]

const mode = [
  "Source",
  "Measure"
]

const parameters = {
  "Source": [
    "AC Voltage @ 50 Hz",
    "DC Voltage",
    "AC Current @ 50 Hz",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @ 1 kHz",
    "Capacitance @ 1 kHz",
    "Capacitance @ 100 Hz",
    "Inductance @ 1 kHz",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @ 50 Hz",
    "DC Power",
    "Conductivity meter",
    "pH Meter"
  ],
  "Measure": [
    "AC Voltage @ 50 Hz",
    "DC Voltage",
    "AC Current @ 50 Hz",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @ 1 kHz",
    "Capacitance @ 1 kHz",
    "Capacitance @ 100 Hz",
    "Inductance @ 1 kHz",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)"
  ]
}

const paratype = {
  "Source": {
    "Oscilloscope": [
      "Amplitude",
      "Time Base",
      "Frequency"
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)"
    ]
  },
  "Measure": {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)"
    ]
  }
}

export default class CreateInstrument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      standards: [],
      filteredStandards: [],
      disciplineId: null,
      instrumentName: null,
      standardMaster: null,
      uncertaintyFactors: null,
      c_data: null,
      calProcRefNo: null,
      status: null,
      lastModified: null,
      disciplineIdError: null,
      instrumentNameError: null,
      standardMasterError: null,
      uncertaintyFactorsError: null,
      c_dataError: null,
      uncertaintyMasterData: [],
      supportiveInstrumentsMasterData: [],
      units: [],
      finalUnitArray: [],
      statusError: null,
      lastModifiedError: null,
      disciplines: [],
      currentCandidate_disciplineId: null,
      currentCandidate_instrumentName: null,
      calibrationProcedureNo: null,
      referenceStandards: null,
      currentCandidate_standardMaster: null,
      supportiveIds: null,
      currentCandidate_uncertaintyMaster: null,
      supportiveInstrumentMasterData: null,
      currentCandidate_supportiveInstrumentMaster: null,
      currentCandidate_uncertaintyFactors: null,
      currentCandidate_c_data: null,
      currentCandidate_calProcRefNo: null,
      currentCandidate_status: null,
      currentCandidate_lastModified: null,
      standardMasterIdArray: null,
      updateModal: false,
      viewModal: false,
      option: [],
      values: null,
      checked: null,
      updateEnableDisable: false,
      setChecked: null,
      readingRows: [["", "", "", "", "", "", "", ""]],
      readingRangeValue: [["", "", "", "", "", "", "", ""]],
      srfObject: {},
      materialType: "steel",
      et: true,
      readingColumns: [
        [
          {
            field: "id",
            headerName: "Sr. No.",
            editable: false,
          },
          {
            field: "mode",
            headerName: "Mode",
            editable: true,
            toggle: 1
          },
          {
            field: "parameter",
            headerName: "Parameter",
            editable: true,
            toggle: 1
          },
          {
            field: "paratype",
            headerName: "Parameter Type",
            editable: true,
            toggle: 1
          },
          {
            field: "fromRange",
            headerName: "From range",
            editable: true,
          },
          {
            field: "toRange",
            headerName: "To range",
            editable: true,
          },
          {
            field: "CMCPLUS",
            headerName: "Lower CMC",
            editable: true,
          },
          {
            field: "CMSMINUS",
            headerName: "Higher CMC",
            editable: true,
          },
          {
            field: "Action",
          },
        ],
      ],
    };
  }
  refresh() {
    window.location.reload(false);
  }

  filterStandards(inpt, dsid) {
    let st = (inpt || this.state.standards).filter(e => e.disciplineId === (dsid || this.state.disciplineId))

    this.setState({ filteredStandards: st });
  }

  fetchStandardsData() {
    let url = BASE_URL;
    axios
      .get(
        url +
          "standards?_fields=disciplineId,standardName,id,stId&_where=(status,eq,1)~and(type,eq,1)"
      )
      .then((res) => {
        this.setState({ standards: res.data });
        this.filterStandards(res.data)
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(
        url +
          "xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.type,eq,2)"
      )
      .then((res) => {
        this.setState({ supportive: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getUnitMasterArray() {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        this.setState({ units: res.data });
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  }

  fetchUncertinityData() {
    let url = BASE_URL;
    axios
      .get(url + "uncertainty?_where=(status,eq,1)")
      .then((res) => {
        this.setState({ uncertaintyMasterData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  supportiveInstrumentsData() {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=sp.supportiveInstruments,_lj,sr.supportiveInstrumentsReadings&_on1=(sp.id,eq,sr.supportiveInstrumentId)&_fields=sp.name,sr.rangeName,sp.id,sr.id"
      )
      .then((res) => {
        console.log(res.data);
        this.setState({ supportiveInstrumentsMasterData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchDeciplineData() {
    let url = BASE_URL;
    axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        this.setState({ disciplines: res.data });
      })
      .catch((err) => {
        console.log("disciplines data error: ", err);
      });
  }

  onSubmit(e) {
    let array = this.state.standardMasterIdArray.split('|')
    let standardMasterIdArray = []
    array.forEach((element, index) => {
      element = element.split(':')[0]
      standardMasterIdArray = [...standardMasterIdArray, String(element)]
    });
    standardMasterIdArray = standardMasterIdArray.join(',')
    
    let url = BASE_URL;
    var jsonData = {
      disciplineId: this.state.currentCandidate_disciplineId,
      instrumentName: this.state.currentCandidate_instrumentName,
      standardMasterIds: standardMasterIdArray,
      calibrationProcedureNo: this.state.calibrationProcedureNo,
      referenceStandards: this.state.referenceStandards,
      uncertaintyFactors: this.state.standardMasterIdArray,
      supportiveInstrumentMasterData:
        this.state.supportiveIds,
      isGauge: this.state.updateEnableDisable,
      materialtype: this.state.materialType === "select material type" ? "" : this.state.materialType
    };

    axios
      .get(url + `standardRanges`)
      .then((res) => {
        var rangesData = res.data;
        var tempArr = [];

        var idsToArray = jsonData.standardMasterIds.split(",");

        if (idsToArray.length) {
          for (let i = 0; i < rangesData.length; i++) {
            for (let j = 0; j < idsToArray.length; j++) {
              if (
                parseInt(idsToArray[j].split(":")[0]) ==
                  rangesData[i].standardId &&
                parseInt(idsToArray[j].split(":")[1]) == rangesData[i].id
              ) {
                tempArr.push(rangesData[i].standardId + ":" + rangesData[i].id);
              }
            }
          }
        }
        jsonData.standardMasterIds = tempArr.toString();
      })     
      .catch((error) => {
        toast.error(error);
      });
    
      axios
      .get(url + `standardRanges`)
      .then((res) => {
        var rangesData = res.data;
        var tempArrs = [];

        var idsToArrays = jsonData.supportiveInstrumentMasterData.split(",");

        if (idsToArrays.length) {
          for (let i = 0; i < rangesData.length; i++) {
            for (let j = 0; j < idsToArrays.length; j++) {
              if (
                parseInt(idsToArrays[j].split(":")[0]) ==
                  rangesData[i].standardId &&
                parseInt(idsToArrays[j].split(":")[1]) == rangesData[i].id
              ) {
                tempArrs.push(rangesData[i].standardId + ":" + rangesData[i].id);
              }
            }
          }
        }
        jsonData.supportiveInstrumentMasterData = tempArrs.toString();
      }).catch((error) => {
        toast.error(error);
      });
    
    axios
      .post(url + "instruments", jsonData)
      .then((res) => {
        if (this.state.readingRows.length) {
          let rows = this.state.readingRows.map((row, i) => ({
            instrumentId: res.data.insertId,
            mode: row[1],
            parameter: row[2],
            paratype: row[3],
            fromRange:
              this.state.readingRangeValue[i][4] != null
                ? row[4] + "#" + this.state.readingRangeValue[i][4]
                : row[4],
            toRange:
              this.state.readingRangeValue[i][5] != null
                ? row[5] + "#" + this.state.readingRangeValue[i][5]
                : row[5],
            lowerCmc:
              this.state.readingRangeValue[i][6] != null
                ? row[6] + "#" + this.state.readingRangeValue[i][6]
                : row[6],
            higherCmc:
              this.state.readingRangeValue[i][7] != null
                ? row[7] + "#" + this.state.readingRangeValue[i][7]
                : row[7],
          }));
          console.log("rows", rows);
          rows.map((rows) => {
            let url = BASE_URL;
            axios
              .post(url + "cmc", rows)
              .then((res) => {
                console.log("cmc added");
              })
              .catch((error) => {
                toast.error("Something Went Wrong!");
              });
          });
        }

        toast("new instrument added!");
        setTimeout(this.refresh, 500);
      })

      .catch((error) => {
        toast.error(error);
      });
  }

  componentDidMount() {
    this.fetchDeciplineData();
    this.fetchStandardsData();
    this.fetchUncertinityData();
    this.supportiveInstrumentsData();
    this.getUnitMasterArray();
  }

  render() {
    var emtryarray = [];
    for (var i = 0; i < this.state.disciplines.length; i++) {
      emtryarray.push({
        label: this.state.disciplines[i].name,
        id: this.state.disciplines[i].id,
      });
    }

    const updateRangeCellValue = (rowIndex, colIndex, value) => {
      const newRows = [...this.state.readingRangeValue];
      newRows[rowIndex][colIndex] = value;
      this.setState({
        readingRangeValue: [...newRows],
      });
    };

    const updateCellValue = (rowIndex, colIndex, value) => {
      const newRows = [...this.state.readingRows];
      newRows[rowIndex][colIndex] = value;
      this.setState({
        readingRows: [...newRows],
      });
    };

    const addDescriptionRow = (tableIndex) => {
      let newReadingRows = [...this.state.readingRows];
      newReadingRows.push(["", "", "", "", "", "", "", ""]);
      this.setState({
        readingRows: [...newReadingRows],
      });

      let newReadingRowss = [...this.state.readingRangeValue];
      newReadingRowss.push(["", "", "", "", "", "", "", ""]);
      this.setState({
        readingRangeValue: [...newReadingRowss],
      });
    };

    const deleteReadingRow = (tableIndex, rowIndex) => {
      let newReadingRows = [...this.state.readingRows];
      newReadingRows.splice(rowIndex, 1);
      this.setState({
        readingRows: [...newReadingRows],
      });
    };

    const deleteStandardMasterIdArrayValue = (rowIndex) => {
      let newReadingRows = this.state.standardMasterIdArray.split('|')
      newReadingRows.splice(rowIndex, 1);
      newReadingRows = newReadingRows?.join('|')
      if(newReadingRows == '')
        this.setState({
          standardMasterIdArray: null
        });
      this.setState({
        standardMasterIdArray: newReadingRows
      });
    }

    function displayTable(value, standards, uncertaintyMasterData) {
      const rows = value.split('|');
      
      const headerCellStyle = {
        backgroundColor: '#f2f2f2',
        padding: '8px',
        fontWeight: 'bold',
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
      };

      const cellStyle = {
        padding: '8px',
        borderBottom: '1px solid #ddd',
      };
      return (
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={headerCellStyle}>Standard Master ID</th>
              <th style={headerCellStyle}>Uncertainty Factors</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => {
              const rowData = row.split(':');
              standards?.forEach(standard=>{
                if(standard.id == rowData[0]){
                  rowData[0] = standard.standardName
                }
              })
              let master_ids = rowData[1]?.split(',')
              let master_names = []
              master_ids?.forEach((master_id, index)=>{
                uncertaintyMasterData?.forEach(umaster=>{
                  if(umaster.id == master_id){
                    master_names.push(umaster.name)
                  }
                })
              } )
              rowData[1] = master_names.join(',')              
              return (
                <tr key={rowIndex}>
                  {rowData.map((column, columnIndex) => {
                    const cellData = column.split(',');
                    return (
                      <td key={columnIndex} style={cellStyle}>
                        {cellData.join(', ')}
                      </td>
                    );
                  })}
                  <td>
                  <DeleteIcon
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteStandardMasterIdArrayValue(rowIndex);
                        }}
                      />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }

    
    return (
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create new instrument
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={emtryarray}
              renderInput={(params) => (
                <TextField {...params} label="Discipline *" />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  let disciplineKeys = false;
                  this.state.disciplines.forEach((el) => {
                    if (el.id == value.id) {
                      if (el.disciplineKey == "0") {
                        disciplineKeys = true
                      }
                    }
                  })
                  if (disciplineKeys) {
                    this.setState({
                      et: false
                    })
                  } else {
                    this.setState({
                      et: true
                    })
                  }
                  const unitArr = [""];
                  for (let i = 0; i < this.state.units.length; i++) {
                    if (
                      parseInt(value.id) == this.state.units[i].disciplineId
                    ) {
                      unitArr.push(this.state.units[i]);
                    }
                  }
                  this.setState({
                    finalUnitArray: unitArr,
                    currentCandidate_disciplineId: value.id,
                  });
                } else {
                  this.setState({
                    currentCandidate_disciplineId: null,
                  });
                }
                this.filterStandards(this.state.standards, value.id)
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Instrument name *"
              size="small"
              fullWidth
              variant="outlined"
              value={this.state.currentCandidate_instrumentName}
              onChange={(e) => {
                this.setState({
                  currentCandidate_instrumentName: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Calibration Procedure No."
              size="small"
              fullWidth
              variant="outlined"
              value={this.state.calibrationProcedureNo}
              onChange={(e) => {
                this.setState({
                  calibrationProcedureNo: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Is Standards *"
              size="small"
              fullWidth
              variant="outlined"
              value={this.state.referenceStandards}
              onChange={(e) => {
                this.setState({
                  referenceStandards: e.target.value,
                });
              }}
            />
          </Grid>

         
          <Grid item xs={4}>
            <Autocomplete
              multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.supportive != null
                  ? this.state.supportive.map((item) => {
                      return {
                        id: item.pl_id,
                        standardRangesId: item.pr_id,
                        title: item.pl_standardName,
                        range: item.pr_rangeName,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                   {option.title}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supportive Instruments *"
                  placeholder="select multiple supportive instruments"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id + ":" + item.standardRangesId;
                    })
                    .join(",");
                  this.setState({
                    supportiveIds: arrToStr,
                  });
                } else {
                  this.setState({
                    supportiveIds: null,
                  });
                }
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { label: "1, Plain plug Gauge" },
                { label: "2, Width gauge" },
                { label: "3, Paddle gauge" },
                { label: "4, Flush pin" },
                { label: "5, Snap gauge" },
              ]}
              renderInput={(params) => <TextField {...params} label="Gauge" />}
              onInputChange={(e, newInputValue) => {
                this.setState({
                  updateEnableDisable: parseInt(newInputValue.split(",")[0]),
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={materialtypeOp.map(e => ({
              label: e
            }))}
              defaultValue={this.state.materialType}
            renderInput={(params) => (
              <TextField {...params} label="Material Type *" />
            )}
            onInputChange={(event, newInputValue) => {
              this.setState({
                materialType: newInputValue
              })
            }}
          />
        </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
        <Grid item xs={4}>
            <Autocomplete
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.filteredStandards != null
                  ? this.state.filteredStandards.map((item) => {
                      return {
                        id: item.id,
                        stId: item.stId,
                        title: item.standardName,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.stId} <b>:</b> {option.title}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Standard Master *"
                  placeholder="select multiple standards"
                />
              )}
              onChange={(event, value) => {
                console.log({value});
                  this.setState({
                    currentCandidate_standardMaster: value.id,
                  });
                
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
            multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.uncertaintyMasterData != null
                  ? this.state.uncertaintyMasterData.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        distribution: item.distribution,
                        sensitives: item.sensitives,
                        coefficient: item.coefficient,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.id} <b>:</b> {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Uncertainity Factors"
                  placeholder="select multiple uncertainity"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id;
                    })
                    .join(",");
                  this.setState({
                    currentCandidate_uncertaintyMaster: arrToStr,
                  });
                } else {
                  this.setState({
                    currentCandidate_uncertaintyMaster: null,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              if(this.state.standardMasterIdArray == null)
                this.setState({standardMasterIdArray:  this.state.currentCandidate_standardMaster + ":" + this.state.currentCandidate_uncertaintyMaster})
              else{
                let value = this.state.standardMasterIdArray;
                value = value + "|" + this.state.currentCandidate_standardMaster + ":" + this.state.currentCandidate_uncertaintyMaster
                this.setState({standardMasterIdArray:  value})
              } 
            }}
          >
            ADD
          </Button>
          </Grid>
          <Grid item xs={12}>
            {this.state.standardMasterIdArray && displayTable(this.state.standardMasterIdArray, this.state.filteredStandards, this.state.uncertaintyMasterData)}
          </Grid>
        </Grid>

        <hr />
        <h4 style={{ "margin-bottom": "0px" }}>CMC</h4>
        <Button
          style={{ float: "right" }}
          onClick={(e) => {
            addDescriptionRow(0);
          }}
        >
          <AddIcon />
        </Button>
        <div style={{ width: "100%", overflow: "auto" }}>
          <ClassicTable>
            <Table sx={{ minWidth: 660 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {this.state.readingColumns[0].filter(e => (e.toggle === undefined || !this.state.et)).map((column) => (
                    <TableCell key={column.field}>
                      {" "}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.readingRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {row.map((cell, cellIndex) => {
                      if (
                        cellIndex != 0 &&
                        cellIndex != 1 &&
                        cellIndex != 2 &&
                        cellIndex != 3
                      ) {
                        return (
                          <TableCell>
                            <TextField
                              size="small"
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            ></TextField>
                            <br />
                            <select
                            // disabled={plus}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              )
                            }}>
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {this.state.finalUnitArray?.map((unit) => {
                              if (unit.symbol == this.state.readingRangeValue[index][cellIndex]) {
                                return (
                                  <option value={unit.symbol} key={unit.symbol} selected>
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 1 && !this.state.et) {
                        return (
                          <TableCell>
                            <Autocomplete
                              style={{ width: "150px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={mode.map(e => ({
                                label: e
                              }))}
                              renderInput={(params) => (
                                <TextField {...params} label="Mode *" />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(index, cellIndex, newInputValue)
                              }}
                              // disabled={plus}
                            />
                            <select
                              disabled={true}
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                )
                              }}>
                              <option value={""} key={""} selected>
                                {""}
                              </option>
                              {this.state.finalUnitArray?.map((unit) => {
                                if (unit.symbol == this.state.readingRangeValue[index][cellIndex]) {
                                  return (
                                    <option value={unit.symbol} key={unit.symbol} selected>
                                      {unit.symbol}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={unit.symbol} key={unit.symbol}>
                                      {unit.symbol}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </TableCell>
                        )
                      }
                      if (cellIndex == 2 && !this.state.et) {
                        return (
                          <TableCell>
                            <Autocomplete
                              style={{ width: "250px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={parameters[row[1]] ? parameters[row[1]].map(e => ({
                                label: e
                              })) : []}
                              renderInput={(params) => (
                                <TextField {...params} label="Parameter *" />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(index, cellIndex, newInputValue)
                              }}
                              // disabled={(plus || !row[2]) ? true : false}
                              disabled={(!row[1]) ? true : false}
                            />
                            <select
                              disabled={true}
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                )
                              }}>
                              <option value={""} key={""} selected>
                                {""}
                              </option>
                              {this.state.finalUnitArray?.map((unit) => {
                                if (unit.symbol == this.state.readingRangeValue[index][cellIndex]) {
                                  return (
                                    <option value={unit.symbol} key={unit.symbol} selected>
                                      {unit.symbol}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={unit.symbol} key={unit.symbol}>
                                      {unit.symbol}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </TableCell>
                        )
                      }
                      if (cellIndex == 3 && !this.state.et) {
                        return (
                          <TableCell>
                            <Autocomplete
                              style={{ width: "150px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={(paratype[row[1]] && paratype[row[1]][row[2]]) ? paratype[row[1]][row[2]].map(e => ({
                                label: e
                              })) : []}
                              renderInput={(params) => (
                                <TextField {...params} label="Parameter Type *" />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(index, cellIndex, newInputValue)
                              }}
                              // disabled={(plus || !(paratype[row[2]] && paratype[row[2]][row[3]])) ? true : false}
                              disabled={(!(paratype[row[1]] && paratype[row[1]][row[2]])) ? true : false}
                            />
                            <select
                              disabled={true}
                              styles={{ width: "100%" }}
                              onChange={(e) => {
                                updateRangeCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                )
                              }}>
                              <option value={""} key={""} selected>
                                {""}
                              </option>
                              {this.state.finalUnitArray?.map((unit) => {
                                if (unit.symbol == this.state.readingRangeValue[index][cellIndex]) {
                                  return (
                                    <option value={unit.symbol} key={unit.symbol} selected>
                                      {unit.symbol}
                                    </option>
                                  );
                                } else {
                                  return (
                                    <option value={unit.symbol} key={unit.symbol}>
                                      {unit.symbol}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          </TableCell>
                        )
                      }
                    })}
                    <TableCell>
                      <DeleteIcon
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteReadingRow(0, index);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ClassicTable>
        </div>

        <br />
        <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              this.onSubmit(e);
            }}
          >
            Save Instrument
          </Button>
        </Toolbar>
        <ToastContainer />
      </Paper>
    );
  }
}
