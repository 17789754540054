import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { useSnackbar } from "notistack";
import { ClassicTable } from "../../../utils/components/Styles";
import { useReactToPrint } from "react-to-print";
import { BASE_URL } from "../../../global";
import { resolveSource } from "../utils";
import { render } from "@testing-library/react";
const axios = require("axios");

export default function EditTypeB(props) {
  let {
    datasheetReadingId,
    datasheetId,
    instrumentId,
    lc,
    nominalVal,
    stdRangeIds,
    supportiveMaster,
    ucUnit
  } = useParams();
  if (props.viewAll === true) {
    datasheetReadingId = props.datasheetReadingId;
    datasheetId = props.datasheetId;
    instrumentId = props.instrumentId;
    lc = props.lc;
    nominalVal = props.nominalVal;
    stdRangeIds = props.stdRangeIds;
    supportiveMaster = props.supportiveMaster;
    ucUnit = props.ucUnit
  }

  const [typeBId, setTypeBId] = useState(null);
  const [customValues, setCustomValues] = useState({});
  const [derivedValues, setDerivedValues] = useState({});
  const [standards, setStandards] = useState([]);
  const [uncertaintyFactors, setUncertaintyFactors] = useState([]);
  const [supportiveInstruments, setSupportiveInstruments] = useState([]);
  const [staticTables, setStaticTables] = useState(null);
  const [customRels, setCustomRels] = useState({});
  const [staticReading, setStaticReadings] = useState(null);
  const [masters, setMasters] = useState([]);
  const [supportive, setSupportive] = useState([]);
  const [factorRows, setFactorRows] = useState([]);

  const printComponentRef = React.useRef();
  const { enqueueSnackbar } = useSnackbar();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  // api
  function fetchTypeB() {
    axios
      .get(
        BASE_URL + `typeB?_where=(datasheetReadingId,eq,${datasheetReadingId})`
      )
      .then((res) => {
        if (res.data?.length > 0) {
          setTypeBId(res.data[0].id);
          setCustomValues(JSON.parse(res.data[0].customValues) || {});
        }
      })
      .catch((err) => {
        console.error("typeB data fetching error: ", err);
      });
  }

  function fetchTypeBValues() {
    axios
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(id,eq,${datasheetReadingId})`
      )
      .then((res) => {
        setDerivedValues(JSON.parse(res?.data[0]?.typeBValues));
        setStaticReadings(res?.data[0]);
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
      });
  }

  function fetchInstrument() {
    axios
      .get(BASE_URL + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        fetchUncertaintyFactors(res.data[0].uncertaintyFactors);
        fetchSupportiveInstruments(res.data[0].supportiveInstrumentMasterData);
      })
      .catch((err) => {
        console.error("instrument data fetching error: ", err);
      });
  }

  function fetchDatasheetStaticTables() {
    let url = BASE_URL;
    return axios
      .get(
        url + `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        setStaticTables(res.data.length > 0 ? res.data[0] : {});
        return res;
      })
      .catch((err) => {
        console.error("datasheet static tables data fetching error: ", err);
        return err;
      });
  }

  function fetchStandards() {
    axios
      .get(
        BASE_URL +
          `standardRanges?_where=(id,in,${stdRangeIds || 0})`
      )
      .then((res) => {
        setStandards([...res.data]);
      })
      .catch((err) => {
        console.error("standard ranges data fetching error: ", err);
      });
  }

  function fetchSupportiveInstruments(supportiveInstrumentIds) {
    supportiveInstrumentIds = supportiveInstrumentIds?.split(",");
    let ids = [];
    let subids = [];
    for (let i = 0; i < supportiveInstrumentIds?.length; i++) {
      let id = supportiveInstrumentIds[i].split(":");
      ids.push(id[0]);
      subids.push(id[1]);
    }

    // `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId&_where=(pl.status,eq,1)~and(pl.id,in,${standardIds})`,
    axios
      .get(BASE_URL + `standardRanges?_where=(id,in,${supportiveMaster})`)
      .then((res) => {
        setSupportiveInstruments([...res.data]);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });

    axios
      .get(
        BASE_URL +
          `standards?_where=(id,in,${ids?.toString()})&_fields=standardName,id`
      )
      .then((res) => {
        let ss = {};
        res.data.map((s) => (ss[s.id] = s.standardName));
        setSupportive(ss);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
  }

  const fetchStandardMasters = () => {
    axios
      .get(
        BASE_URL +
          `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.id,pl.id&_where=(pl.status,eq,1)~and(pr.id,eq,${
            stdRangeIds
          })`
      )
      .then((res) => {
        let ms = {};
        res.data.map((m) => (ms[m.pr_id] = m));
        setMasters(ms);
      })
      .catch((err) => {
        console.error("supportiveInstruments fetching error: ", err);
      });
  };

  function fetchUncertaintyFactors(uncertaintyFactors) {
    axios
      .get(
        BASE_URL +
          `uncertainty?_where=(id,in,${uncertaintyFactors?.toString()})`
      )
      .then((res) => {
        setUncertaintyFactors(res.data);
      })
      .catch((err) => {
        console.error("uncertainty factors data fetching error: ", err);
      });
  }

  function submitTypeB() {
    if (!typeBId) {
      axios
        .post(BASE_URL + `typeB`, {
          datasheetReadingId: datasheetReadingId,
          customValues: JSON.stringify(customValues),
        })
        .then((res) => {
          enqueueSnackbar("Type-B updated successfully!", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .patch(BASE_URL + `typeB/${typeBId}`, {
          customValues: JSON.stringify(customValues),
        })
        .then((res) => {
          enqueueSnackbar("Type-B updated successfully!", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  React.useEffect(() => {
    fetchStandards();
    fetchInstrument();
    fetchTypeB();
    fetchTypeBValues();
    fetchDatasheetStaticTables();
    fetchStandardMasters();
  }, []);

  React.useEffect(() => {
    if (staticTables && staticReading && uncertaintyFactors.length > 0) {
      let ops = {};
      uncertaintyFactors.map((op) => ops[op.id]);
      let rels = JSON.parse(staticTables.defaultConfiguration);
      rels = rels?.typeb?.relations ? Object.entries(rels.typeb.relations) : [];
      let newRels = {};
      rels.map((rel) => {
        newRels[rel[1]] = staticReading[rel[0]];
      });
      setCustomRels(newRels);
    }
  }, [staticReading, staticTables, uncertaintyFactors]);

  React.useEffect(() => {
    renderFields();
  }, [standards, uncertaintyFactors, supportiveInstruments]);

  const updateCustomValue = (uCount, value) => {
    setCustomValues({
      ...customValues,
      [`${uCount}`]: value,
    });
  };

  const renderFields = async () => {
    let selectors = {
      datasheets: datasheetId,
      instruments: instrumentId,
      standardRanges: stdRangeIds,
      datasheetStaticReadings: datasheetReadingId,
      standards: Object.values(masters)?.[0]?.["pl_id"]
    };
    let rows = [];
    let uCount = 1;
    let hasLCDisplayed = false;
    let unlinkedRangesDisplayStatus = [];

    let linked = false;
    for(let index = 0; index < standards?.length; index++){

      let standard = standards[index]
      for (let i = 0; i < uncertaintyFactors?.length; i++) {
        // unlinked factors should display only once
        linked = false;
        let isDerived = false;

        if (
          uncertaintyFactors[i].linkedRanges === null ||
          uncertaintyFactors[i].linkedRanges === ""
        ) {
          if (unlinkedRangesDisplayStatus[uncertaintyFactors[i].name] === true)
            continue;
          else {
            unlinkedRangesDisplayStatus[uncertaintyFactors[i].name] = true;
          }
        }

        // linked range value
        let dv = standard[uncertaintyFactors[i].linkedRanges];
        if (dv !== undefined) linked = true;

        // default value
        if (uncertaintyFactors[i].defaultValue) {
          dv = uncertaintyFactors[i].defaultValue;
          linked = false;
        }

        // derived value from dtasheet
        if (derivedValues?.[uncertaintyFactors[i].id]) {
          dv = derivedValues[uncertaintyFactors[i].id];
          isDerived = true;
          linked = false;
        }

        // custom value
        if (uncertaintyFactors[i].linkedRanges === "leastCount") {
          linked = false;
          if (hasLCDisplayed) continue;
          let _lc = Number((lc?.match(/[\d\.]+/g) || [])[0]);
          dv = _lc / 2;
          hasLCDisplayed = true;
        } else if (customValues["s:" + index + ":" + (i + 1)]) {
          dv = customValues["s:" + index + ":" + (i + 1)];
          dv = Number((String(dv)?.match(/[\d\.]+/g) || [])[0]);
          linked = false;
        }

        dv = String(dv).replace(",", " ");

        if (customRels[uncertaintyFactors[i].id]) {
          dv = customRels[uncertaintyFactors[i].id];
          linked = false;
        }
        let value = dv;
        if(isDerived == false){
          value = await resolveSource(JSON.parse(uncertaintyFactors[i].sourceconfig || null),selectors, ucUnit)
          value = value + "#" + ucUnit.split("_unit_")?.[1]
        }

        let row = (
          <TableRow key="1">
            <TableCell>{"U" + uCount}</TableCell>
            <TableCell>
              {`${uncertaintyFactors[i].name}${
                linked ? `(${masters[stdRangeIds]?.["pl_standardName"]})` : ""
              }`}
              <br />±{" "}
              <TextField
                id="outlined-basic"
                size="small"
                variant="outlined"
                placeholder={`enter U${uCount}`}
                defaultValue={value.replace("#", " ")}
                onChange={(e) =>
                  updateCustomValue(
                    "s:" + index + ":" + (i + 1),
                    e.target.value
                  )
                }
              />
            </TableCell>
            <TableCell>
              {value.replace("#", " ")}
            </TableCell>
            <TableCell>ω</TableCell>
            <TableCell>Sensitivity Coeff. = 1</TableCell>
            <TableCell>{value?.replaceAll("#", " ")} </TableCell>
          </TableRow>
        );
        rows.push(row);
        uCount++;
      }
    }

    let parameter = ["axialUniformity", "radialUniformity", "stability"];
    supportiveInstruments?.map((factor, index) => {
      for (let i = 0; i < parameter.length; i++) {
        if (factor[parameter[i]] != "" && factor[parameter[i]] != null) {
          let dv = factor[parameter[i]];
          if (customValues["si:" + index + ":" + (i + 1)]) {
            dv = customValues["si:" + index + ":" + (i + 1)];
          }
          let row = (
            <TableRow key="1">
              <TableCell>{"U" + uCount}</TableCell>
              <TableCell>
                {`${parameter[i]} (${supportive[factor.standardId]})`}
                <br />±{" "}
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  placeholder={`enter U${uCount++}`}
                  defaultValue={dv?.replaceAll("#", "")}
                  onChange={(e) => {
                    updateCustomValue(
                      "si:" + index + ":" + (i + 1),
                      e.target.value
                    );
                  }}
                />
              </TableCell>
              <TableCell>{"1/√3"}</TableCell>
              <TableCell>ω</TableCell>
              <TableCell>Sensitivity Coeff. = 1</TableCell>
              <TableCell>{factor[parameter[i]]}</TableCell>
            </TableRow>
          );
          rows.push(row);
        }
      }
    });
    
    setFactorRows([...rows]);

  };

  return (
    <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
      <Typography variant="h5" align="center" component="div" sx={{ mb: 2 }}>
        Type-B Contribution
      </Typography>

      <div>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Distribution</TableCell>
                <TableCell>DOF</TableCell>
                <TableCell>Calculation</TableCell>
                <TableCell>Value(±)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {standards && uncertaintyFactors && factorRows?.map(row => row)}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>

      {props.viewAll != true && (
        <Toolbar
          sx={{ displayPrint: "none" }}
          style={{ padding: "0px", width: "100%" }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0, displayPrint: "none" }}
            onClick={() => {
              submitTypeB();
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ ml: 3, displayPrint: "none" }}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Toolbar>
      )}
    </Paper>
  );
}
