import * as React from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./user.css"

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  Box
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { param } from "jquery";
import { useParams } from "react-router";
import { showComponent } from "../../helper/helpers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
const axios = require("axios");


const moduleList = [
  {
    "id": 1,
    "name": "dashboard",
  },
  {
    "id": 2,
    "name": "master",
  },
  {
    "id": 3,
    "name": "SRF",
  },
  {
    "id": 4,
    "name": "Datasheet",
  },
  {
    "id": 5,
    "name": "Certificates",
  },
  {
    "id": 6,
    "name": "Standard In/Out"
  },
  {
    "id": 7,
    "name": "Courier Register"
  },
  {
    "id": 8,
    "name": "Expenses",
  },
  {
    "id": 9,
    "name": "Work Report",
  },
  {
    "id": 10,
    "name": "Summary Report",
  },
  {
    "id": 11,
    "name": "History Card",
  },
  {
    "id": 12,
    "name": "Due Reports",
  },
  {
    "id": 13,
    "name": "Masters Due"
  },
  {
    "id": 14,
    "name": "Enquiry"
  },
  {
    "id": 15,
    "name": "Quotation",
  },
  {
    "id": 16,
    "name": "Invoice",
  },
  {
    "id": 17,
    "name": "Payment",
  },
  {
    "id": 18,
    "name": "Purchase",
  },
  // {
  //   "id": 19,
  //   "name": "Intermediate Procedure",
  // },
  // {
  //   "id": 20,
  //   "name": "Nable Scope"
  // },
  {
    "id": 21,
    "name": "Standard In/Out"
  },
  {
    "id": 22,
    "name": "Enquiry Followup",
  },
  {
    "id": 23,
    "name": "Feedback Report",
  },
  {
    "id": 24,
    "name": "Pickup",
  },
  {
    "id": 25,
    "name": "User",
  }
]


const userTypes = [
  {
    id: 1,
    name: "Admin"
  },
  {
    id: 2,
    name: "User"
  }
]


export default function EditUserMaster() {
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modules, setModules] = React.useState(null);
  const [type, setType] = React.useState("Admin");
  const [selectedModules, setSelectedModules] = React.useState(null);
  const [selectedClients, setSelectedClients] = React.useState(null);
  const [clientId, setClientId] = React.useState(null);
  const [executing, setExecuting] = React.useState(false);
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0])
  const [clientArray, setClientArray] = React.useState([])
  const { id } = useParams()

  const onSubmit = () => {
    setExecuting(true);
    var jsonData = {
      userName: userName,
      password: password,
    };
    jsonData["type"] = type === "Admin" ? 1 : 2
    if (selectedModules) {
      jsonData["modules"] = selectedModules.map(m => m.id).join(",")
    }

    axios
      .post(BASE_URL + `users`, jsonData)
      .then((res) => {
        toast("User Added Successfully!");
        setTimeout(refresh, 1000);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getClientIdList = () => {
    let url = BASE_URL
    axios
      .get(url + 'clients?_where=(status,eq,1)')
      .then((res) => {
        var pushCArray = []
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].companyName) {
            pushCArray.push(res.data[i].id + ', ' + res.data[i].companyName)
          }
        }
        setClientArray(pushCArray)
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
      })
  }

  var refresh = () => {
    window.location.reload(false);
  };


  const initModules = () => {
    setModules(moduleList)
  }

  useEffect(() => {
    initModules()
    getClientIdList()
  }, [])


  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Add user
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters) ? vals[0] = 1 : vals[0] = 0

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters) ? vals[1] = 1 : vals[1] = 0

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? vals[2] = 1 : vals[2] = 0

              // Validate length
              e.target.value.length >= 8 ? vals[3] = 1 : vals[3] = 0
              e.target.value.length <= 32 ? vals[4] = 1 : vals[4] = 0

              setPasswordVal(vals)
              setPassword(e.target.value)
            }}
          />
          <div>
            <p id="letter" class={passVal[0] ? "valid" : "invalid"}>A <b>lowercase</b> letter</p>
            <p id="capital" class={passVal[1] ? "valid" : "invalid"}>A <b>capital (uppercase)</b> letter</p>
            <p id="number" class={passVal[2] ? "valid" : "invalid"}>A <b>number</b></p>
            <p id="lengthMin" class={passVal[3] ? "valid" : "invalid"}>Minimum <b>8 characters</b></p>
            <p id="lengthMax" class={passVal[4] ? "valid" : "invalid"}>Maximum <b>32 characters</b></p>
          </div>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={userTypes.map(({ id, name }) => ({
              label: name,
            }))}
            value={type}
            renderInput={(params) => (
              <TextField {...params} label="Master Link *" />
            )}
            onInputChange={(event, newInputValue) => {
              setType(newInputValue)
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            size="small"
            value={selectedModules ? selectedModules : []}
            options={
              modules != null
                ? modules.map((item) => {
                  return {
                    id: item.id,
                    name: item.name
                  };
                })
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />

                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select modules (optional)"
                placeholder="select multiple modules"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                setSelectedModules(value);
              } else {
                setSelectedModules(null);
              }
            }}
          />

        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={8}>
          {console.log("selectedClients : ", selectedClients)}
          <Box sx={{ width: "100%" }}>
            <table className="user-client-table">
              <tr>
                <th>Client</th>
                <th>Start Date</th>
                <th>End Date</th>
                {(selectedClients || []).length > 1 && showComponent("delete") ? (<th></th>) : ""}
              </tr>
              {(selectedClients || []).map((e, idx) => {
                console.log("==>> ", e)
                return (
                  <tr key={idx}>
                    <td>
                      <Autocomplete
                        fullWidth
                        id="checkboxes-tags-demo"
                        size="small"
                        value={e[0] ? e[0] : []}
                        options={
                          clientArray != null
                            ? clientArray.map((item) => {
                              return {
                                id: item.split(",")[0],
                                name: item.split(",")[1]
                              };
                            })
                            : []
                        }
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />

                            {`${option.id}, ${option.name}`}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Add Clients (optional)"
                            placeholder="Add Clients"
                          />
                        )}
                        onChange={(event, value) => {
                          let tmp = selectedClients;
                          let tp = value !== null ? value.split("") : []
                          tmp[idx][0] = (value.split(",")[0])
                          setSelectedClients(tmp)
                        }}
                      />
                    </td>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ border: "none" }}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          format="dd/MM/yyyy"
                          value={e[1] ? new Date(e[1]) : ''}
                          onChange={(newValue) => {
                            let tmp = selectedClients;
                            tmp[idx][1] = newValue
                            setSelectedClients(tmp)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                          sx={{ border: "none" }}
                        />
                      </LocalizationProvider>
                    </td>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          inputFormat="dd/MM/yyyy"
                          format="dd/MM/yyyy"
                          value={e[2] ? new Date(e[2]) : ''}
                          onChange={(newValue) => {
                            let tmp = selectedClients;
                            tmp[idx][2] = newValue
                            setSelectedClients(tmp)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" fullWidth />
                          )}
                        />
                      </LocalizationProvider>
                    </td>
                    {(selectedClients || []).length > 1 && showComponent("delete") ?
                      (
                        <td width={"15px"}>
                          <DeleteIcon
                            style={{ color: '#dc3545' }}
                            onClick={(e) => {
                              let tmp = (selectedClients || []).filter((_, i) => i !== idx)
                              setSelectedClients(tmp)
                            }}
                          />
                        </td>
                      ) : (
                        ""
                      )}
                  </tr>
                )
              })}
              <tr>
                <td colSpan={(selectedClients || []).length > 1 ? 4 : 3}>
                  <Button
                    onClick={(e) => {
                      setSelectedClients([...(selectedClients || []), [-1, "", ""]]);
                    }}
                  >
                    <b style={{ fontSize: '18px' }}>ADD CLIENT</b>
                    <AddIcon />
                  </Button>
                </td>
              </tr>
            </table>
          </Box>
        </Grid>
      </Grid>
      <br />


      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          disabled={executing || userName === "" || password === "" || passVal.reduce((s, v) => s + v, 0) < 5}
          onClick={() => {
            // enqueueSnackbar("Standard added successfully !", {
            //     variant: "success",
            // });
            onSubmit();
          }}
        >
          Save user
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
