import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Grid, TextField, Autocomplete } from "@mui/material";
import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { BASE_URL } from "./../../global";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No." },
  { id: "disipline", label: "Discipline" },
  { id: "standardName", label: "Name", minWidth: 40 },
  { id: "standardIds", label: "Id/Serial No.", minWidth: 40 },
  { id: "make", label: "Make/Model", minWidth: 40 },
  {
    id: "certificateNumber",
    label: "Certificate Number",
    align: "left",
    minWidth: 40,
  },
  {
    id: "traceability",
    label: "Tracability",
    align: "left",
    minWidth: 40,
  },

  {
    id: "calDate",
    label: "Cal.dt",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "validUpto",
    label: "Valid Upto",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
];

function createData(
  id,
  disipline,
  standardName,
  standardIds,
  make,
  certificateNumber,
  traceability,
  calDate,
  validUpto
) {
  return {
    id,
    disipline,
    standardName,
    standardIds,
    make,
    certificateNumber,
    traceability,
    calDate,
    validUpto,
  };
}

export default function MastersDue() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [standards, setStandards] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(0);
  const [toDate, setToDate] = React.useState(0);
  const [dateData, setDateData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [standardName, setStandardName] = React.useState(null);
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "standards?_where=(status,eq,1)")
      .then((res) => {
        setStandards(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var pushArray = [];

  for (let i = 0; i < standards.length; i++) {
    pushArray.push({
      label: standards[i].standardName,
    });
  }

  const FilterData = () => {
    if (standardName == null) {
      var resultProductData = data.filter(
        (data) =>
          new Date(data.standard_validUpto) >= new Date(fromDate) &&
          new Date(data.standard_validUpto) <= new Date(toDate)
      );
    } else {
      var resultProductData = data.filter(
        (data) =>
          new Date(data.standard_validUpto) >= new Date(fromDate) &&
          new Date(data.standard_validUpto) <= new Date(toDate) &&
          data.standard_standardName == standardName.label
      );
    }
    setDateData(resultProductData);
  };

  const getData = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
        "xjoin?_join=standard.standards,_j,disc.discipline&_on1=(standard.disciplineId,eq,disc.id)&_fields=standard.stId,standard.standardName,standard.make,standard.certificateNumber,standard.traceability,standard.calDate,standard.validUpto,disc.name,standard.id&_where=(standard.status,eq,1)"
      )
      .then((res) => {
        // setDateData(res.data)
        setData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var rowData = [];
  for (let i = 0; i < dateData.length; i++) {
    rowData.push(
      createData(
        dateData[i].standard_id,
        dateData[i].disc_name,
        dateData[i].standard_standardName,
        dateData[i].standard_stId,
        dateData[i].standard_make,
        dateData[i].standard_certificateNumber,
        dateData[i].standard_traceability,
        dateData[i].standard_calDate
          ? moment(dateData[i].standard_calDate).format("DD-MM-YYYY")
          : "",
        dateData[i].standard_validUpto
          ? moment(dateData[i].standard_validUpto).format("DD-MM-YYYY")
          : ""
      )
    );
  }

  useEffect(() => {
    getClient();
    getData();
  }, []);

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Grid container spacing={2}>

        <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
              <DatePicker
                label="From"
                value={from ? new Date(from) : ''}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setFrom(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To"
                value={to ? new Date(to) : ''}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => setTo(newValue)}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={pushArray}
              renderInput={(params) => (
                <TextField {...params} label="Standards" />
              )}
              onChange={(event, value) => setStandardName(value)}
            />
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={fromDate ? fromDate : new Date()}
                onChange={(e) => {
                  setFromDate(e);
                  console.log(fromDate);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={toDate ? toDate : new Date()}
                onChange={(newValue) => {
                  setToDate(newValue);
                  console.log(toDate);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              FilterData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Search
          </Button>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            id="datatable-keytable"
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rowData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  );
}
