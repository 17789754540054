import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./../global";
import { useEffect } from "react";
import EditTypeA from "./typeA/editTypeA";
import EditTypeB from "./workReport/typeB/editTypeB"
import EditBudget from "./workReport/budget/editBudget";
const axios = require("axios");

export default function ViewAll() {
  const {
    instrumentId,
    lc,
    range,
    datasheetId,
    tableId,
    // nominalVal,
    // datasheetReadingId,
  } = useParams();

  // const [datasheetStaticReadings, setDatasheetStaticReadings] = useState([]);
  // const [unitMap, setUnitMap] = useState({})
  const [state, setState] = useState({
    datasheetStaticReadings: [],
    unitMap: {}
  })

  function getUncertaintyUnit(rowIndex){
    let readings = state.datasheetStaticReadings;
    let uncertaintyUnit = ""
    readings.forEach((reading, index) =>{
      if(String(reading["uncertainty"])?.includes("_unit_")){
        uncertaintyUnit = reading["uncertainty"]
      }
      if(index == rowIndex)
        return;
    })
    return uncertaintyUnit?.replaceAll("$", "") || ""
  }

  function fetchStaticReadingRows() {
    // for static datasheet
    axios
      .get(
        BASE_URL +
          `datasheetStaticReadings?_where=(datasheetId,eq,${datasheetId})`
      )
      .then((res) => {
        let cU = null
        let map = {}
        res.data.map(data => {
          if (!map[data.tableId]) {
            map[data.tableId] = {}
          }
          if (data["c1"].includes("_unit_"))
            cU = data.id
          else {
            map[data.tableId][data.id] = cU
          }
        })
        setState({
          ...state,
          datasheetStaticReadings: res.data,
          unitMap: map
        })
      })
      .catch((err) => {
        console.log("datasheetStaticReadings fetching error: ", err);
      });
  }

  useEffect(() => {
    fetchStaticReadingRows();
  }, []);

  return (
    <>
      {state.datasheetStaticReadings &&
        state.datasheetStaticReadings.map((reading, idx) => {
          if(reading.c1.includes("_unit_") || reading.c1.includes("_rh_"))
            return null;
          // else
          return (
            <>
              <EditTypeA
                datasheetReadingId={reading.id}
                tableId={reading.tableId}
                datasheetId={reading.datasheetId}
                viewAll={true}
              />
              <EditTypeB
                datasheetReadingId={reading.id}
                datasheetId={reading.datasheetId}
                instrumentId={instrumentId}
                nominalVal={reading.c1}
                lc={lc}
                stdRangeIds={`${reading.standardRanges?.split(",")[0]}`}
                viewAll={true}
                supportiveMaster={`${reading.supportiveRanges}`}
                ucUnit = {getUncertaintyUnit(idx)}
              />
              <EditBudget
                datasheetReadingId={reading.id}
                datasheetId={reading.datasheetId}
                instrumentId={instrumentId}
                nominalVal={reading.c1}
                lc={lc}
                range={range}
                stdRangeIds={`${reading.standardRanges?.split(",")[0]}`}
                viewAll={true}
                supportiveMaster = {`${reading.supportiveRanges}`}
                unitRow={state.unitMap[reading.tableId][reading.id]}
                ucUnit = {getUncertaintyUnit(idx)}
              />
            </>
          );
        })}
    </>
  );
}
