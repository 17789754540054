import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField, Autocomplete } from "@mui/material"
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../global";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment"

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No" },
  { id: "name", label: "Discipline Name", minWidth: 40 },

  {
    id: "status",
    label: "Status",
    align: "left",
    minWidth: 40,
  },
];

function createData(id, name, status) {
  return {
    id,
    name,
    status,
  };
}

export default function DisciplineList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [disciplines, setDiscList] = React.useState("");
  const [searchBy, setSearchBy] = React.useState("")
  const [searchKey, setSearchKey] = React.useState("")
  const [from, setFrom] = React.useState(null)
  const [to, setTo] = React.useState(null)
  const _clientId = localStorage.getItem("clientId")
  const userType = localStorage.getItem("type");
  const [title, setTitle] = React.useState("")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getDList = (event) => {
    // let url = BASE_URL;
    // axios
    //   .get(url + "discipline?_where=(status,eq,1)")
    //   .then((res) => {
    //     setDiscList(res.data);
    //     // initializeDataTable();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    
    let whereConditions = ""
    let pagination_settings = ""

    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "Discipline Name": "name",
        "Status": "status"
      }[searchBy]
      whereConditions = `where ${colName} like '%${searchKey}%'`
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where "
      } else {
        whereConditions += " and "
      }

      whereConditions += ` qt.date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `
    }
    if (userType != '1')
      whereConditions += whereConditions.length > 0 ? `and qt.clientId=${_clientId}` : `where qt.clientId=${_clientId}`
    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${rowsPerPage * Number(page)}`
    }

    let data = {
      query: `select * from discipline as qt ${whereConditions} order by qt.id DESC ${pagination_settings}`
    }
    console.log(data.query)
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        // setQuotationList(res.data)
        setDiscList(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err)
      })
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  useEffect(() => {
    getDList();
  }, []);

  const search = () => {
    getDList()
  }

  const submitSiteDelete = (id) => {
    console.log(id);

    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `discipline/${id}`, jsonData)
      .then((res) => {
        toast("Discipline was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };
  var rows = [];

  for (let i = 0; i < disciplines.length; i++) {
    rows.push(
      createData(disciplines[i].id, disciplines[i].name, disciplines[i].status)
    );
  }

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Toolbar>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/master/discipline/createDiscipline"
          onClick={() => {
            setTitle("Create Discipline");
          }}
        >
          <b>ADD NEW DISCIPLINE</b>
        </Button>
      </Toolbar>
      <hr />
      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end", width: "100%" }}>
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
              label="From"
              value={from}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="To"
              value={to}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px"
          }}
          size="small"
          id="combo-box-demo"
          options={[
            { key: "name", label: "Discipline Name" },
            { key: "status", label: "Status" }
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue)
          }}
        />
        <TextField
          id="outlined-basic"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search()
          }}>
          <SearchIcon />
        </Button>
      </div>
      <br />

      <Table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="datatable-keytable"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    let value = row[column.id];
                    if (column.id == "id") {
                      value = index + 1;
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Discipline" placement="top-start">
                        <Button
                          style={{
                            borderColor: "#dc3545",
                          }}
                          component={Link}
                          to={`/master/discipline/editDiscipline/${row.id}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Delete Descipline" placement="top-start">
                        <Button
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Really want to delete descipline?"
                              )
                            ) {
                              submitSiteDelete(row.id);
                            }
                          }}
                        >
                          <DeleteIcon style={{ color: "#dc3545" }} />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </TableContainer>
  );
}
