import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { ClassicTable } from "../../utils/components/Styles";
import { generateNumber } from "../../utils/utils";

const axios = require("axios");

export default function CreateQuotation() {
  const [srfObject, createSrfObject] = React.useState({
    subject: "Quotation for Calibration of Equipment",
  });
  const [ProductsList, setProductList] = React.useState([]);
  const [customer, setCustomer] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [productArray, setProducts] = React.useState([]);
  const [amount, setAmount] = React.useState(0);
  const [totalAmount, setTotal] = React.useState(0);
  const [conveyanceCharges, setConveyanceCharges] = React.useState(0);
  const [courierCharges, setCourierCharges] = React.useState(0);
  const [executing, setExecuting] = React.useState(false);
  const [clientList, setClientList] = React.useState([]);
  const [clientArray, setCLientListArray] = React.useState([]);
  const [gstType, setGstType] = React.useState(null);
  const [cGst, setCGST] = React.useState(0);
  const [sGst, setSGST] = React.useState(0);
  const [terms, setTerms] = React.useState(null);
  const [qtId, setQtId] = React.useState(null);

  const counterAmt = () => {
    var count = 0;
    for (let i = 0; i < readingRows.length; i++) {
      if (readingRows[i][2] != "" && readingRows[i][3] != "") {
        count += parseInt(readingRows[i][2] * readingRows[i][3]);
      }
    }
    count += (conveyanceCharges + courierCharges)
    setTotal(count);
  };

  const fetchSettings = async () => {
    await axios.get(BASE_URL + `settings`).then(async (res) => {
      let remark = null,
        settings = res.data;
      await settings.map(async (setting) => {
        if (setting.keyName === "quotation terms") {
          remark = setting.value;
        }
        if (setting.keyName === "QuotationNumber") {
          // setQuotation(setting.value)
          await axios
            .get(BASE_URL + "quotation?_fields=id&_sort=-id")
            .then((res) => {
              let qt = generateNumber(
                setting.value,
                `${res.data.length > 1 ? res.data[0].id : 0}`
              );
              updateSrfObject("quotationNumber", qt);
            });
        }
      });
      setTerms(remark);
    });
  };

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
        width: 10,
      },
      {
        field: "Product Name",
        headerName: "Equipment Name",
        editable: true,
      },
      {
        field: "discription",
        headerName: "Description",
        editable: true,
      },
      {
        field: "Rate",
        headerName: "Rate",
        editable: true,
      },
      {
        field: "Quantity",
        headerName: "Quantity",
        editable: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        editable: true,
      },
    ],
  ];

  const [readingRows, setReadingRows] = React.useState([["", "", "", "", ""]]);

  // setTotal
  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    setExecuting(true);
    var totalAmounts = 0;

    if (readingRows.length) {
      readingRows.map((row) => {
        totalAmounts = totalAmounts + row[4];
      });
      var rows = readingRows.map((row) => ({
        srfInstrumentId: parseInt(row[0]),
        discription: row[1],
        rate: row[2],
        quantity: row[3],
        totalAmount: totalAmounts + parseInt(conveyanceCharges),
        clientId: parseInt(clientId),
        quotationNumber: srfObject.quotationNumber,
        referenceNumber: srfObject.referenceNumber,
        subject: srfObject.subject,
        date: moment(srfObject.date).format("YYYY-MM-DD"),
        termsConditions: terms,
        conveyanceCharges: conveyanceCharges,
        courierCharges: courierCharges,
        gstType: gstType,
      }));
    }
    let url = BASE_URL;
    axios
      .post(url + "quotation/bulk", rows)
      .then((res) => {
        setTimeout(refresh, 500);
        toast("Quotation created successfully !");
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject({ ...newSrfObject });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const updateAmount = (value) => {
    var newRowsData = [...amount];
    newRowsData += parseInt(value);
    setAmount(...newRowsData);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "", "", "", ""]);
    setReadingRows([...newReadingRows]);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initiateClients = () => {
    var pushArray = [];
    var pushCArray = [];

    for (let i = 0; i < customer.length; i++) {
      pushArray.push({
        id: customer[i].id,
        label: customer[i].companyName,
        contact: customer[i].contact,
        address: customer[i].address,
      });
      pushCArray.push({
        label: customer[i].id + ", " + customer[i].companyName,
      });
    }
    setClientList(pushArray);
    setCLientListArray(pushCArray);
  };

  const getProductList = () => {
    let url = BASE_URL;
    axios
      .get(url + "customProducts")
      .then((res) => {
        setProductList(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const updateRanges = () => {
    let grandTotal = 0;
    for (let i = 0; i < readingRows.length; i++) {
      grandTotal += readingRows[i][3] * readingRows[i][4];
    }
    // grandTotal += conveyanceCharges + courierCharges;
    // setTotal(Number(grandTotal).toFixed(2));
    // setGrandTotal((grandTotal + (grandTotal * 18) / 100).toFixed(2));
  };

  const initiateProducts = () => {
    let productsArray = [];

    for (let i = 0; i < ProductsList.length; i++) {
      productsArray.push({
        label: ProductsList[i].id + "," + ProductsList[i].productName,
        rate: ProductsList[i].rate,
      });
    }
    setProducts(productsArray);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    counterAmt();
  }, [readingRows, conveyanceCharges, courierCharges]);

  const invoicesData = () => {
    if (gstType === 1) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="SGST"
              InputLabelProps={{ shrink: true }}
              value={(9 * totalAmount) / 100}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="CGST"
              disabled
              value={(9 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else if (gstType == 2) {
      return (
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginTop: "10px" }}
          justifyContent="flex-end"
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="IGST"
              value={(18 * totalAmount) / 100}
              InputLabelProps={{ shrink: true }}
              disabled
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      );
    } else {
      <Grid
        container
        spacing={2}
        style={{ marginBottom: "10px", marginTop: "10px" }}
        justifyContent="flex-end"
      ></Grid>;
    }
  };

  useEffect(() => {
    initiateProducts();
  }, [ProductsList]);

  useEffect(() => {
    initiateClients();
  }, [customer]);

  useEffect(() => {
    getCustomer();
    getProductList();
    fetchSettings();
  }, []);

  return (
    <>
      {executing && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "rgba(255,255,255, 0.85)",
            zIndex: "2",
          }}
        >
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      )}
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Create Quotation
        </Typography>
        <Grid container spacing={2} style={{ marginBottom: "10px" }}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={clientArray}
              renderInput={(params) => (
                <TextField {...params} label="Company Name *" />
              )}
              onInputChange={(event, value) => {
                clientList.map((client) => {
                  if (client.id === parseInt(value.split(",")[0])) {
                    if (client.address) {
                      setClientId(client.id);
                      setAddress(client.address);
                      setContact(client.contact);
                    } else {
                      setAddress("");
                      setContact("");
                    }
                  }
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Address *"
              size="small"
              disabled
              value={address}
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateSrfObject("address", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Quotation Number *"
              size="small"
              fullWidth
              variant="outlined"
              value={srfObject.quotationNumber}
              onChange={(e) => {
                updateSrfObject("quotationNumber", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Reference Number *"
              size="small"
              fullWidth
              variant="outlined"
              onChange={(e) => {
                updateSrfObject("referenceNumber", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Subject *"
              size="small"
              fullWidth
              variant="outlined"
              value={srfObject.subject}
              onChange={(e) => {
                updateSrfObject("subject", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date *"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={srfObject.date ? new Date(srfObject.date) : ""}
                onChange={(newValue) => {
                  updateSrfObject("date", newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Terms Conditions"
              fullWidth
              rows={3}
              multiline
              variant="outlined"
              value={terms}
              maxRows={5}
              onChange={(e) => {
                setTerms(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={[
                { value: 1, label: "State GST" },
                { value: 2, label: "Central Gst" },
                { value: 3, label: "Non Gst" },
              ]}
              renderInput={(params) => (
                <TextField {...params} label="GST Type *" />
              )}
              onInputChange={(event, newInputValue) => {
                if (newInputValue == "State GST") {
                  setGstType(1);
                } else if (newInputValue == "Central Gst") {
                  setGstType(2);
                } else {
                  setGstType(3);
                }
                // setGstType(newInputValue == "State GST" ? 1 : 2);
                if (newInputValue == "State GST") {
                  setCGST((9 * totalAmount) / 100);
                  setSGST((9 * totalAmount) / 100);
                } else if (newInputValue == "Central Gst") {
                  setCGST((18 * totalAmount) / 100);
                } else {
                  setCGST(0);
                  setSGST(0);
                }
              }}
            />
          </Grid>
        </Grid>
        <hr />
        <h4 style={{ "margin-bottom": "0px" }}>Product Details </h4>
        <Button
          style={{ float: "right" }}
          onClick={(e) => {
            addDescriptionRow(0);
          }}
        >
          <AddIcon />
        </Button>
        <div style={{ width: "100%", overflow: "auto" }}>
          <ClassicTable>
            <Table sx={{ minWidth: 660 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {readingColumns[0].map((column) => (
                    <TableCell key={column.field}>
                      {" "}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {readingRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {row.map((cell, cellIndex) => {
                      if (cellIndex == 0) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Autocomplete
                              size="small"
                              id="combo-box-demo"
                              options={productArray}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(event, value) => {
                                if (value != null) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(value.label.split(",")[0])
                                  );
                                  updateCellValue(index, 2, value.rate);
                                }
                              }}
                            />
                          </TableCell>
                        );
                      }
                      if (cellIndex == 1) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                onChange={(e) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    e.target.value
                                  );
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 2) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={readingRows[index][cellIndex]}
                                variant="outlined"
                                onChange={(e) => {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    e.target.value
                                  );
                                  updateCellValue(
                                    index,
                                    4,
                                    parseInt(readingRows[index][3]) *
                                      parseInt(e.target.value)
                                  );
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 3) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setTotal(
                                      parseInt(readingRows[index][2]) *
                                        parseInt(e.target.value)
                                    );
                                    updateCellValue(
                                      index,
                                      cellIndex,
                                      parseInt(e.target.value)
                                    );
                                    updateCellValue(
                                      index,
                                      4,
                                      parseInt(readingRows[index][2]) *
                                        parseInt(e.target.value)
                                    );
                                  }
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                      if (cellIndex == 4) {
                        return (
                          <TableCell style={{ width: "200px" }}>
                            <Grid item xs={3}>
                              <TextField
                                id="outlined-basic"
                                size="small"
                                fullWidth
                                // disabled
                                InputLabelProps={{ shrink: true }}
                                value={readingRows[index][cellIndex]}
                                variant="outlined"
                                onChange={(e) => {
                                  setTotal(
                                    parseInt(amount) + parseInt(e.target.value)
                                  );
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    parseInt(amount) + parseInt(e.target.value)
                                  );
                                }}
                              />
                            </Grid>
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
                <br />
              </TableBody>
            </Table>
          </ClassicTable>
        </div>
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginLeft: "74.5%" }}
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="OnSite Charges"
              size="small"
              fullWidth
              variant="outlined"
              value={conveyanceCharges}
              onChange={(e) => {
                setConveyanceCharges(Number(e.target.value));
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginLeft: "74.5%" }}
        >
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label="Courier Charges"
              size="small"
              fullWidth
              variant="outlined"
              value={courierCharges}
              onChange={(e) => {
                setCourierCharges(Number(e.target.value));
              }}
            />
          </Grid>
        </Grid>
        {gstType !== 3 && gstType !== null ? (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "10px", marginLeft: "74.5%" }}
          >
            <Grid item xs={3} justify="flex-end">
              <TextField
                id="outlined-basic"
                label="Total Amount"
                size="small"
                value={totalAmount.toFixed(2)}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {gstType !== 3 && gstType !== null ? invoicesData() : ""}
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "10px", marginLeft: "74.5%" }}
        >
          <Grid item xs={3} justify="flex-end">
            <TextField
              id="outlined-basic"
              label="Grand Total Amount"
              size="small"
              value={(totalAmount * ((gstType !== 3 && gstType !== null) ? 1.18 : 1)).toFixed(2)}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Toolbar style={{ padding: "0px", overflow: "auto" }}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            disabled={executing}
            onClick={() => {
              gstType != null
                ? handleSubmit()
                : toast.error("Please select GST Type!");
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0, ml: 2 }}
            onClick={() => {
              // handleSubmit();
            }}
          >
            Print
          </Button>
        </Toolbar>
        <ToastContainer />
      </Paper>
    </>
  );
}
