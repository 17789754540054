import React from 'react'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

export default function StandardMasterSelection(props) {
  const {
    index,
    label,
    allStandards,
    staticReadingRows,
    activeRowIndex,
    updateStandardRangesOfRow,
    isSupportive,
    supportive
  } = props

  return (
    <div>
      <Autocomplete
        fullWidth
        id="checkboxes-tags-demo"
        size="small"
        options={
          allStandards != null
            ? allStandards.map((item) => {
              return {
                id: item.pl_id,
                stId: item.pl_stId,
                title: item.pl_standardName,
                rangeId: item.pr_id,
                range: item.pr_rangeName?.replaceAll("#", ""),
                accuracy: item.pr_accuracy?.replaceAll("#", ""),
                lc: item.pr_gravity?.replaceAll("#", ""),
                axialUniformity: item.pr_axialUniformity?.replaceAll("#", ""),
                radialUniformity: item.pr_radialUniformity?.replaceAll("#", ""),
                stability: item.pr_stability?.replaceAll("#", ""),
              }
            })
            : []
        }
        defaultValue={
          allStandards != null
            ? allStandards
              .filter((item) =>
                (
                  isSupportive ?
                    (supportive && supportive[activeRowIndex] ? supportive[activeRowIndex] : "") :
                    staticReadingRows[activeRowIndex][
                    staticReadingRows[activeRowIndex].length - 2
                    ]
                )
                  ?.split(',')
                  ?.includes(String(item.pr_id)),
              )
              .map((item) => {
                if (isSupportive)
                  return {
                    id: item.pl_id,
                    stId: item.pl_stId,
                    title: item.pl_standardName,
                    rangeId: item.pr_id,
                    range: item.pr_rangeName?.replaceAll("#", ""),
                    axialUniformity: item.pr_axialUniformity?.replaceAll("#", ""),
                    radialUniformity: item.pr_radialUniformity?.replaceAll("#", ""),
                    stability: item.pr_stability?.replaceAll("#", ""),

                  }
                return {
                  id: item.pl_id,
                  stId: item.pl_stId,
                  title: item.pl_standardName,
                  rangeId: item.pr_id,
                  range: item.pr_rangeName?.replaceAll("#", ""),
                  accuracy: item.pr_accuracy?.replaceAll("#", ""),
                  lc: item.pr_gravity?.replaceAll("#", ""),

                }
              })[index]
            : []
        }
        getOptionLabel={(option) => {
          console.log("get Option : ", option)

          if (isSupportive)
            return String(option.range) +
              ', ' +
              option.title +
              ', ' +
              (option.axialUniformity ? option.axialUniformity : "") +
              ', ' +
              (option.radialUniformity ? option.radialUniformity : "") +
              ', ' +
              (option.stability ? option.stability : "")

          return String(option.range) +
            ', ' +
            option.title +
            ', ' +
            option.accuracy +
            ', ' +
            option.lc
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.stId} <b>:</b> {option.title}, {option.range}
            ,&nbsp;&nbsp;
            {!isSupportive &&
              (<span style={{ color: '#335FFF' }}>
              accuracy: {option.accuracy}
              </span>)
            }
            {isSupportive &&
              (<span style={{ color: '#335FFF' }}>
              Axial Uniformity: {option.axialUniformity}
              </span>)
            }{' '}
            ,&nbsp;&nbsp;
            {!isSupportive &&
              <span style={{ color: '	#148F77' }}>LC: {option.lc}</span>
            }
            {isSupportive &&
              <span style={{ color: '	#148F77' }}>Radial Uniformity: {option.radialUniformity}</span>
            }
            {
              isSupportive && (
                <>
                  ,&nbsp;&nbsp;
                  <span style={{ color: '	#FF5533' }}>Stability: {option.stability}</span>
                </>
              )
            }
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="select multiple standards"
          />
        )}
        onChange={(event, value) => {
          updateStandardRangesOfRow(String(value?.rangeId || ""), isSupportive ? -1 :+ index + 1)

        }}
        disableCloseOnSelect
      />
    </div>
  )
}
