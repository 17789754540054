import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from "moment";

const axios = require("axios");

export default function UpdateFeedback() {
  const [date, setDate] = React.useState("");
  const [name, setName] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [p1, setp1] = React.useState("");
  const [p2, setp2] = React.useState("");
  const [p3, setp3] = React.useState("");
  const [p4, setp4] = React.useState("");
  const [p5, setp5] = React.useState("");
  const [p6, setp6] = React.useState("");
  const [p7, setp7] = React.useState("");
  const [p8, setp8] = React.useState("");
  const [firstCol, setFirstCol] = React.useState("");
  const [secondCol, setSecondCol] = React.useState("");
  const params = useParams();

  const getSupprtiveList = () => {
    let url = BASE_URL;
    axios
      .get(url + `feedback/${params.id}`)
      .then((res) => {
        setDate(res.data[0].date);
        setName(res.data[0].name);
        setContactNumber(res.data[0].contactNumber);
        setp1(res.data[0].p1);
        setp2(res.data[0].p2);
        setp3(res.data[0].p3);
        setp4(res.data[0].p4);
        setp5(res.data[0].p5);
        setp6(res.data[0].p6);
        setp7(res.data[0].p7);
        setp8(res.data[0].p8);
        setFirstCol(res.data[0].firstCol);
        setSecondCol(res.data[0].secondCol);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getSupprtiveList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    var jsonData = {
      date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      name: name,
      contactNumber: contactNumber,
      p1: p1,
      p2: p2,
      p3: p3,
      p4: p4,
      p5: p5,
      p6: p6,
      p7: p7,
      p8: p8,
      firstCol: firstCol,
      secondCol: secondCol,
    };

    let url = BASE_URL;

    axios
      .patch(url + `feedback/${params.id}`, jsonData)
      .then((res) => {
        toast("Updated Feedback!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Feedback
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={date ? date : new Date()}
              onChange={(newValue) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Name"
            size="small"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="outlined-basic"
            label="Contact Number"
            size="small"
            fullWidth
            variant="outlined"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <div className="border-dark p-5 border">
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">QUALITY OF SERVICE</Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p1}
              onChange={(event, newValue) => {
                setp1(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              TIMELY DELIVERY OF CALIBRATED INSTRUMENTS
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p2}
              onChange={(event, newValue) => {
                setp2(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              TIMELY DELIVERY OF CALIBRATED CERTIFICATES
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p3}
              onChange={(event, newValue) => {
                setp3(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">CALIBRATION REPORTS</Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p4}
              onChange={(event, newValue) => {
                setp4(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              ENGINEERS PERFORMANCE AT SITE
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p5}
              onChange={(event, newValue) => {
                setp5(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              RESPONSE TIME OF YOUR ENQUIRIES
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p6}
              onChange={(event, newValue) => {
                setp6(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">
              CONFORT LEVEL IN DEALING WITH NAGMAN
            </Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p7}
              onChange={(event, newValue) => {
                setp7(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4} style={{ textAlign: "left" }}>
            <Typography component="legend">PACKING AND DISPATCH</Typography>
          </Grid>{" "}
          <Grid item xs={4}>
            <Rating
              name="no-value"
              value={p8}
              onChange={(event, newValue) => {
                setp8(newValue);
              }}
            />
          </Grid>{" "}
        </Grid>
      </div>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="If rated below 3 (Either 2 or 1) please inform us the reason"
            rows={3}
            InputLabelProps={{ shrink: true }}
            multiline
            value={firstCol}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setFirstCol(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Suggestions for improvement in quality and services"
            rows={3}
            InputLabelProps={{ shrink: true }}
            multiline
            value={secondCol}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setSecondCol(e.target.value)}
          />
        </Grid>
      </Grid>
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            onSubmit();
          }}
        >
          Edit Feedback
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
