import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
// import srflogo from "../image/srflogo.png";
import moment from "moment";
import {
    Grid,
    Paper,
    TextField,
    Typography,
    Autocomplete,
    Toolbar,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextareaAutosize,
    Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useReactToPrint } from "react-to-print";

import { ClassicTable } from "./../../utils/components/Styles";

const axios = require("axios");

export default function ViewSrf() {
    const navigate = useNavigate();
    const printComponentRef = React.useRef();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [srfObject, createSrfObject] = React.useState([]);
    const [clientArray, setClientArray] = React.useState([]);
    const [srfInstruments, setSrfInstruments] = React.useState("");
    var [standardArray, setStandardArray] = React.useState("");
    const [clientId, setClientId] = React.useState("");
    const [poNumber, setPoNumber] = React.useState("");
    const [poDate, setpotDate] = React.useState("");
    const [committedDeliveryDate, setCommittedDeliveryDate] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [standardMasterArray, setStandardMasterArray] = React.useState([]);
    const [srfListData, setSrfList] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [address, setAddress] = React.useState("");
    const [serviceReqNumber, setServiceReqNumber] = React.useState("");
    const [deliveryDate, setDeliveryDate] = React.useState("");
    const [receiptDate, setReceiptDate] = React.useState("");
    const [instrument, setInstrument] = React.useState(null);
    var [allInstruments, setInstruments] = React.useState([]);

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
    });

    function fetchInstrument(instrumentId) {
        let url = BASE_URL;
        axios
            .get(url + `instruments?_where=(id,eq,${instrumentId})`)
            .then((res) => {
                setInstrument(res.data);
            })
            .catch((err) => {
                console.log("certificate tables data fetching error: ", err);
            });
    }

    const params = useParams()
    const getSrfsList = (event) => {
        let url = BASE_URL;
        axios
            .get(
                url +
                "xjoin?_join=pl.srfs,_j,pr.clients&_on1=(pl.clientId,eq,pr.id)&_fields=pl.clientId,pr.companyName,pl.serviceReqNumber,pl.committedDeliveryDate,pl.receiptDate,pl.id,pl.address&_where=(pl.status,eq,1)"
            )
            .then((res) => {
                if (res.data.length && res.data[0] != null) {
                    setCompanyName(res.data[0].pr_companyName);
                    setAddress(res.data[0].pl_address);
                    setServiceReqNumber(res.data[0].pl_serviceReqNumber);
                    setDeliveryDate(res.data[0].pl_committedDeliveryDate);
                    setReceiptDate(res.data[0].pl_receiptDate);
                    setSrfList(res.data);
                    console.log("ddddddddddddd", res.data)
                }
                fetchInstrument(res.data[0].cer_instrumentId);
                if (res.data[0].cer_additionalStandardIds) {
                    let standardMasterIds =
                        res.data[0].cer_additionalStandardIds;

                    var splitStData = standardMasterIds.split(",");
                    var combineArray = [];
                    splitStData.forEach(function (element) {
                        var splitByCol = element.split(":")[0];
                        combineArray.push(splitByCol);
                    });
                    axios
                        .get(
                            url +
                            `standards?_where=(id,in,${combineArray.toString()})`
                        )
                        .then((res) => {
                            console.log(res.data)
                            setStandardMasterArray(res.data);
                        });
                } else if (res.data[0].ins_standardMasterIds) {
                    var splitStData =
                        res.data[0].ins_standardMasterIds.split(",");
                    var combineArray = [];
                    splitStData.forEach(function (element) {
                        var splitByCol = element.split(":")[0];
                        combineArray.push(splitByCol);
                    });
                    console.log(combineArray.toString());
                    axios
                        .get(
                            url +
                            `standards?_where=(id,in,${combineArray.toString()})`
                        )
                        .then((res) => {
                            setStandardMasterArray(res.data);
                            console.log("standards", res.data);
                        });
                }
            }

            )
            .catch((error) => {
                console.log(error);
            });
    };


    const getClientIdList = () => {
        let url = BASE_URL;
        axios
            .get(url + 'clients?_where=(status,eq,1)')
            .then((res) => {
                setClientArray(res.data);
            })
            .catch((error) => {
                // toast.error("Something Went Wrong!");
            });
    }

    const srfInstrumentsList = () => {
        let url = BASE_URL;
        axios
            .get(url + `srfInstruments/${params.id}`)
            .then((res) => {
                setSrfInstruments(res.data);

            })
            .catch((error) => {
                // toast.error("Something Went Wrong!");
            });
    }

    const instrumentsList = () => {
        let url = BASE_URL;
        axios
            .get(url + `instruments?_where=(status,eq,1`)
            .then((res) => {
                setInstruments(res.data);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });
    };


    const initiateRanges = () => {
        let rows = [];
        for (let i = 0; i < srfInstruments.length; i++) {
            rows.push([
                srfInstruments[i].id,
                srfInstruments[i].instrumentId,
            ]);
        }

        for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < allInstruments.length; j++) {
                if (allInstruments[j].id === rows[i][1]) {
                    rows[i][1] = rows[i][0] + ", " + allInstruments[j].instrumentName;
                    // delete rows[i][0];
                }
            }
        }
        console.log(rows);
        setReadingRows(rows);
    };

    var pushCArray = [];
    for (let i = 0; i < clientArray.length; i++) {
        pushCArray.push({
            label: clientArray[i].id + ', ' + clientArray[i].companyName,
        });
    }

    const readingColumns = [
        [
            {
                field: "id",
                headerName: "Sr. No.",
                editable: false,
            },
            {
                field: "description",
                headerName: "Description",
                editable: true,
            },
            {
                field: "range",
                headerName: "Range",
                editable: true,
            },
            {
                field: "lc",
                headerName: "L.C.",
                editable: true,
            },
            {
                field: "make",
                headerName: "Make",
                editable: true,
            },
            {
                field: "conOfReceipt",
                headerName: "Con. of Receipt",
                editable: true,
            },

            {
                field: "calibrationFrequency",
                headerName: "Calibration frequency",
                editable: true,
            },
            {
                field: "accuracy",
                headerName: "Accuracy",
                editable: true,
            },

            {
                field: "Action",
            },
        ],
    ];
    console.log("srfInstruments", srfInstruments)
    var pushArray = [];

    for (let i = 0; i < srfInstruments.length; i++) {
        pushArray.push({
            instrumentId: allInstruments.find(
                (element) =>
                    element.id == srfInstruments[i].instrumentId
            )?.instrumentName,
            ranges: srfInstruments[i].ranges,
            lc: srfInstruments[i].lc,
            make: srfInstruments[i].make,
            conOfReceipt: srfInstruments[i].conOfReceipt,
            calFrequency: srfInstruments[i].calFrequency,
            accuracy: srfInstruments[i].accuracy,
        }



        )
    }
    console.log("pushArray", pushArray)

    var dataaa = [1, '2', 3, '4', 5, 6, 7]

    const [readingRows, setReadingRows] = React.useState([
        dataaa,
    ]);


    const instrumentList = [
        {
            label: "1, instrument 1",
        },
        {
            label: "2, instrument 2",
        },
    ];

    useEffect(() => {
        srfInstrumentsList();
        getClientIdList();
        getSrfsList();
        instrumentsList();
    }, []);

    useEffect(() => {
        initiateRanges();
    }, [srfInstruments, allInstruments]);

    // submit form
    const submitDescriptionRows = (readingRows, id) => {
        let rows = readingRows.map((row) => ({
            srfsId: id,
            instrumentId: 1,
            ranges: row[1],
            lc: row[2],
            make: row[3],
            conOfReceipt: row[4],
            calFrequency: row[5],
            accuracy: row[6],
        }));
        return
        rows.map((rows) => {
            let url = BASE_URL;
            axios
                .post(url + "srfInstruments", rows)
                .then((res) => {
                    toast("SRF Update successfully !");
                    setTimeout(refresh, 1000);
                })
                .catch((error) => {
                    toast.error("Something Went Wrong!");
                });
        });
    };

    const submitDescriptionRows2 = (readingRows, insertedID, id) => {
        let rows = readingRows.map((row) => ({
            clientId: id,
            instrumentId: 1,
            ranges: row[1],
            lc: row[2],
            make: row[3]
        }));
        rows.map((rows) => {
            let url = BASE_URL;
            axios
                .post(url + "datasheets", rows)
                .then((res) => {
                    // toast("datasheets created successfully !");
                    // setTimeout(refresh, 1000);
                })
                .catch((error) => {
                    toast.error("Something Went Wrong!");
                });
        });
    };

    var refresh = () => {
        window.location.reload(false);
    };

    const handleSubmit = (event) => {
        console.log(srfObject)
        // console.log("readingRows", readingRows)
        let row = {
            clientId: srfObject.clientId,
            receiptDate: receiptDate,
            poNumber: poNumber,
            poDate: poDate,
            committedDeliveryDate: committedDeliveryDate,
            serviceReqNumber: serviceReqNumber,
            description: description,
        };
        let url = BASE_URL;
        axios
            .patch(url + `srfs/${params.id}`, row)
            .then((res) => {
                toast("SRF updated successfully !");
                var insertedID = res.data.insertId;
                submitDescriptionRows(readingRows, insertedID);
                // submitDescriptionRows2(readingRows, insertedID, row.clientId);
                // setTimeout(refresh, 1000);
            })
            .catch((error) => {
                toast.error("Something Went Wrong!");
            });
    };

    //ToDo: fetch cutomers from database

    // data operations
    const updateSrfObject = (key, value) => {
        let newSrfObject = {
            ...srfObject,
        };
        newSrfObject[key] = value;
        createSrfObject(newSrfObject);
    };
    const updateCellValue = (rowIndex, colIndex, value) => {
        const newRows = [...readingRows];
        newRows[rowIndex][colIndex] = value;
        setReadingRows([...newRows]);
    };

    const addDescriptionRow = (tableIndex) => {
        let newReadingRows = [...readingRows];
        newReadingRows.push(["", "", "", "", "", "", ""]);
        setReadingRows([...newReadingRows]);
    };

    const deleteReadingRow = (tableIndex, rowIndex) => {
        let newReadingRows = [...readingRows];
        newReadingRows.splice(rowIndex, 1);
        setReadingRows([...newReadingRows]);
    };

    var refresh = () => {
        window.location.reload(false);
        navigate("/srf");
    };

    return (
        <Paper sx={{ mt: 2, p: 2 }}>
            <div className="srf" ref={printComponentRef}>
                <div style={{ width: "100%", border: '1px solid black', lineHeight: '16px' }}>
                    <h4 style={{ fontWeight: 'bold', lineHeight: '16px', textAlign: 'center', marginTop: '4px' }}>GLOBAL CALIBRATION</h4>
                    <div className="row" style={{ lineHeight: '16px' }}>
                        <div className="col-3">
                            {/* <img className="srfLogo" src={srflogo} alt="IMG" /> */}
                        </div>
                        <div className="col-6 mt-4">
                            <h2>SERVICE REQUEST FORM</h2>
                        </div>
                        <div className="col-3 ">
                            <p>Rev No.01<br />Rev Date: 29.09.2019</p>

                        </div>
                    </div>
                </div>
                <div className="srfFulfilled" style={{ width: "100%", border: '1px solid black', lineHeight: '16px' }}>
                    <h3>To be filled by customer    </h3>
                    <hr style={{ marginTop: '-3px' }} />
                    <div className="row" style={{ textAlign: 'left', marginLeft: '5px', lineHeight: '16px' }}>
                        <div className="col-7">
                            <span>Customer’s Name: M/s. {companyName} </span><br />
                            <span>Address: {address}.
                            </span><br />
                            <span>Ph. No.: -</span><br />
                            <span>Through: -</span>
                        </div>
                        <div className="col-5">
                            <span>Service Request No.: : {serviceReqNumber} </span><br />
                            <span>Entry Date: 20.03.2021
                            </span><br />
                            <span>Committed Date : {moment(deliveryDate).format("DD-MM-YYYY")} </span><br />
                            <span>Customer Ref. No/.:</span><br />
                            <span>Receipt Date: {moment(receiptDate).format("DD-MM-YYYY")} </span>
                        </div>

                    </div>
                    <div className="row">
                        <table style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }} className="srftable">
                            <thead>
                                <tr>
                                    <th>sr.No</th>
                                    <th>Description of Item</th>
                                    <th>Make</th>
                                    <th>Range/Size(mm)</th>
                                    <th>L.C.</th>
                                    <th>Condition of receipt</th>
                                    <th>Calibration Frequency</th>
                                </tr>
                            </thead>
                            {pushArray.length > 0 ? (
                                <tbody>
                                    {pushArray.map((item, index) => {
                                        return (
                                            <tr style={{ lineHeight: '13px' }}>
                                                <td style={{ padding: '7px' }}>{index + 1}</td>
                                                <td style={{ padding: '7px' }}>{item.instrumentId}</td>
                                                <td style={{ padding: '7px' }}>{item.make}</td>
                                                <td style={{ padding: '7px' }}>{item.ranges}</td>
                                                <td style={{ padding: '7px' }}>{item.lc}</td>
                                                <td style={{ padding: '7px' }}>OK</td>
                                                <td style={{ padding: '7px' }}>{item.calFrequency}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="6">No items added</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        <span style={{ marginLeft: 'auto', marginRight: 'auto', lineHeight: '16px', marginTop: '3px' }}> *** Review by global Customer Support ***</span>
                    </div>

                    <p style={{ textAlign: 'start', fontWeight: 'bold', marginLeft: '5px', lineHeight: '16px' }}>Remarks:-</p>
                    <div className="row" style={{ lineHeight: '16px' }}>
                        <div className="col-6">
                            <p style={{ textAlign: 'start', fontWeight: 'bold', marginLeft: '5px' }}>* We accept the terms and conditions (shown
                                overleaf).</p>
                            <p style={{ textAlign: 'start', fontWeight: 'bold', marginLeft: '5px' }}>Acceptance</p><br /><br />
                            <p style={{ textAlign: 'start', fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }}>Customer’s Signature</p>
                        </div>
                        <div className="col-6">
                            <p style={{ textAlign: 'start', fontWeight: 'bold', marginLeft: '5px' }}>For star LABORATORY</p><br /><br /><br />

                            <p style={{ textAlign: 'start', fontWeight: 'bold', marginLeft: '5px', fontSize: '20px' }}>Customer Support Division</p>

                        </div>
                    </div>
                </div>
                <div className="note1" style={{ width: "100%", border: '1px solid black', lineHeight: '16px' }}>
                    <h5 className="note1" style={{ marginTop: '.5em', lineHeight: '16px' }}><b>Note :-</b> Conformity Statement Required: YES / NO. If Conformity statement is required, then
                        Decision Rule will be followed by the Lab. Decision Rule: - If the UUC is within
                        specification limits, considering the sum of maximum error & associated uncertainty (±),
                        then the Acceptance statement is given on the Calibration certificate, otherwise Rejection
                        statement is given. There may be Risk of False Acceptance OR False Rejection.</h5><br />
                    <h5 ><b> Note :- </b>1. If you will Required calibration certificate under NABL log YES / NO.
                        2. If you will Required Calibration Next Due Date YES / NO.
                        3. If you will Required any specific calibration point etc. than you should be motion on your
                        challan coy or P.O.
                    </h5>
                    <p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginTop: '10px', color: 'black', lineHeight: '16px' }}>Terms and conditions </p>
                    <p style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px', textAlign: 'left', marginLeft: '5px', color: 'black', lineHeight: '16px' }}>1) The mode of payment – Cheque / Cash / Demand Draft only in the favour of “star” for the full amount <br />
                        2) All items submitted for calibration must be serviceable & free from damage.<br />
                        3) An item accepted for calibration may be returned uncalibrated under circumstances beyond control.<br />
                        4) All possible care will be taken in handling the item in laboratory, but applicant is responsible for the
                        damages during transit (to & from laboratory)<br />
                        5) All possible care will be taken in handling the item in laboratory, but applicant is responsible for the
                        damages during transit (to & from laboratory)<br />
                        Some examples of documents are :-
                        A) Manufactures data sheet with details of the item such as model and Sr.No.
                        B) Copy of applicable standard specification.
                        C) Calibration procedure (in case of nonstandard/unique calibration) specified by you.
                        D) Sketch / Block diagrams etc.<br />
                        6) An item accepted for calibration may be returned uncalibrated under circumstances beyond control.<br />
                        7) Where applicant doesn’t provide any calibration method, global assumes that the calibration methods &
                        measurement capabilities (available at global in the form of documents) are well known to applicant and
                        acceptable<br />
                        8) Items submitted for calibration must be accompanied by details of item, model & Sr. No. (Accessories to
                        be specified separately).
                        <br />
                        9) The jobs will be undertaken at the prevailing rates fixed by global<br />
                        10) The conditions are subject to any changes / modifications without prior notice</p>

                </div>
            </div>



            <Toolbar style={{ padding: "0px", overflow: "auto" }}>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ m: 0 }}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    size="small"
                    sx={{ ml: 3 }}
                    onClick={handlePrint}
                >
                    Print
                </Button>
            </Toolbar>
            <ToastContainer />
        </Paper>
    );
}
