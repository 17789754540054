import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  Toolbar,
  Button,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import { API_URL } from "../../../../src/global";
const axios = require("axios");

export default function UpdateEnquiry(props) {
  const [srfObject, createSrfObject] = React.useState({});

  var [clientArray, setClientArray] = React.useState([]);
  var [address, setAddress] = React.useState("");
  var [contact, setContact] = React.useState("");
  const [contactPersonName, setContactPersonName] = React.useState("");
  const [emailId, setEmailId] = React.useState("");
  const [followUpDate, setFollowUpDate] = React.useState("");
  const [date, setDate] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [rating, setRating] = React.useState("");
  const [clientName, setClientName] = React.useState("");
  const [add, setAdd] = React.useState("");
  const [cont, setCont] = React.useState("");
  const params = useParams();

  const getClientIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  const getEnquiryList = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=en.enquiry,_j,client.clients&_on1=(en.clientID,eq,client.id)&_fields=en.contactPersonName,en.emailId,en.followUpDate,en.date,en.remark,en.rating,client.companyName,client.address,client.contact,client.id&_where=(en.id,eq,${params.id})`
      )
      .then((res) => {
        setContactPersonName(res.data[0].en_contactPersonName);
        setEmailId(res.data[0].en_emailId);
        setFollowUpDate(res.data[0].en_followUpDate);
        setDate(res.data[0].en_date);
        setRemark(res.data[0].en_remark);
        setRating(res.data[0].en_rating);
        setClientName(
          res.data[0].client_id + ", " + res.data[0].client_companyName
        );
        setContact(res.data[0].client_contact);
        setAddress(res.data[0].client_address);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    let row = {
      // clientId: clientId,
      contactPersonName: contactPersonName,
      emailId: emailId,
      followUpDate: moment(followUpDate).format("YYYY-MM-DD"),

      date: moment(date).format("YYYY-MM-DD"),

      remark: remark,
      rating: rating,
    };
    let url = BASE_URL;
    axios
      .patch(url + `enquiry/${params.id}`, row)
      .then((res) => {
        props.setLoader(false);
        toast("Enquiry updated successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
      contact: clientArray[i].contact,
    });
  }

  useEffect(() => {
    getClientIdList();
    getEnquiryList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Enquiry
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={clientName}
            options={pushCArray}
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            disabled
            onInputChange={(event, newInputValue) => {
              updateSrfObject(
                "clientId",
                parseInt(newInputValue.split(",")[0])
              );
              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue.split(",")[0])) {
                  if (client.address) {
                    setAdd(client.address);
                    setCont(client.contact);
                  } else {
                    setAdd("");
                    setCont("");
                  }
                }
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={add}
            variant="outlined"
            disabled
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Contact Person Name *"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            value={contactPersonName}
            onChange={(e) => {
              setContactPersonName(e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="email Id *"
            InputLabelProps={{ shrink: true }}
            size="small"
            value={emailId}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setEmailId(e.target.value)
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            value={remark}
            InputLabelProps={{ shrink: true }}
            label="Remarks"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setRemark(e.target.value)
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            renderInput={(params) => <TextField {...params} label="Rating" />}
            id="combo-box-demo"
            options={[
              {
                id: 1,
                label: "HOT",
              },
              {
                id: 2,
                label: "COLD",
              },
              {
                id: 3,
                label: "NOT INTERESTED",
              },
            ]}
            value={rating}
            onInputChange={(event, newInputValue) => {
              console.log(newInputValue);
              setRating(newInputValue)
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={date ? new Date(date) : ""}
              onChange={(newValue) => {
                setDate(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Followup Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                followUpDate ? new Date(followUpDate) : ""
              }
              onChange={(newValue) => {
                setFollowUpDate(newValue)
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Edit Enquiry
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
