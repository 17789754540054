import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";

const axios = require("axios");

export default function CreateClient(props) {
  const [srfObject, createSrfObject] = React.useState({});
  const [readingRows, setReadingRows] = React.useState([["", "",""]]);
  const [calibratedByChecked, setCalibratedByChecked] = React.useState("false");
  const [checkedByChecked, setCheckedByChecked] = React.useState("false");
  const [approvedByChecked, setApprovedByChecked] = React.useState("false");
  const [reviewedByChecked, setReviewedByChecked] = React.useState("false");
  const [signedByChecked, setSignedByChecked] = React.useState("false");

  var refresh = () => {
    window.location.reload(false);
  };

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "contactPersonName",
        headerName: "Contact Person Name",
        editable: true,
      },
      {
        field: "contactNo",
        headerName: "Contact No",
        editable: true,
      },
      {
        field: "email",
        headerName: "Email Id",
        editable: true,
      },
    ],
  ];

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addDescriptionRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(["", "",""]);
    setReadingRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined) {
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };
  const onSubmit = (event) => {
    let rows = readingRows.map((row) => ({
      contact: row[0],
      contactPersonName: row[1],
      emailId: row[1],
    }));
    var checkedObj = {
      forOrganization: {
        calibratedBy: calibratedByChecked.toString(),
        checkedBy: checkedByChecked.toString(),
        approvedBy: approvedByChecked.toString(),
      },
      forClient: {
        reviewedBy: reviewedByChecked.toString(),
        signedBy: signedByChecked.toString(),
      },
    };
    let row = {
      companyName: srfObject.companyName,
      contact: rows.length ? JSON.stringify(rows) : null,
      address: srfObject.address,
      gstNumber: srfObject.gstNumber,
      state: srfObject.state,
      email: srfObject.email,
      shippingAddress: srfObject.shippingAddress,
      signature: JSON.stringify(checkedObj),
    };

    let url = BASE_URL;
    axios
      .post(url + "clients", row)
      .then((res) => {
        props.setLoader(false);
        toast("clients created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create new client
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Company Name *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("companyName", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Billing Address"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("address", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Shipping Address"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("shippingAddress", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="GST Number *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("gstNumber", e.target.value);
            }}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="State *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("state", e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Email *"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfObject("email", e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <InputLabel id="demo-simple-select-label" style={{ float: "left" }}>
        For Organization
      </InputLabel>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setCalibratedByChecked(e.target.checked);
                }}
              />
            }
            label="Calibrated By"
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setCheckedByChecked(e.target.checked);
                }}
              />
            }
            label="Checked By"
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setApprovedByChecked(e.target.checked);
                }}
              />
            }
            label="Approved By"
          />
        </Grid>
      </Grid>
      <br />
      <InputLabel id="demo-simple-select-label" style={{ float: "left" }}>
        For Client
      </InputLabel>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setReviewedByChecked(e.target.checked);
                }}
              />
            }
            label="Reviewed By"
          />
        </Grid>

        <Grid item xs={2}>
          <FormControlLabel
            classes={{
              justifyContent: "space-between",
              alignItems: "left",
            }}
            control={
              <Checkbox
                onChange={(e) => {
                  setSignedByChecked(e.target.checked);
                }}
              />
            }
            label="Signed By"
          />
        </Grid>
      </Grid>
      <br />
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addDescriptionRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    return (
                      <TableCell>
                        <TextField
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        ></TextField>
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save Client
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
