import React, { useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useSnackbar } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

// import { ClassicTable } from "/../utils/components/Styles";
import { BASE_URL } from "./../../global";
// import { insert, view } from "/../components/common/api";
import { integerPropType } from "@mui/utils";
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';


const axios = require("axios");

export default function WorkReport() {
  const navigate = useNavigate();
  const [assignJobs, setAssignJobs] = React.useState([]);
  const [approve, setApprove] = React.useState([]);
  const [reject, setReject] = React.useState([]);
  const [certificate, setCertificates] = React.useState([]);
  const [dispatch, setDispatch] = React.useState([]);
  const [filteredInwards, setFilteredInwards] = React.useState(0);
  const [jobAssigned, setJobAssigned] = React.useState(0);

  
  const getInwardInstrument = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "datasheets?_where=(status,eq,1)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        var resultProductData = res.data.filter(a => {
          var date = moment(a.lastModified).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setFilteredInwards(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getAssignedJobs = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "datasheets/count?_where=(status,eq,1)")
      .then((res) => {
        setAssignJobs(res.data[0].no_of_rows);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getApprove = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "certificates?_where=(status,eq,3)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        let resultProductData = res.data.filter(a => {
          var date = moment(a.lastModified).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setApprove(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const getRejection = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "certificates?_where=(status,eq,4)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        let resultProductData = res.data.filter(a => {
          var date = moment(a.lastModified).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setReject(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCertificates = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "certificates?_where=(status,eq,1)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        let resultProductData = res.data.filter(a => {
          var date = moment(a.dateOfIssue).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setCertificates(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDispatchInstruments = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "dispatch?_where=(status,eq,1)")
      .then((res) => {
        var startDate = moment(new Date()).format("DD-MM-YYYY");
        var endDate = moment().subtract(0, 'day').format("DD-MM-YYYY");
        let resultProductData = res.data.filter(a => {
          var date = moment(a.dispatchDate).format("DD-MM-YYYY");
              return (date >= endDate && date <= startDate);
          });
          setDispatch(resultProductData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getJobAssigned = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "datasheets")
      .then((res) => {
        // var start = new Date();
        // start.setUTCHours(0,0,0,0);
        
        // var end = new Date();
        // end.setUTCHours(23,59,59,999);
        var start = new Date();
        start.setDate(start.getDate()-1);
        var end = new Date();
        end.setDate(end.getDate()+1); 
        if(res.data && res.data.length)
        var resultProductData = res.data?.filter(
            (data) =>
              new Date(data.calibrationDate) >= new Date(start.toUTCString()) 
              && new Date(data.calibrationDate) <= new Date(end.toUTCString()) 
              && data.calibrationDate!=null
          );
          console.log(resultProductData)
        setJobAssigned(resultProductData.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCompletedJobAssigned = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "datasheets")
      .then((res) => {
        // var start = new Date();
        // start.setUTCHours(0,0,0,0);
        
        // var end = new Date();
        // end.setUTCHours(23,59,59,999);
        var start = new Date();
        start.setDate(start.getDate()-1);
        var end = new Date();
        end.setDate(end.getDate()+1); 
        if(res.data && res.data.length)
        var resultProductData = res.data?.filter(
            (data) =>
              new Date(data.calibrationDate) >= new Date(start.toISOString()) 
              && new Date(data.calibrationDate) <= new Date(end.toISOString()) 
              && data.calibrationDate!=null
          );
          console.log(resultProductData)
        setJobAssigned(resultProductData.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getAssignedJobs();
    getApprove();
    getRejection();
    getCertificates();
    getDispatchInstruments();
    getInwardInstrument();
    getJobAssigned();
    getCompletedJobAssigned();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      {/* <Grid className="mb-4" container spacing={2}>

        <Grid item xs={2}>
          <TextField
            id="Start Date"
            label="Date From"
            variant="outlined"
            type="date"
            className="mr-2"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
          // value={fromDate}
          // onChange={(e) =>
          //     // setFromDate(e.target.value)
          // }
          />
        </Grid>


        <Grid item xs={2}>
          <TextField
            id="Start Date"
            label="Date To"
            variant="outlined"
            type="date"
            className="mr-2"
            required={true}
            InputLabelProps={{ shrink: true }}
            size="small"
          // value={toDate}
          // onChange={(e) =>
          //     / setToDate(e.target.value)
          // }
          />
        </Grid>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // FilterData();
          }}
          style={{ marginLeft: '10px', height: '40px', marginTop: '1em' }}
        >
          Search
        </Button>
      </Grid> */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Inward Instruments </th>
            <th>Assigned Jobs </th>
            <th>Completed jobs</th>
            <th>Approval </th>
            <th>Rejections</th>
            <th>Certificates </th>
            <th>Dispatch instruments</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>{filteredInwards.length}</td>
            <td>{jobAssigned}</td>
            <td>1</td>
            <td>{approve.length}</td>
            <td>{reject.length}</td>
            <td>{certificate.length}</td>
            <td>{dispatch.length}</td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
}
