import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextareaAutosize,
  Stack,
  Modal,
  Box,
  ButtonGroup,
  Tooltip
} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { showComponent } from "../helper/helpers"
import { ClassicTable } from "./../../utils/components/Styles";
import jsPDF from "jspdf";

const axios = require("axios");

const fixedColumns = [
  {
    field: "id",
    headerName: "Sr. No.",
    editable: false,
  },
  {
    field: "description",
    headerName: "Instruments",
    editable: true,
  },
  {
    field: "ducID",
    headerName: "DUCID",
    editable: true,
  },
  {
    field: "serialNumber",
    headerName: "Serial number",
    editable: true,
  },
  {
    field: "range",
    headerName: "DUC Range",
    editable: true,
  },
  {
    field: "lc",
    headerName: "Least Count",
    editable: true,
  },
  {
    field: "make",
    headerName: "DUC Make",
    editable: true,
  },
  {
    field: "model",
    headerName: "Model",
    editable: true,
  },
  {
    field: "conOfDuc",
    headerName: "Con. Of Duc",
    editable: true,
  },
  {
    field: "calibrationFrequency",
    headerName: "Calibration frequency",
    editable: true,
  },
  {
    field: "accuracy",
    headerName: "Accuracy",
    editable: true,
  },
  {
    field: "calibrationType",
    headerName: "Calibration Type",
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    editable: true,
  },
  {
    field: "locationOfInstrument",
    headerName: "Location Of Instrument",
    editable: true,
  },
  {
    field: "calPoint",
    headerName: "Calibration Point",
    editable: true,
  },
  {
    field: "calMethod",
    headerName: "Calibration Method",
    editable: true,
  },
  {
    field: "labCapabilities",
    headerName: "Lab Capabilities",
    editable: true,
  },
]

export default function CreateSRF(props) {
  const navigate = useNavigate();
  var [srfInstruments, setSrfInstruments] = React.useState([]);
  var [allInstruments, setInstruments] = React.useState([]);
  const [clientName, setClientName] = React.useState("");
  const [receiptDate, setReceiptDate] = React.useState("");
  const [poNumber, setPoNumber] = React.useState("");
  const [poDate, setpotDate] = React.useState("");
  const [committedDeliveryDate, setCommittedDeliveryDate] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [jobNumber, setJobNumber] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [dcDate, setDcDate] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([]);
  const [fullColumnsLoaded, setFullColumnsLoaded] = React.useState(false)
  const [multiValueData, setMultiValueData] = React.useState({})
  const [multiValueModalOpen, setMultiValueModalOpen] = React.useState(false)
  const [unitArray, setUnitArray] = React.useState([])
  const [copyQt, setCopyQt] = React.useState([])

  const [readingColumns, setReadingColumns] = React.useState([
    fixedColumns,
  ]);

  const multiValueModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    maxHeight: '350px',
    overflow: 'auto',
  }

  const renderMultivalueModal = () => {
    if (
      multiValueData.rowIndex != undefined &&
      multiValueData.cellIndex != undefined
    )
      return (
        <Modal
          open={multiValueModalOpen}
          onClose={() => {
            setMultiValueModalOpen(false)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          size="md"
        >
          <Box sx={multiValueModalStyle}>
            <h5>Update Multiple values</h5>
            <table className="srf-multivalue-table">
              <tr>
                {multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? <th>Min Value</th> : ''}
                {multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? <th>{' - '}</th> : ''}
                <th>
                  {multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? 'Max Value' : 'Value'}
                </th>
                <th>Unit</th>
                <th></th>
              </tr>
              {readingRows[multiValueData.rowIndex][multiValueData.cellIndex]
                .split('||')
                .map((e, idx) => (
                  <tr>
                    {multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? (
                      <td>
                        <TextField
                          value={e.split("|")[0].split("#")[0]}
                          onChange={(e) => {
                            let ctr0 = readingRows[multiValueData.rowIndex][multiValueData.cellIndex].split("||")
                            let ctr1 = ctr0[idx].split("|")
                            let ctr2 = ctr1[0].split("#")
                            ctr2[0] = e.target.value
                            ctr1[0] = e.target.value !== "" ? ctr2.join("#") : ""
                            ctr0[idx] = ctr1.join("|")
                            updateCellValue(
                              multiValueData.rowIndex,
                              multiValueData.cellIndex,
                              ctr0.join("||"),
                            )
                          }}
                        />
                      </td>
                    ) : (
                      ''
                    )}
                    {multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? <td>{' - '}</td> : ''}
                    <td>
                      <TextField
                        value={
                          multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19
                            ? e.split('|')[1] ? e.split('|')[1].split('#')[0] : ""
                            : e.split('#')[0]
                        }
                        onChange={(e) => {
                          let ctr0 = readingRows[multiValueData.rowIndex][multiValueData.cellIndex].split("||")
                          let ctr1 = ctr0[idx].split("|")
                          if (multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19) {
                            let ctr2 = ctr1[1] ? ctr1[1].split("#") : [""]
                            ctr2[0] = e.target.value
                            ctr1[1] = ctr2.join("#")
                            ctr0[idx] = ctr1.join("|")
                          } else {
                            let ctr2 = ctr1[0].split("#")
                            ctr2[0] = e.target.value
                            ctr1[0] = ctr2.join("#")
                            ctr0[idx] = ctr1
                          }
                          updateCellValue(
                            multiValueData.rowIndex,
                            multiValueData.cellIndex,
                            ctr0.join("||"),
                          )
                        }}
                        disabled={multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? e.split("|")[0].split("#")[0] === "" : false}
                      />
                    </td>
                    <td>
                      <select
                        styles={{ width: '100%' }}
                        onChange={(e) => {
                          let ctr0 = readingRows[multiValueData.rowIndex][
                            multiValueData.cellIndex
                          ].split('||')
                          let ctr1 = ctr0[idx].split('|')
                          let ctr2 = ""
                          if (ctr1[0].length > 0) {
                            ctr2 = ctr1[0].split("#")
                            ctr2 = [ctr2[0], e.target.value]
                            ctr1[0] = ctr2.join("#")
                          }
                          if (ctr1.length > 1) {
                            ctr2 = ctr1[1].split("#")
                            ctr2 = [ctr2[0], e.target.value]
                            ctr1[1] = ctr2.join("#")
                          }
                          ctr0[idx] = ctr1.join("|")
                          updateCellValue(
                            multiValueData.rowIndex,
                            multiValueData.cellIndex,
                            ctr0.join('||'),
                          )
                        }}
                        value={
                          e.split("|")[0].split("#")[1] ? e.split("|")[0].split("#")[1] : ""
                        }
                        disabled={multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? e.split("|")[0].split("#")[0] === "" || !e.split("|")[1] : e === ""}
                      >
                        {unitArray.map((option) => (
                          <option value={option.symbol}>{option.symbol}</option>
                        ))}
                      </select>
                    </td>
                    <td>
                      {readingRows[multiValueData.rowIndex][multiValueData.cellIndex].split('||').length > 1 ? (
                        showComponent("delete") &&
                        <DeleteIcon
                          style={{ color: '#dc3545' }}
                          onClick={(e) => {
                            let tmp = readingRows[multiValueData.rowIndex][multiValueData.cellIndex]
                              .split('||')
                              .filter((_, i) => i !== idx)
                            updateCellValue(
                              multiValueData.rowIndex,
                              multiValueData.cellIndex,
                              tmp.join('||'),
                            )
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={multiValueData.cellIndex === 4 || multiValueData.cellIndex === 19 ? 4 : 2}>
                  <Button
                    onClick={(e) => {
                      let ctr =
                        readingRows[multiValueData.rowIndex][
                        multiValueData.cellIndex
                        ]
                      ctr += '||'
                      updateCellValue(
                        multiValueData.rowIndex,
                        multiValueData.cellIndex,
                        ctr,
                      )
                    }}
                  >
                    <b style={{ fontSize: '18px' }}>ADD</b>
                    <AddIcon />
                  </Button>
                </td>
              </tr>
            </table>
          </Box>
        </Modal>
      )
  }

  const getUnitMasterArray = () => {
    let url = BASE_URL
    axios
      .get(url + 'unit?_where=(status,eq,1)')
      .then((res) => {
        setUnitArray([''].concat(res.data))
      })
      .catch((error) => {
        toast.error('Something Went Wrong!')
      })
  }

  const params = useParams();
  const getSrfsList = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
        `xjoin?_join=srf.srfs,_j,client.clients&_on1=(srf.clientId,eq,client.id)&_fields=srf.serviceReqNumber,srf.jobNumber,srf.poNumber,srf.poDate,srf.receiptDate,srf.committedDeliveryDate,srf.dcNumber,srf.dcDate,srf.entryDate,srf.description,client.address,client.companyName&_where=(srf.id,eq, ${params.id})`
      )
      .then((res) => {
        setServiceReqNumber(res.data[0].srf_serviceReqNumber);
        setJobNumber(res.data[0].srf_jobNumber);
        setPoNumber(res.data[0].srf_poNumber);
        setpotDate(res.data[0].srf_poDate);
        setReceiptDate(res.data[0].srf_receiptDate);
        setDcNumber(res.data[0].srf_dcNumber);
        setDcDate(res.data[0].srf_dcDate);
        setEntryDate(res.data[0].srf_entryDate);
        setDescription(res.data[0].srf_description);
        setCommittedDeliveryDate(res.data[0].srf_committedDeliveryDate);
        setAddress(res.data[0].client_address);
        setClientName(res.data[0].client_companyName);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const srfInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `srfInstruments?_where=(srfsId,eq,${params.id})`)
      .then((res) => {
        setSrfInstruments(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getExtraColumns = () => {
    // Terms and Condition
    let url = BASE_URL;
    axios
      .get(url + "settings?_where=(keyName,eq,srfInstrumentsAdditionalColumns)&_fields=value")
      .then((res) => {
        if (res.data.length > 0) {
          // let new 
          setReadingColumns([readingColumns[0].concat(res.data[0].value.split(",").map(col => {
            return {
              field: col,
              headerName: col,
              editable: true,
            }
          }))])
        }
        console.log("getExtraColumns is completed!")
        setFullColumnsLoaded(true)

      })
      .catch((error) => {
        console.log(error);
      });
  }

  const instrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `instruments?_where=(status,eq,1`)
      .then((res) => {
        setInstruments(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const initiateRanges = () => {
    let rows = [];
    for (let i = 0; i < srfInstruments.length; i++) {
      let extra = srfInstruments[i].extraColumns ? JSON.parse(srfInstruments[i].extraColumns) : {}
      rows.push([
        srfInstruments[i].id,
        srfInstruments[i].instrumentId,
        srfInstruments[i].DUCID,
        srfInstruments[i].serialNumber,
        srfInstruments[i].ranges,
        srfInstruments[i].lc,
        srfInstruments[i].make,
        srfInstruments[i].model,
        srfInstruments[i].ConOfDuc,
        srfInstruments[i].calFrequency,
        srfInstruments[i].accuracy,
        srfInstruments[i].calibrationType,
        srfInstruments[i].location,
        srfInstruments[i].locationOfInstrument,
        srfInstruments[i].calPoint,
        srfInstruments[i].calMethod,
        srfInstruments[i].labCapabilities,
      ].concat(
        srfInstruments[i].extraColumns
          ? Array(readingColumns[0].length - fixedColumns.length).fill("").map((e, j) => {
            return extra[readingColumns[0][fixedColumns.length + j].field] ? extra[readingColumns[0][fixedColumns.length + j].field] : e
          })
          : Array(readingColumns[0].length - fixedColumns.length).fill("")
      ));
    }

    for (let i = 0; i < rows.length; i++) {
      for (let j = 0; j < allInstruments.length; j++) {
        if (allInstruments[j].id === rows[i][1]) {
          rows[i][1] = rows[i][1] + ", " + allInstruments[j].instrumentName;
          // delete rows[i][0];
        }
      }
    }
    console.log(rows);
    setReadingRows(rows);
    setCopyQt(Array(rows.length).fill(1))
  };

  useEffect(() => {
    getExtraColumns()
    getUnitMasterArray()
  }, []);

  useEffect(() => {
    instrumentsList();
    srfInstrumentsList();
    getSrfsList();
  }, [fullColumnsLoaded])

  useEffect(() => {
    initiateRanges();
  }, [srfInstruments, allInstruments]);

  const submitDescriptionRows2 = (readingRows,extraColumns) => {
    let rows = [];
    for (let i = 0; i < readingRows.length; i++) {
      rows.push({
        // srfsId: parseInt(params.id),
        srfInstrumentId: parseInt(readingRows[i][1].split(",")[0].trim()),
        ranges: readingRows[i][4],
        lc: readingRows[i][5],
        serialNumber: readingRows[i][3],
        make: readingRows[i][6],
        model: readingRows[i][7],
        DUCID: readingRows[i][2],
        ConOfDuc: readingRows[i][8],
        // calFrequency: readingRows[i][9],
        accuracy: readingRows[i][10],
        calibrationType: readingRows[i][11],
        location: readingRows[i][12],
        locationOfInstrument: readingRows[i][13],
        calMethod: readingRows[i][14],
        // labCapabilities: readingRows[i][16],
        id: Number(readingRows[i][0]),
        extraColumns: JSON.stringify(extraColumns[i]),
        serviceReqNumber: serviceReqNumber
      });
      ;
    }

    console.log(rows);
    Promise.all([
      ...rows.map(row => axios.patch(BASE_URL + `datasheets/${row.id}`, row)),
      ...rows.map(row => axios.patch(BASE_URL + `certificates/${row.id}`, row))
    ])
      .then(res => {
        toast.success("SRF Updated!")
        props.setLoader(false);
        setTimeout(refresh, 500);
      })
      .catch(err => {
        console.log("error : ", err)
        props.setLoader(false);
      })
  };

  const handleSubmit = (event) => {
    let rows = [];
    let extraColumns = []
    for (let i = 0; i < readingRows.length; i++) {
      let extraCols = {}
      console.log("reading row : ", readingRows[i])
      if (readingColumns[0].length > fixedColumns.length) {
        for (let j = fixedColumns.length; j < readingColumns[0].length; j++) {
          extraCols[`${readingColumns[0][j].field}`] = readingRows[i][j];
        }
      }
      let cols = {
        srfsId: parseInt(params.id),
        instrumentId: parseInt(readingRows[i][1].split(",")[0].trim()),
        ranges: readingRows[i][4],
        lc: readingRows[i][5],
        serialNumber: readingRows[i][3],
        make: readingRows[i][6],
        model: readingRows[i][7],
        DUCID: readingRows[i][2],
        ConOfDuc: readingRows[i][8],
        calFrequency: readingRows[i][9],
        accuracy: readingRows[i][10],
        calibrationType: readingRows[i][11],
        location: readingRows[i][12],
        locationOfInstrument: readingRows[i][13],
        calPoint: readingRows[i][14],
        calMethod: readingRows[i][15],
        labCapabilities: readingRows[i][16],
        id: Number(readingRows[i][0]),
      };

      if (extraCols !== {}) {
        cols["extraColumns"] = JSON.stringify(extraCols)
      }

      extraColumns.push(extraCols)

      rows.push(cols)
    }

    let row = {
      receiptDate: moment(receiptDate).format("YYYY-MM-DD"),
      dcNumber: dcNumber,
      dcDate: moment(dcDate).format("YYYY-MM-DD"),
      entryDate: moment(entryDate).format("YYYY-MM-DD"),
      committedDeliveryDate: moment(committedDeliveryDate).format("YYYY-MM-DD"),
      serviceReqNumber: serviceReqNumber,
      jobNumber: jobNumber,
      description: description,
      address: address,
      poNumber: poNumber,
      poDate: moment(poDate).format("YYYY-MM-DD"),
    };


    Promise.all([
      ...rows.map(row1 => axios.patch(BASE_URL + `srfInstruments/${row1.id}`, row1)),
      axios.patch(BASE_URL + `srfs/${params.id}`, row)
    ])
      .then(res => {
        submitDescriptionRows2(readingRows, extraColumns);
      })
      .catch(err => {
        console.log("error : ", err)
        // props.setLoader(false)
      })
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push(Array(readingColumns[0].length).fill("")) //.map(c => "").filter((s, i) => i < ))
    // console.log("this is new reading row : ", readingRows, newReadingRows)
    setReadingRows([...newReadingRows]);
    setCopyQt([...copyQt, 1])
  };

  const deleteReadingRow = (tableIndex, rowIndex) => {
    let newReadingRows = [...readingRows];
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
    let tmp = [...copyQt]
    tmp.splice(rowIndex, 1)
    setCopyQt([...tmp])
  };
  var refresh = () => {
    window.location.reload(false);
    navigate("/srf");
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Update SRF
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Company Name *"
            size="small"
            fullWidth
            disabled
            value={clientName}
            variant="outlined"
            onChange={(e) => setClientName(e.target.value)}
          />
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Receipt Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={receiptDate ? new Date(receiptDate) : ''}
              onChange={(newValue) => setReceiptDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No. *"
            size="small"
            fullWidth
            value={dcNumber}
            variant="outlined"
            onChange={(e) => setDcNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="DC Dt./RGP Dt. *"
              value={dcDate ? new Date(dcDate) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setDcDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Entry Date *"
              value={entryDate ? new Date(entryDate) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setEntryDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Committed delivery date *"
              value={committedDeliveryDate ? new Date(committedDeliveryDate) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setCommittedDeliveryDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Service request number *"
            size="small"
            fullWidth
            value={serviceReqNumber}
            variant="outlined"
            onChange={(e) => setServiceReqNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Job number"
            size="small"
            fullWidth
            value={jobNumber}
            variant="outlined"
            onChange={(e) => setJobNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            fullWidth
            value={address}
            disabled
            variant="outlined"
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="PO Number *"
            size="small"
            fullWidth
            value={poNumber}
            variant="outlined"
            onChange={(e) => setPoNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="PO Date *"
              value={poDate ? new Date(poDate) : ''}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setpotDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <hr />
      <h4 style={{ "margin-bottom": "0px", "margin-top": "12px" }}>
        Description as below
      </h4>
      {/* <Button
        style={{ float: "left" }}
        onClick={(e) => {
          addRangeRow(0);
        }}
      >
        <AddIcon />
      </Button> */}
      {showComponent('add_in') && (
        <Button
          style={{ float: 'left' }}
          onClick={(e) => {
            addRangeRow(0)
          }}
        >
          <b style={{ fontSize: '18px' }}>ADD</b>
          <AddIcon />
        </Button>
      )}
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                    <TextField
                        style={{
                          width: "75px",
                          marginRight: "10px"
                        }}
                        type="number" 
                        // inputProps={{ min: 1, max: 75 }}
                        InputProps={{ inputProps: { min: 1, max: 75 } }}
                        size="small"
                        value={copyQt[index]}
                        onChange={(e) => {
                          let tmp = [...copyQt]
                          tmp[index] = Number(e.target.value)
                          setCopyQt(tmp)
                        }}
                      ></TextField>
                      <Tooltip title="Copy as New Row" placement="top-start">
                        <ContentCopyIcon
                          style={{ color: "#1976d2" }}
                          onClick={(e) => {
                            // let tmp = [...readingRows]
                            // tmp.splice(index + 1, 0, [...readingRows[index]]);
                            // setReadingRows(tmp)

                            let tmp = [...readingRows]
                            tmp.splice(index + 1, 0, ...Array(copyQt[index]).fill(0).map(e => [...readingRows[index]]) );
                            setReadingRows(tmp)
                            tmp = [...copyQt]
                            tmp.splice(index + 1, 0, ...Array(copyQt[index]).fill(0).map(e => 1))
                            tmp[index] = 1
                            setCopyQt(tmp)
                          }}
                        />
                      </Tooltip>
                      {
                        showComponent("delete") &&
                        <Tooltip title="Delete Row" placement="top-start">
                          <DeleteIcon
                            style={{ color: "#dc3545" }}
                            onClick={(e) => {
                              deleteReadingRow(0, index);
                            }}
                          />
                        </Tooltip>
                      }
                    </ButtonGroup>
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 1 &&
                      cellIndex != 4 &&
                      cellIndex != 5 &&
                      cellIndex != 10 &&
                      cellIndex != 9 &&
                      (cellIndex != 11) &
                      (cellIndex != 0) &
                      (cellIndex != 12) &&
                      cellIndex != 14 &&
                      cellIndex != 15 &&
                      cellIndex != 19
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            style={{width: "200px"}}
                            size="small"
                            value={cell}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1) {
                      return (
                        <TableCell>
                          <TextField
                            style={{width: "200px"}}
                            size="small"
                            value={cell}
                            disabled
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setMultiValueData({
                                rowIndex: index,
                                cellIndex: cellIndex,
                              })
                              setMultiValueModalOpen(!multiValueModalOpen)
                            }}
                            style={{
                              backgroundColor: readingRows[index][cellIndex] && readingRows[index][cellIndex].length > 0 ? "#d9fdd3" : ""
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 5) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setMultiValueData({
                                rowIndex: index,
                                cellIndex: cellIndex,
                              })
                              setMultiValueModalOpen(!multiValueModalOpen)
                            }}
                            style={{
                              backgroundColor: readingRows[index][cellIndex] && readingRows[index][cellIndex].length > 0 ? "#d9fdd3" : ""
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 10) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setMultiValueData({
                                rowIndex: index,
                                cellIndex: cellIndex,
                              })
                              setMultiValueModalOpen(!multiValueModalOpen)
                            }}
                            style={{
                              backgroundColor: readingRows[index][cellIndex] && readingRows[index][cellIndex].length > 0 ? "#d9fdd3" : ""
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 9) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "3 Months" },
                                { id: 2, label: "6 Months" },
                                { id: 3, label: "12 Months" },
                                { id: 6, label: "18 Months" },
                                { id: 5, label: "24 Months" },
                              ]}
                              value={cell}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 11) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              defaultValue={cell == 1 ? "NABL" : "NON NABL"}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "NABL",
                                },
                                {
                                  id: 2,
                                  label: "NON NABL",
                                },
                                {
                                  id: 3,
                                  label: "OFF RECORD",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "NABL") {
                                  updateCellValue(index, cellIndex, 0);
                                } else if (newInputValue === "NON NABL") {
                                  updateCellValue(index, cellIndex, 1);
                                } else {
                                  updateCellValue(index, cellIndex, 2);
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 12) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              defaultValue={cell == 1 ? "NABL" : "NON NABL"}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                {
                                  id: 1,
                                  label: "Onsite",
                                },
                                {
                                  id: 2,
                                  label: "In house",
                                },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Onsite") {
                                  updateCellValue(index, cellIndex, "Onsite");
                                } else {
                                  updateCellValue(index, cellIndex, "In house");
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 14) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "As per proc" },
                                { id: 2, label: "As per customer" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                cell == "As per proc"
                                  ? "As per proc"
                                  : "As per customer"
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "As per proc") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per proc"
                                  );
                                  // changeCertificateStatus(3 + ":" + result.toString());
                                } else if (
                                  newInputValue === "As per customer"
                                ) {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "As per customer"
                                  );
                                  // changeCertificateStatus(4 + ":" + result.toString());
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 15) {
                      return (
                        <TableCell>
                          <Grid item xs={3}>
                            <Autocomplete
                              style={{width: "200px"}}
                              size="small"
                              id="combo-box-demo"
                              options={[
                                { id: 1, label: "Direct" },
                                { id: 2, label: "Comparison" },
                                { id: 3, label: "Direct/Comparison" },
                              ]}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              value={
                                cell == "Direct"
                                  ? "Direct"
                                  : cell == "Comparison"
                                    ? "Comparison"
                                    : "Direct/Comparison"
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue === "Direct") {
                                  updateCellValue(index, cellIndex, "Direct");
                                } else if (newInputValue === "Comparison") {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Comparison"
                                  );
                                } else {
                                  updateCellValue(
                                    index,
                                    cellIndex,
                                    "Direct/Comparison"
                                  );
                                }
                              }}
                            />
                          </Grid>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 19) {
                      return (
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              setMultiValueData({
                                rowIndex: index,
                                cellIndex: cellIndex,
                              })
                              setMultiValueModalOpen(!multiValueModalOpen)
                            }}
                            style={{
                              backgroundColor: readingRows[index][cellIndex] && readingRows[index][cellIndex].length > 0 ? "#d9fdd3" : ""
                            }}
                          >
                            Add
                          </Button>
                        </TableCell>
                      )
                    }
                  })}
                  <TableCell>
                    {
                      showComponent("delete") &&
                      <DeleteIcon
                        style={{ color: "#dc3545" }}
                        onClick={(e) => {
                          deleteReadingRow(0, index);
                        }}
                      />
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
      </div>
      <Stack style={{ "margin-top": "20px" }} justifyContent="start">
        <Typography align="left">Description</Typography>
        <TextareaAutosize
          minRows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter your description here"
          style={{ width: 900 }}
        />
      </Stack>
      <div style={{ "margin-top": "20px" }}>
        <Typography align="left">
          Conformity of Statement Requirement:
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </Typography>
      </div>
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            handleSubmit();
          }}
        >
          Update
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      {renderMultivalueModal()}
      <ToastContainer />
    </Paper>
  );
}
