import convert from "./convert-units/lib/convert_units";


const unitMapping = {
    "Kg/cm²": "Kg_cm_sq",
    "°C": "C"
}
const getUnitForLibrary = (unit) => {
    if (unit in unitMapping )
      return unitMapping[unit]
    return unit
  }

export default function unitConvertor(value, fromUnit, toUnit) {
  const precision = getPrecision(value);
  
  let convertedValue = convert(value)
    .from(getUnitForLibrary(fromUnit))
    .to(getUnitForLibrary(toUnit));

  return convertedValue.toFixed(precision);
}

function getPrecision(value) {
  const valueString = value.toString();
  const decimalIndex = valueString.indexOf('.');
  
  if (decimalIndex === -1) {
    return 0;
  }
  
  return valueString.length - decimalIndex - 1;
}