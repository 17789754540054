import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import moment from "moment";
const axios = require("axios");

export default function CreateStandardInOut() {
  const [companyName, seCompanyName] = React.useState(null);
  const [address, setAddress] = React.useState("");
  const [purpose, setPurpose] = React.useState(null);
  const [personName, setPersonName] = React.useState(null);
  const [remark, setRemark] = React.useState(null);
  const [outwardDate, setOutwardDate] = React.useState(null);
  const [expectedReturnDate, setExpectedReturnDate] = React.useState(null);
  const [standards, setStandard] = React.useState("");
  var [clientArray, setClientArray] = React.useState([]);
  var [standardArray, setStandardArray] = React.useState([]);
  var [checkPoints, setCheckPoints] = React.useState("");
  var [standardArr, setStandardArr] = React.useState([]);

  const getClientList = () => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClientArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const customerList = [];
  const clients = [];
  for (let i = 0; i < clientArray.length; i++) {
    clients.push({
      label: clientArray[i].id + ", " + clientArray[i].companyName,
    });
    customerList.push({
      id: clientArray[i].id,
      label: clientArray[i].companyName,
      address: clientArray[i].address,
    });
  }

  const getStandardList = () => {
    let url = BASE_URL;
    axios
      .get(url + "standards?_where=(status,eq,1)")
      .then((res) => {
        setStandardArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getStandardMasterList = () => {
    const standardList = [];
    for (let i = 0; i < standardArray.length; i++) {
      if (standardArray[i].standardName != null)
        standardList.push({
          id: standardArray[i].id,
          label: standardArray[i].standardName,
          stId: standardArray[i].stId,
        });
    }
    setStandardArr(standardList);
  };
  useEffect(() => {
    getClientList();
    getStandardList();
  }, []);
  useEffect(() => {
    getStandardMasterList();
  }, [standardArray]);

  var refresh = () => {
    window.location.reload(false);
  };

  const handleSubmit = (event) => {
    let row = {
      clientId: companyName,
      // standardIds: standardIds,
      standards: standards,
      purpose: purpose,
      outwardDate: moment(outwardDate).format("YYYY-MM-DD"),
      expectedReturnDate: moment(expectedReturnDate).format("YYYY-MM-DD"),
      personName: personName,
      remark: remark,
      checkPoint: checkPoints,
    };

    console.log(row);

    // var finalArr = [];
    // if (row.standards != null) {
    //   var arr = row.standards.split(",");

    //   for (let i = 0; i < arr.length; i++) {
    //     finalArr.push({
    //       clientId: companyName,
    //       standardIds: parseInt(arr[i].split(":")[0]),
    //       standards: arr[i],
    //       purpose: purpose,
    //       outwardDate: moment(outwardDate).format("YYYY-MM-DD"),
    //       expectedReturnDate: moment(expectedReturnDate).format("YYYY-MM-DD"),
    //       personName: personName,
    //       remark: remark,
    //       checkPoint: checkPoints,
    //     });
    //   }
    // }
    let url = BASE_URL;
    axios
      .post(url + "standardInOut", row)
      .then((res) => {
        toast("standards In/Out created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const purposeList = [
    {
      label: "Onsite",
    },
    {
      label: "self-calibration",
    },
  ];

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create standard In/Out
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={clients}
            renderInput={(params) => (
              <TextField {...params} label="Company Name" />
            )}
            onChange={(event, value) => {
              if (value != null) {
                seCompanyName(parseInt(value.label.split(",")[0]));
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Address"
            size="small"
            value={address}
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            size="small"
            options={
              standardArr.length
                ? standardArr.map((item) => {
                    return {
                      id: item.id,
                      title: item.label,
                      stId: item.stId,
                    };
                  })
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.stId}:{option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Standards"
                placeholder="select multiple Standards"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    return item.id;
                  })
                  .join(",");
                setStandard(arrToStr);
              } else {
                setStandard("");
              }
            }}
          />

          {/* <Autocomplete
            size="small"
            id="combo-box-demo"
            options={standardArr}
            multiple
            renderInput={(params) => (
              <TextField {...params} label="Standards" />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    return item.id + ":" + item.label;
                  })
                  .join(",");
                  console.log(arrToStr)
                setStandard(arrToStr);
              } else {
                setStandard("");
              }
            }}
          /> */}
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={purposeList}
            renderInput={(params) => <TextField {...params} label="Purpose" />}
            onChange={(event, value) => {
              if (value != null) {
                setPurpose(value.label);
              } else {
                setPurpose("");
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Outward date "
            size="small"
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setOutwardDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Expected return date"
            type="date"
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setExpectedReturnDate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Person’s Name "
            value={personName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPersonName(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Remarks"
            value={remark}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setRemark(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Check Points"
            InputLabelProps={{ shrink: true }}
            fullWidth
            rows={3}
            multiline
            variant="outlined"
            maxRows={5}
            onChange={(e) => {
              setCheckPoints(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
