import React, { useState } from "react";

import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LinearProgress } from "@mui/material";
import { Form, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../image/logo.png";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

import "./Login.css";

//API handling components
import { BASE_URL } from "./../../global";
const axios = require("axios");

const data = { userName: "admin", password: "admin" };

function ClientLogin(props) {
    const navigate = useNavigate();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // const Authenticate = (_password, _id, _userName) => {
    //     if (_password == "client" && _userName == "client") {
    //         // setId(_id);
    //         // setName(_name);
    //         // setType(_type);

    //         localStorage.setItem("id", _id);
    //         localStorage.setItem("isLoggedIn", true);
    //         parseInt(localStorage.id);
    //         localStorage.setItem("userName", _userName);
    //         alert("Login Successful");

    //         props.isLoggedIn(true);
    //         navigate("/dashboard");
    //     }
    // };

    const handleSubmit = (e) => {
        //static login
        // e.preventDefault();
        // props.setIsLoggedIn(true);
        // localStorage.setItem("isLoggedIn", true);
        navigate("/clientDashboard");

        // e.preventDefault();
        // setIsLoading(true);

        // let url = BASE_URL;

        // axios
        //     .get(url + "users?_where=(userName,eq,admin)~and(password,eq,pass)")
        //     .then((res) => {
        //         if (res.data.length > 0) {
        //             Authenticate(
        //                 res.data[0].password,
        //                 res.data[0].id,
        //                 res.data[0].userName
        //             );
        //             window.location.reload(false);
        //         } else {
        //             toast("Incorrect Username and password");
        //             setIsLoading(false);
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
    };

    return (
        <>
            <div class="limiter">
                <div class="container-login100">
                    <div class="wrap-login100">
                        <div class="login100-pic js-tilt" data-tilt>
                            <img className="circle" src={logo} alt="IMG" />
                        </div>

                        <form
                            class="login100-form validate-form"
                            onSubmit={handleSubmit}
                        >
                            <span class="login100-form-title">Client Login</span>

                            <div
                                class="wrap-input100 validate-input"
                                data-validate="Valid email is required: ex@abc.xyz"
                            >
                                <input
                                    class="input100"
                                    type="text"
                                    name="email"
                                    placeholder="Username"
                                    onChange={(e) =>
                                        setUserName(e.target.value)
                                    }
                                />
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i
                                        class="fa fa-envelope"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                            </div>

                            <div
                                class="wrap-input100 validate-input"
                                data-validate="Password is required"
                            >
                                <input
                                    class="input100"
                                    type="password"
                                    name="pass"
                                    placeholder="Password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <span class="focus-input100"></span>
                                <span class="symbol-input100">
                                    <i
                                        class="fa fa-lock"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                            </div>

                            <div class="container-login100-form-btn">
                                <button class="login100-form-btn">Login</button>
                            </div>
                            <Link to="/">
                            <div class="container-login100-form-btn" >
                                <button class="login100-form-btn" style={{backgroundColor:'cornflowerblue'}}>Login</button>
                            </div>
                            </Link>
                            {/* <div>
                            <a class="txt2" href="#">
                                    Client Login
                                </a>
                            </div> */}

                            <div class="text-center p-t-12">
                                <span class="txt1">Forgot</span>
                                <a class="txt2" href="#">
                                    Username / Password?
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClientLogin;
