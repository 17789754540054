import React from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";

import { Grid, Paper, Typography, Toolbar, Button } from "@mui/material";
const axios = require("axios");

export default function CreateScope() {
  const [srfObject, createSrfObject] = React.useState({});
  const [executing, setExecuting] = React.useState(false);
  const [image, setImage] = React.useState("");

  const handleChange = async (e) => {
    setImage(e.target.files[0]);
  };

  const handleApi = () => {
    //call the api
    const url = "https://login.globalcalibration.com/fileUpload.php";

    const data = new FormData();
    data.append("fileToUpload", image);
    axios.post(url, data).then((res) => {
      if (res.status == 200) {
        toast("File Uploaded successfully !");
        setTimeout(refresh, 500);
      }
    });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = (event) => {
    setExecuting(true);

    let row = {
      subject: srfObject.subject,
      message: srfObject.message,
    };
    let url = BASE_URL;
    axios
      .post(url + "feedback", row)
      .then((res) => {
        toast("Feedback created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Nable Scope
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          certificate upload
          <input type="file" onChange={handleChange} /> <br />
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              disabled={executing}
              onClick={handleApi}
            >
              SUBMIT
            </Button>
          </Toolbar>
        </Grid>
        <Grid item xs={3}>
          scope upload
          <input type="file" onChange={handleChange} /> <br />
          <Toolbar style={{ padding: "0px", overflow: "auto" }}>
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              disabled={executing}
              onClick={handleApi}
            >
              SUBMIT
            </Button>
          </Toolbar>
        </Grid>
      </Grid>
      <br />

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          disabled={executing}
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            onSubmit();
          }}
        >
          Save Scope
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
