import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";

import { Grid, TextField, Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../global";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "ulrNo", label: "ULR No.", minWidth: 40 },
  { id: "certificateNumber", label: "Certificate Number", minWidth: 40 },
  {
    id: "InstrumentId",
    label: "Instrument Name",
    minWidth: 40,
    align: "left",
  },
  { id: "range", label: "range", minWidth: 40 },
  { id: "lc", label: "lc", minWidth: 40 },
  { id: "locationOfInstrument", label: "Location Of Instrument", minWidth: 40 },
  {
    id: "calibrationDate",
    label: "Calibration date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due date",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  ulrNo,
  certificateNumber,
  InstrumentId,
  range,
  lc,
  locationOfInstrument,
  calibrationDate,
  dueDate
) {
  return {
    id,
    ulrNo,
    certificateNumber,
    InstrumentId,
    range,
    lc,
    locationOfInstrument,
    calibrationDate,
    dueDate,
  };
}

export default function SummaryReport() {
  const [page, setPage] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [fromDate, setFromDate] = React.useState("");
  const [data, setData] = React.useState([]);
  const [company, setCompany] = React.useState(null);
  const [toDate, setToDate] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dateData, setDateData] = React.useState([]);
  const [calData, setCalData] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const [certificatesData, setCertificatesData] = React.useState([]);
  const [finalArray, setFinalArray] = React.useState([]);
  const [instId, setInstId] = React.useState(0);
  const [ducId, setDucId] = React.useState("");

  const FilterData = () => {
    var resultProductData = data.filter(
      (datas) =>
        new Date(datas.cert_calibrationDate) >= new Date(fromDate) &&
        new Date(datas.cert_calibrationDate) <= new Date(toDate) &&
        datas.client_id == company &&
        datas.cert_DUCID == ducId &&
        datas.cert_srfInstrumentId == instId
    );
    setDateData(resultProductData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstruments = (id) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=cert.certificates,_j,inst.instruments&_on1=(cert.srfInstrumentId,eq,inst.id)&_fields=cert.DUCID,inst.instrumentName,cert.calibrationDate,cert.id,inst.id,cert.locationOfInstrument&_where=(cert.clientId,eq,${id})`
      )
      .then((res) => {
        setCertificatesData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var pushArray = [];

  for (let i = 0; i < customer.length; i++) {
    pushArray.push({
      id: customer[i].id,
      label: customer[i].companyName,
    });
  }

  const initializeCertificateDataTable = (id) => {
    var pushArray = [];

    for (let i = 0; i < certificatesData.length; i++) {
      pushArray.push({
        instrumentId: certificatesData[i].inst_id,
        ducId: certificatesData[i].cert_DUCID,
        label:
          certificatesData[i].inst_instrumentName +
          "," +
          certificatesData[i].cert_DUCID,
      });
    }
    setFinalArray(pushArray);
  };

  const getData = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=cert.certificates,_j,client.clients,_j,inst.instruments&_on1=(cert.clientId,eq,client.id)&_on2=(cert.instrumentId,eq,inst.id)&_fields=client.companyName,client.contact,client.address,inst.instrumentName,cert.calibrationDate,cert.nextDueDate,client.id,cert.id,cert.DUCID,cert.srfInstrumentId,cert.certificateNumber,cert.ranges,cert.lc`
      )

      .then((res) => {
        setData(res.data);
        initializeDataTable();
        // FilterData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };

  const initializeRowTable = () => {
    var rowData = [];
    var idAr = [];
    console.log(dateData);
    for (let i = 0; i < dateData.length; i++) {
      idAr.push(dateData[i].cert_id);
      rowData.push(
        createData(
          dateData[i].cert_id,
          "ULR12123",
          dateData[i].cert_certificateNumber,
          dateData[i].inst_instrumentName,
          dateData[i].cert_ranges,
          dateData[i].cert_lc,
          dateData[i].cert_locationOfInstrument,
          dateData[i].cert_calibrationDate
            ? moment(dateData[i].cert_calibrationDate).format("DD-MM-YYYY")
            : "",
          dateData[i].cert_nextDueDate
            ? moment(dateData[i].cert_nextDueDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }
    setCalData(rowData);
    setIds(idAr);
  };
  useEffect(() => {
    getCustomer();
    getData();
  }, []);
  useEffect(() => {
    initializeRowTable();
  }, [dateData]);
  useEffect(() => {
    initializeCertificateDataTable();
  }, [certificatesData]);

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={pushArray}
              renderInput={(params) => (
                <TextField {...params} label="Company Name" />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  setCompany(value.id);
                  getInstruments(value.id);
                } else {
                  setCompany("");
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={finalArray}
              renderInput={(params) => (
                <TextField {...params} label="Instruments" />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  setInstId(value.instrumentId);
                  setDucId(value.ducId);
                } else {
                  setCompany("");
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="Start Date"
              label="Date From"
              variant="outlined"
              type="date"
              className="mr-2"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              id="Start Date"
              label="Date To"
              variant="outlined"
              type="date"
              className="mr-2"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              FilterData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Search
          </Button>
          {ids.length ? (
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              component={Link}
              to={`/viewHistoryCard/${ids.toString()}`}
              style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
            >
              Print Summary
            </Button>
          ) : (
            ""
          )}
        </Grid>

        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {calData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={calData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
