import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Button,
  Toolbar,
} from "@mui/material";
const axios = require("axios");
export default function CreateStandard(props) {
  const [disciplineName, setDisciplineName] = React.useState("");
  var [environmentalConditions, setEnvironmentalConditions] =
    React.useState("");
  const [key, setKey] = React.useState(false);

  const params = useParams();
  const getStandardIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + `discipline/${params.id}`)
      .then((res) => {
        setDisciplineName(res.data[0].name);
        setKey(res.data[0].disciplineKey);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getStandardIdList();
  }, []);

  const onSubmit = (id) => {
    var jsonData = {
      name: disciplineName,
      disciplineKey: key,
      environmentalConditions: environmentalConditions,
    };
    let url = BASE_URL;

    var refresh = () => {
      window.location.reload(false);
      <Link to="/master/master/standard">Home</Link>;
    };
    axios
      .patch(url + `discipline/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("updated discipline!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Update
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Discipline Name *"
            size="small"
            value={disciplineName}
            fullWidth
            variant="outlined"
            onChange={(e) => setDisciplineName(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={key == "0" ? "Electro-technical" : "Others"}
            options={[
              { id: 1, label: "Electro-technical" },
              { id: 2, label: "Others" },
              { id: 3, label: "mass & volume" },
            ]}
            renderInput={(params) => <TextField {...params} label="Key name" />}
            onInputChange={(event, newInputValue) => {
              if (newInputValue === "Electro-technical") {
                setKey("0");
              } else if (newInputValue === "mass & volume") {
                setKey("2");
              } else {
                setKey("1");
              }
            }}
          />
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Edit Discipline
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
