import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";

const axios = require("axios");
const materialtypeOp = [
  "steel",
  "carbite"
]

const mode = [
  "Source",
  "Measure"
]

const parameters = {
  "Source": [
    "AC Voltage @ 50 Hz",
    "DC Voltage",
    "AC Current @ 50 Hz",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @ 1 kHz",
    "Capacitance @ 1 kHz",
    "Capacitance @ 100 Hz",
    "Inductance @ 1 kHz",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @ 50 Hz",
    "DC Power",
    "Conductivity meter",
    "pH Meter"
  ],
  "Measure": [
    "AC Voltage @ 50 Hz",
    "DC Voltage",
    "AC Current @ 50 Hz",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @ 1 kHz",
    "Capacitance @ 1 kHz",
    "Capacitance @ 100 Hz",
    "Inductance @ 1 kHz",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)"
  ]
}

const paratype = {
  "Source": {
    "Oscilloscope": [
      "Amplitude",
      "Time Base",
      "Frequency"
    ],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)"
    ]
  },
  "Measure": {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)"
    ]
  }
}


export default function EditInstrument() {
  const [materialtype, setMaterialtype] = React.useState("steel")
  const [discipline, setDiscipline] = React.useState([]);
  const [instrumentName, setInstrumentName] = React.useState("");
  const [standardMasterIds, setStandardMasterIds] = React.useState([]);
  const [uncertaintyFactors, setUncertaintyFactors] = React.useState([]);

  const [referenceStandards, setreferenceStandards] = React.useState([]);
  const [supportiveInstrumentMasterData, setSupportiveInstrumentMasterData] =
    React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [uncertaintyFactorsIds, setUncertaintyFactorsIds] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([]);
  const [standardMultiValues, setStandardMultiValues] = React.useState([]);
  const [standardMultiValueIds, setStandardMultiValueIds] =
    React.useState(null);
  const [SuppMultiValueIds, setSuppMultiValueIds] =
    React.useState(null);
  const [standards, setStandards] = React.useState([]);
  const [cmcReadings, setCmcReadings] = React.useState([]);
  const [uncertaintyFactorsdata, setUncertaintyFactorsdata] = React.useState(
    []
  );
  const [unitArray, setUnitArray] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [readingRangeValue, setReadingRangeValue] = React.useState([]);

  const [gaugeData, setGaugeData] = React.useState([
    { label: "1, Plain plug Gauge" },
    { label: "2, Width gauge" },
    { label: "3, Paddle gauge" },
    { label: "4, Flush pin" },
    { label: "5, Snap gauge" },
  ]);
  const [supportiveInstrumentMasterDatas, setSupportiveInstrumentMasterDatas] =
    React.useState([]);
  const [stIds, setStIds] = React.useState("");
  const [parameterName, setParameterName] = React.useState("");
  const [disciplineId, setDisciplineId] = React.useState("");
  const [disciplineName, setDisciplineName] = React.useState("")
  const [currrValue, setCurrrValue] = React.useState(0);
  const [updateEnableDisable, setUpdateEnableDisable] = React.useState(1);
  const [calibrationProcedureNo, setCalibrationProcedureNo] =
    React.useState("");
  const [
    supportiveInstrumentMasterDataIds,
    setSupportiveInstrumentMasterDataIds,
  ] = React.useState("");
  const [uncertMultiValueIds, setUncertMultiValueIds] = React.useState(null);
  const [supportiveMultiValues, setSupportiveMultiValues] = React.useState([]);
  const [supportive, setSupportive] = React.useState([]);
  const params = useParams();
  const userType = localStorage.getItem("type");
  const [et, setEt] = React.useState(true)
  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "mode",
        headerName: "Mode",
        editable: true,
        toggle: 1
      },
      {
        field: "parameter",
        headerName: "Parameter",
        editable: true,
        toggle: 1
      },
      {
        field: "paratype",
        headerName: "Parameter Type",
        editable: true,
        toggle: 1
      },
      {
        field: "fromRange",
        headerName: "From range",
        editable: true,
      },
      {
        field: "toRange",
        headerName: "To range",
        editable: true,
      },
      {
        field: "CMCPLUS",
        headerName: "Lower CMC",
        editable: true,
      },
      {
        field: "CMSMINUS",
        headerName: "Higher CMC",
        editable: true,
      },
      {
        field: "Action",
      },
    ],
  ];

  const gaugeSet = () => {
    gaugeData.forEach((element) => {
      if (parseInt(element.label.split(",")[0]) == updateEnableDisable) {
        setCurrrValue(element.label);
      }
    });
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const fetchStandardsData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
        "xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.disciplineId,pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId,pr.id&_where=(pl.status,eq,1)"
      )
      .then((res) => {
        let st = res.data.filter(e => e.pl_disciplineId == disciplineId)
        setStandards([...st]);
        var fArr = [];
        const spArr = stIds.split(",");
        if (res.data.length && spArr && spArr.length) {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < spArr.length; j++) {
              if (
                parseInt(spArr[j].split(":")[0]) == res.data[i].pl_id &&
                parseInt(spArr[j].split(":")[1]) == res.data[i].pr_id
              ) {
                fArr.push({
                  id: res.data[i].pl_id,
                  title: res.data[i].pl_standardName,
                  range: res.data[i].pr_rangeName,
                  standardRangesId: res.data[i].pr_id,
                  stId: res.data[i].pl_stId,
                });
                break;
              }
            }
          }
        }
        setStandardMultiValues(fArr);
      })

    axios
      .get(
        url +
        "xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId,pr.id&_where=(pl.status,eq,1)~and(pl.type,eq,2)"
      )
      .then((res) => {
        var fArrr = [];
        const spArr = supportiveInstrumentMasterDataIds.split(",");
        if (res.data.length && spArr && spArr.length) {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < spArr.length; j++) {
              if (
                parseInt(spArr[j].split(":")[0]) == res.data[i].pl_id &&
                parseInt(spArr[j].split(":")[1]) == res.data[i].pr_id
              ) {
                fArrr.push({
                  id: res.data[i].pl_id,
                  title: res.data[i].pl_standardName,
                  range: res.data[i].pr_rangeName,
                  standardRangesId: res.data[i].pr_id,
                });
                break;
              }
            }
          }
        }
        setSupportiveMultiValues(fArrr);
        setSupportive(res.data);
      })
      .catch((err) => {
        console.log("courses data error: ", err);
      });
  };

  const fetchUncertinityData = () => {
    let url = BASE_URL;
    axios
      .get(url + "uncertainty?_where=(status,eq,1)")
      .then((res) => {
        let uncertaintyFactorsIdsArr = uncertaintyFactorsIds.split(",")
        if (res.data.length && uncertaintyFactorsIdsArr) {
          var uncertIdArr = [];
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < uncertaintyFactorsIdsArr.length; j++) {
              if (parseInt(uncertaintyFactorsIdsArr[j]) == res.data[i].id) {
                uncertIdArr.push({
                  title: res.data[i].name,
                  id: res.data[i].id,
                });
                break;
              }
            }
          }
        }
        setUncertaintyFactors(uncertIdArr);
        setUncertaintyFactorsdata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const supportiveInstrumentsData = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
        "xjoin?_join=sp.supportiveInstruments,_j,sr.supportiveInstrumentsReadings&_on1=(sp.id,eq,sr.supportiveInstrumentId)&_fields=sp.name,sr.rangeName,sp.id,sr.id,sr.supportiveInstrumentId"
      )
      .then((res) => {
        var fArr = [];
        const spArr = supportiveInstrumentMasterDataIds.split(",");
        if (res.data.length && spArr && spArr.length) {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < spArr.length; j++) {
              if (
                parseInt(spArr[j]) === res.data[i].sr_supportiveInstrumentId
              ) {
                fArr.push({
                  id: res.data[i].sp_id,
                  title: res.data[i].sp_name,
                  range: res.data[i].sr_rangeName,
                  supportiveInstrumentsReadingsId: res.data[i].sr_id,
                });
                break;
              }
            }
          }
        }
        setSupportiveInstrumentMasterDatas(fArr);
        setSupportiveInstrumentMasterData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let disciplineKeys = false;
        discipline.forEach((el) => {
          if (el.id == disciplineId) {
            if (el.disciplineKey == "0") {
              disciplineKeys = true
            }
          }
        })
        if (disciplineKeys) {
          setEt(false)
        } else {
          setEt(true)
        }
  }, [discipline, disciplineId])

  const fetchDeciplineData = () => {
    let url = BASE_URL;
    axios
      .get(url + "discipline")
      .then((res) => {
        setDiscipline(res.data);
      })
      .catch((err) => {
        console.log("disciplines data error: ", err);
      });
  };

  const fetchInstrumentData = () => {
    let url = BASE_URL;
    axios
      .get(url + `xjoin?_join=inst.instruments,_j,ds.discipline&_on1=(inst.disciplineId,eq,ds.id)&_fields=ds.name,inst.materialtype,inst.disciplineId,inst.id,inst.standardMasterIds,inst.uncertaintyFactors,inst.supportiveInstrumentMasterData,inst.instrumentName,inst.standardMasterIds,inst.calibrationProcedureNo,inst.referenceStandards,inst.isGauge&_where=(inst.id,eq,${params.id})`)


      .then((res) => {
        if (res.data[0].inst_standardMasterIds) {
          setStIds(res.data[0].inst_standardMasterIds);
        }
        if (res.data[0].inst_uncertaintyFactors) {
          setUncertaintyFactorsIds(res.data[0].inst_uncertaintyFactors);
        }
        if (res.data[0].inst_supportiveInstrumentMasterData) {
          setSupportiveInstrumentMasterDataIds(
            res.data[0].inst_supportiveInstrumentMasterData
          );
        }
        setMaterialtype(res.data[0]?.inst_materialtype)
        setInstrumentName(res.data[0].inst_instrumentName);
        setParameterName(res.data[0].inst_parameterName);
        setDisciplineId(res.data[0].inst_disciplineId);
        setDisciplineName(res.data[0].ds_name);
        setStandardMasterIds(res.data[0].inst_standardMasterIds);
        setCalibrationProcedureNo(res.data[0].inst_calibrationProcedureNo);
        setreferenceStandards(res.data[0].inst_referenceStandards);
        setUpdateEnableDisable(res.data[0].inst_isGauge);
      })
      .catch((err) => {
        console.log("courses data error: ", err);
      });
  };

  const unitArrRange = () => {
    const unitArr = [""];
    if (disciplineId != "") {
      for (let i = 0; i < unitArray.length; i++) {
        if (parseInt(disciplineId) == unitArray[i].disciplineId) {
          unitArr.push(unitArray[i]);
        }
      }
      setUnits(unitArr);
    } else {
      setUnits([""]);
    }
  };

  const clearPrevRanges = (ids) => {
    ids = [...ids, ...deletedIds];
    if (ids.length < 1) return;
    axios
      .delete(BASE_URL + `cmc/bulk?_ids=${ids.join(",")}`)
      .then((res) => { })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getCmcReadings = () => {
    axios
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${params.id})`)
      .then((res) => {
        setCmcReadings(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };

  const handleSubmit = async (e) => {
    let url = BASE_URL;
    var jsonData = {
      disciplineId: typeof disciplineId !== "number" ? (disciplineId != '' ? disciplineId?.split(',')[0] : null) : disciplineId,
      instrumentName: instrumentName,
      standardMasterIds:
        standardMultiValueIds == null ? stIds : standardMultiValueIds,
      calibrationProcedureNo: calibrationProcedureNo,
      referenceStandards: referenceStandards,
      uncertaintyFactors:
        uncertMultiValueIds == null
          ? uncertaintyFactorsIds.length
            ? uncertaintyFactorsIds.toString()
            : null
          : uncertMultiValueIds,

      supportiveInstrumentMasterData:
        SuppMultiValueIds == null
          ? supportiveInstrumentMasterDataIds
          : SuppMultiValueIds,
      isGauge: parseInt(updateEnableDisable),
      materialtype: materialtype === "select material type" ? "" : materialtype
    };
    console.log('json Data', jsonData)
    axios
      .patch(url + `instruments/${params.id}`, jsonData)
      .then(async (res) => {
        if (readingRows.length) {
          let rangeIds = [];
          let rows = [];
          for (let i = 0; i < readingRows.length; i++) {
            rows.push({
              instrumentId: parseInt(params.id),
              mode: readingRows[i][1],
              parameter: readingRows[i][2],
              paratype: readingRows[i][3],
              fromRange:
                readingRangeValue[i][4] != null && readingRangeValue[i][4] != ""
                  ? readingRows[i][4].split("#")[0] +
                  "#" +
                  readingRangeValue[i][4]
                  : readingRows[i][4],
              toRange:
                readingRangeValue[i][5] != null && readingRangeValue[i][5] != ""
                  ? readingRows[i][5].split("#")[0] +
                  "#" +
                  readingRangeValue[i][5]
                  : readingRows[i][5],
              lowerCmc:
                readingRangeValue[i][6] != null && readingRangeValue[i][6] != ""
                  ? readingRows[i][6].split("#")[0] +
                  "#" +
                  readingRangeValue[i][6]
                  : readingRows[i][6],
              higherCmc:
                readingRangeValue[i][7] != null && readingRangeValue[i][7] != ""
                  ? readingRows[i][7].split("#")[0] +
                  "#" +
                  readingRangeValue[i][7]
                  : readingRows[i][7],
            });
            Number(readingRows[i][0]) && rangeIds.push(readingRows[i][0]);
          }

          await clearPrevRanges(rangeIds);
          console.log('rows', rows)
          if (rows.length > 0) {
            axios
              .post(url + "cmc/bulk", rows)
              .then((res) => {
                console.log("saved");
              })
              .catch((error) => {
                toast.error("Something Went Wrong!");
              });
          }
        }

        toast("Instrument updated!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...readingRows];
    newReadingRows.push([-1, "", "", "", "", "", "", ""]);
    setReadingRows([...newReadingRows]);

    let newReadingRowss = [...readingRangeValue];
    newReadingRowss.push([-1, null, null, null, null, null, null, null]);
    setReadingRangeValue([...newReadingRowss]);
  };

  const deleteReadingRow = (rowIndex, id) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined && id) {
      let url = BASE_URL
      axios
        .delete(url + `cmc/${id}`)
        .then((res) => {
          // toast("standards Deleted Successfully !");
          // setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!")
        })
    }
    if (newReadingRows[rowIndex] != undefined) {
      setDeletedIds([...deletedIds, newReadingRows[rowIndex][0]]);
      newReadingRows.splice(rowIndex, 1);
      setReadingRows([...newReadingRows]);
    }
  };

  const deleteStandardMasterIdArrayValue = (rowIndex) => {
    let newReadingRows = this.state.standardMasterIdArray.split('|')
    newReadingRows.splice(rowIndex, 1);
    newReadingRows = newReadingRows?.join('|')
    if(newReadingRows == '')
      this.setState({
        standardMasterIdArray: null
      });
    this.setState({
      standardMasterIdArray: newReadingRows
    });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  const initiateChData = () => {
    try {
      let rows = [];
      let rowsSpace = [];
      for (let i = 0; i < cmcReadings.length; i++) {
        rowsSpace.push([
          "",
          "",
          "",
          "",
          (cmcReadings[i].fromRange || "#").split("#")[1],
          (cmcReadings[i].toRange || "#").split("#")[1],
          (cmcReadings[i].lowerCmc || "#").split("#")[1],
          (cmcReadings[i].higherCmc || "#").split("#")[1],
        ]);
        rows.push([
          cmcReadings[i].id,
          cmcReadings[i].mode,
          cmcReadings[i].parameter,
          cmcReadings[i].paratype,
          cmcReadings[i].fromRange,
          cmcReadings[i].toRange,
          cmcReadings[i].lowerCmc,
          cmcReadings[i].higherCmc,
        ]);
      }
      setReadingRows(rows);
      setReadingRangeValue(rowsSpace);
    } catch (error) {
      console.log(error);
    }
  };

  var emtryarray = [];
  for (var i = 0; i < discipline.length; i++) {
    emtryarray.push({
      label: discipline[i].id + ", " + discipline[i].name,
    });
  }

  useEffect(() => {
    fetchDeciplineData();
    gaugeSet();
    fetchInstrumentData();
    getCmcReadings();
    getUnitMasterArray();
  }, []);

  useEffect(() => {
    initiateChData();
  }, [cmcReadings]);

  useEffect(() => {
    if (disciplineId)
      fetchStandardsData();
  }, [stIds, supportiveInstrumentMasterDataIds, disciplineId]);

  useEffect(() => {
    fetchUncertinityData();
  }, [uncertaintyFactorsIds]);

  useEffect(() => {
    supportiveInstrumentsData();
  }, [supportiveInstrumentMasterDataIds]);

  useEffect(() => {
    unitArrRange();
  }, [unitArray]);


  // useEffect(() => {
  //   if (standards && disciplineId) {
  //     let st = standards.filter(e => e.disciplineId === disciplineId)
  //     setStandards([...st])
  //   }
  // }, [standards, ])

  function displayTable(value, standards, uncertaintyMasterData) {
    const rows = value.split('|');
    
    const headerCellStyle = {
      backgroundColor: '#f2f2f2',
      padding: '8px',
      fontWeight: 'bold',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    };

    const cellStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
    };
    return (
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th style={headerCellStyle}>Standard Master ID</th>
            <th style={headerCellStyle}>Uncertainty Factors</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => {
            const rowData = row.split(':');
            standards?.forEach(standard=>{
              if(standard.id == rowData[0]){
                rowData[0] = standard.standardName
              }
            })
            let master_ids = rowData[1]?.split(',')
            let master_names = []
            master_ids?.forEach((master_id, index)=>{
              uncertaintyMasterData?.forEach(umaster=>{
                if(umaster.id == master_id){
                  master_names.push(umaster.name)
                }
              })
            } )
            rowData[1] = master_names.join(',')              
            return (
              <tr key={rowIndex}>
                {rowData.map((column, columnIndex) => {
                  const cellData = column.split(',');
                  return (
                    <td key={columnIndex} style={cellStyle}>
                      {cellData.join(', ')}
                    </td>
                  );
                })}
                <td>
                <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteStandardMasterIdArrayValue(rowIndex);
                      }}
                    />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }


  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit
      </Typography>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={`${disciplineId}, ${disciplineName}`}
            options={emtryarray}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onChange={(event, value) => {
              let disciplineKeys = false;
              discipline.forEach((el) => {
                if (el.id == value.label.split(",")[0].trim()) {
                  if (el.disciplineKey == "0") {
                    disciplineKeys = true
                  }
                }
              })
              if (disciplineKeys) {
                setEt(false)
              } else {
                setEt(true)
              }
              if (value != null) {
                setDisciplineName(value.label.split(",")[1])
                setDisciplineId(value.label.split(",")[0]);
              } else {
                setDisciplineName(null)
                setDisciplineId(null);
              }
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Instrument name *"
            InputLabelProps={{ shrink: true }}
            size="small"
            fullWidth
            variant="outlined"
            value={instrumentName}
            onChange={(e) => {
              setInstrumentName(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Calibration Procedure No."
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            value={calibrationProcedureNo}
            onChange={(e) => {
              setCalibrationProcedureNo(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Is Standards *"
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={referenceStandards}
            onChange={(e) => {
              setreferenceStandards(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            value={
              standardMultiValues?.length
                ? standardMultiValues.map((item) => {
                  return {
                    title: item.title,
                    id: item.id,
                    standardId: item.standardRangesId,
                    range: item.range,
                    stId: item.stId,
                  };
                })
                : []
            }
            size="small"
            options={
              standards != null
                ? standards.map((item) => {
                  if (item.pr_id) {
                    return {
                      id: item.pl_id,
                      stId: item.pl_stId,
                      standardId: item.pr_id,
                      title: item.pl_standardName,
                      range: item.pr_rangeName,
                    };
                  }
                })
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) =>
              option.stId + ":" + option.title
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.stId} <b>:</b> {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Standard Master *"
                placeholder="select multiple standards"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    if (item.standardId) {
                      return item.id + ":" + item.standardId;
                    }
                  })
                  .join(",");
                var values = value.map((value) => ({
                  title: value.title,
                  stId: value.stId,
                  id: value.id,
                  standardRangesId: value.standardId,
                  range: value.range,
                }));
                console.log(values);
                setStandardMultiValues([...values]);
                setStandardMultiValueIds(arrToStr);
              } else {
                setStandardMultiValues(null);
                setStandardMultiValueIds(false);
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            value={
              uncertaintyFactors?.length
                ? uncertaintyFactors.map((item) => {
                  return {
                    title: item.title,
                    id: item.id,
                  };
                })
                : []
            }
            size="small"
            options={
              uncertaintyFactorsdata.length
                ? uncertaintyFactorsdata.map((item) => {
                  return {
                    id: item.id,
                    title: item.name,
                  };
                })
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Uncertainity Factors"
                placeholder="select multiple standards"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    return item.id;
                  })
                  .join(",");
                var values = value.map((value) => ({
                  title: value.title,
                  id: value.id,
                }));
                setUncertaintyFactors([...values]);
                setUncertMultiValueIds(arrToStr);
              } else {
                setUncertMultiValueIds(false);
                setUncertaintyFactors([]);
              }
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            multiple
            fullWidth
            id="checkboxes-tags-demo"
            value={
              supportiveMultiValues?.length
                ? supportiveMultiValues.map((item) => {
                  return {
                    title: item.title,
                    id: item.id,
                    standardId: item.standardRangesId,
                    range: item.range,
                  };
                })
                : []
            }
            size="small"
            options={
              supportive != null
                ? supportive.map((item) => {
                  if (item.pr_id) {
                    return {
                      id: item.pl_id,
                      standardId: item.pr_id,
                      title: item.pl_standardName,
                      range: item.pr_rangeName,
                    };
                  }
                })
                : []
            }
            disableCloseOnSelect
            getOptionLabel={(option) =>
              option.title
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.title}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Supportive Instrument *"
                placeholder="select multiple supportive Instrument"
              />
            )}
            onChange={(event, value) => {
              if (value.length) {
                var arrToStr = Array.prototype.map
                  .call(value, function (item) {
                    if (item.standardId) {
                      return item.id + ":" + item.standardId;
                    }
                  })
                  .join(",");
                var values = value.map((value) => ({
                  title: value.title,
                  id: value.id,
                  standardRangesId: value.standardId,
                  range: value.range,
                }));
                console.log(values);
                setSupportiveMultiValues([...values]);
                setSuppMultiValueIds(arrToStr);
              } else {
                setSupportiveMultiValues(null);
                setSuppMultiValueIds(false);
              }
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={0}
            options={[
              { label: "0, N/A" },
              { label: "1, Plain plug Gauge" },
              { label: "2, Width gauge" },
              { label: "3, Paddle gauge" },
              { label: "4, Flush pin" },
              { label: "5, Snap gauge" },
            ]}
            renderInput={(params) => <TextField {...params} label="Gauge *" />}
            onInputChange={(e, newInputValue) => {
              setUpdateEnableDisable(parseInt(newInputValue.split(",")[0]));
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={materialtypeOp.map(e => ({
              label: e
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Material Type *" />
            )}
            value={materialtype === "" ? "select material type" : materialtype}
            onInputChange={(event, newInputValue) => {
              setMaterialtype(newInputValue)
            }}
          />
        </Grid>
      </Grid>
      <hr />
        <Grid container spacing={2}>
        <Grid item xs={4}>
            <Autocomplete
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.filteredStandards != null
                  ? this.state.filteredStandards.map((item) => {
                      return {
                        id: item.id,
                        stId: item.stId,
                        title: item.standardName,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.stId} <b>:</b> {option.title}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Standard Master *"
                  placeholder="select multiple standards"
                />
              )}
              onChange={(event, value) => {
                console.log({value});
                  this.setState({
                    currentCandidate_standardMaster: value.id,
                  });
                
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
            multiple
              fullWidth
              id="checkboxes-tags-demo"
              size="small"
              options={
                this.state.uncertaintyMasterData != null
                  ? this.state.uncertaintyMasterData.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                        distribution: item.distribution,
                        sensitives: item.sensitives,
                        coefficient: item.coefficient,
                      };
                    })
                  : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.id} <b>:</b> {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Uncertainity Factors"
                  placeholder="select multiple uncertainity"
                />
              )}
              onChange={(event, value) => {
                if (value.length) {
                  var arrToStr = Array.prototype.map
                    .call(value, function (item) {
                      return item.id;
                    })
                    .join(",");
                  this.setState({
                    currentCandidate_uncertaintyMaster: arrToStr,
                  });
                } else {
                  this.setState({
                    currentCandidate_uncertaintyMaster: null,
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              if(this.state.standardMasterIdArray == null)
                this.setState({standardMasterIdArray:  this.state.currentCandidate_standardMaster + ":" + this.state.currentCandidate_uncertaintyMaster})
              else{
                let value = this.state.standardMasterIdArray;
                value = value + "|" + this.state.currentCandidate_standardMaster + ":" + this.state.currentCandidate_uncertaintyMaster
                this.setState({standardMasterIdArray:  value})
              } 
            }}
          >
            ADD
          </Button>
          </Grid>
          <Grid item xs={12}>
            {this.state.standardMasterIdArray && displayTable(this.state.standardMasterIdArray, this.state.filteredStandards, this.state.uncertaintyMasterData)}
          </Grid>
        </Grid>
      <hr />
      <h4 style={{ "margin-bottom": "0px" }}>CMC</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow(0);
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].filter(e => (e.toggle === undefined || !et)).map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex !== 0 &&
                      cellIndex !== 1 &&
                      cellIndex !== 2 &&
                      cellIndex !== 3
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell?.split("#")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <br />
                          <select
                            // disabled={plus}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              )
                            }}>
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (unit.symbol == readingRangeValue[index][cellIndex]) {
                                return (
                                  <option value={unit.symbol} key={unit.symbol} selected>
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 1 && !et) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "150px" }}
                            size="small"
                            id="combo-box-demo"
                            value={cell}
                            options={mode.map(e => ({
                              label: e
                            }))}
                            renderInput={(params) => (
                              <TextField {...params} label="Mode *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue)
                            }}
                          // disabled={plus}
                          />
                          <select
                            // disabled={plus}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              )
                            }}>
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (unit.symbol == readingRangeValue[index][cellIndex]) {
                                return (
                                  <option value={unit.symbol} key={unit.symbol} selected>
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 2 && !et) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "250px" }}
                            size="small"
                            id="combo-box-demo"
                            value={cell}
                            options={parameters[row[1]] ? parameters[row[1]].map(e => ({
                              label: e
                            })) : []}
                            renderInput={(params) => (
                              <TextField {...params} label="Parameter *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue)
                            }}
                            // disabled={(plus || !row[2]) ? true : false}
                            disabled={(!row[1]) ? true : false}
                          />
                          <select
                            // disabled={(plus || !row[2]) ? true : false}
                            disabled={(!row[1]) ? true : false}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              )
                            }}>
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (unit.symbol == readingRangeValue[index][cellIndex]) {
                                return (
                                  <option value={unit.symbol} key={unit.symbol} selected>
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      )
                    }
                    if (cellIndex == 3 && !et) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "150px" }}
                            size="small"
                            id="combo-box-demo"
                            value={cell}
                            options={(paratype[row[1]] && paratype[row[1]][row[2]]) ? paratype[row[1]][row[2]].map(e => ({
                              label: e
                            })) : []}
                            renderInput={(params) => (
                              <TextField {...params} label="Parameter Type *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue)
                            }}
                            // disabled={(plus || !(paratype[row[2]] && paratype[row[2]][row[3]])) ? true : false}
                            disabled={(!(paratype[row[1]] && paratype[row[1]][row[2]])) ? true : false}
                          />
                          <select
                            // disabled={(plus || !(paratype[row[2]] && paratype[row[2]][row[3]])) ? true : false}
                            disabled={(!(paratype[row[1]] && paratype[row[1]][row[2]])) ? true : false}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              )
                            }}>
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (unit.symbol == readingRangeValue[index][cellIndex]) {
                                return (
                                  <option value={unit.symbol} key={unit.symbol} selected>
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      )
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(index, row[0]);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>

        <br />
      </div>

      <br />
      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {userType !== "3" ? (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        ) : (
          console.log("")
        )}
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            // handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
