import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
} from "@mui/material";
import CButton from "./../../../utils/components/Button";
const axios = require("axios");

export default function CreateRange(props) {
  var [instrumentName, setInstrumentName] = React.useState("");
  const [ranges, setRanges] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [rangeColumnName, setRangeColumnName] = React.useState([""]);
  const [specificColumnName, setSpecificColumnName] = React.useState([""]);

  const addDatasheetColumn = () => {
    setRangeColumnName([...rangeColumnName, ""]);
  };

  const deleteDatasheetColumn = (index) => {
    let newrangeColumnName = [...rangeColumnName];
    newrangeColumnName.splice(index, 1);
    setRangeColumnName([...newrangeColumnName]);
  };
  const updateRangeColumnName = (value, index) => {
    const newColumnName = [...rangeColumnName];
    newColumnName[index] = value;
    setRangeColumnName(newColumnName);
  };

  const addSpecificColumn = () => {
    setSpecificColumnName([...specificColumnName, ""]);
  };

  const deleteSpecificColumn = (index) => {
    let newrangeColumnName = [...specificColumnName];
    newrangeColumnName.splice(index, 1);
    setSpecificColumnName([...newrangeColumnName]);
  };
  const updateSpecificColumnName = (value, index) => {
    const newColumnName = [...specificColumnName];
    newColumnName[index] = value;
    setSpecificColumnName(newColumnName);
  };

  const onSubmit = () => {
    //    if (rangeColumnName.length) {
    //     var jsonData = {
    //         calPoint1 : rangeColumnName[0],
    //         calPoint2 : rangeColumnName[1],
    //         calPoint3 : rangeColumnName[2],
    //         calPoint4 : rangeColumnName[3],
    //         calPoint5 : rangeColumnName[4],
    //         calPoint6 : rangeColumnName[5],
    //         calPoint7 : rangeColumnName[6],
    //         calPoint8 : rangeColumnName[7],
    //         instrumentId: instrumentName,
    //         ranges: ranges
    //     }
    // }else if(specificColumnName.length){
    //   var jsonData = {
    //     specificPoint1 : specificColumnName[0],
    //     specificPoint2 : specificColumnName[1],
    //     specificPoint3 : specificColumnName[2],
    //     specificPoint4 : specificColumnName[3],
    //     specificPoint5 : specificColumnName[4],
    //     specificPoint6 : specificColumnName[5],
    //     specificPoint7 : specificColumnName[6],
    //     specificPoint8 : specificColumnName[7],
    //     instrumentId: instrumentName,
    //     ranges: ranges
    // }
    // }else{
    //     var jsonData = {
    //         instrumentId: instrumentName,
    //         ranges: ranges
    //     }
    // }

    var jsonData = {
      specificPoint1: specificColumnName[0],
      specificPoint2: specificColumnName[1],
      specificPoint3: specificColumnName[2],
      specificPoint4: specificColumnName[3],
      specificPoint5: specificColumnName[4],
      specificPoint6: specificColumnName[5],
      specificPoint7: specificColumnName[6],
      specificPoint8: specificColumnName[7],
      instrumentId: instrumentName,
      ranges: ranges,
    };

    Object.keys(jsonData).forEach((key) => {
      if (jsonData[key] === undefined || !jsonData[key]) {
        delete jsonData[key];
      }
    });
    let url = BASE_URL;
    axios
      .post(url + "ranges", jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("new range added!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getInstrumentsList();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create New Range
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={instrumentArray.map(({ id, instrumentName }) => ({
              label: id + ", " + instrumentName,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Instruments" />
            )}
            onInputChange={(event, newInputValue) => {
              var splArr = newInputValue.split(",");
              setInstrumentName(splArr.length ? parseInt(splArr[0]) : "");
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Range"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setRanges(e.target.value)}
          />
        </Grid>
      </Grid>
      <br />
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        add Calibration Points
        <CButton
          variant="contained"
          size="small"
          label={<AddIcon />}
          sx={{ ml: 3 }}
          onClick={() => {
            addDatasheetColumn();
          }}
        ></CButton>
      </Typography>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        {rangeColumnName.map((column, index) => (
          <Grid item xs={4}>
            <OutlinedInput
              id="outlined-adornment-password"
              label={"column " + (index + 1)}
              value={column}
              placeholder={"column " + (index + 1)}
              onChange={(e) => {
                updateRangeColumnName(e.target.value, index);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      deleteDatasheetColumn(index);
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        add Specific Points
        <CButton
          variant="contained"
          size="small"
          label={<AddIcon />}
          sx={{ ml: 3 }}
          onClick={() => {
            addSpecificColumn();
          }}
        ></CButton>
      </Typography>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        {specificColumnName.map((column, index) => (
          <Grid item xs={4}>
            <OutlinedInput
              id="outlined-adornment-password"
              label={"column " + (index + 1)}
              value={column}
              placeholder={"column " + (index + 1)}
              onChange={(e) => {
                updateSpecificColumnName(e.target.value, index);
              }}
              size="small"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      deleteSpecificColumn(index);
                    }}
                    edge="end"
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        ))}
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Save Range
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
