import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../global";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Grid, TextField, Autocomplete } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  {
    id: "invoiceNumber",
    label: "Invoice Number",
    minWidth: 40,
  },
  {
    id: "invoiceDate",
    label: "Invoice Date",
    align: "left",
    minWidth: 40,
  },
  { id: "clientName", label: "Company Name", minWidth: 40 },

  {
    id: "gstNumber",
    label: "Gst Number",
    minWidth: 40,
    align: "left",
  },
  {
    id: "sacCode",
    label: "Sac Code",
    minWidth: 40,
    align: "left",
  },

  {
    id: "totalAmount",
    label: "Total Amount",
    minWidth: 40,
    align: "left",
  },
  {
    id: "cgst",
    label: "cgst",
    minWidth: 40,
    align: "left",
  },
  {
    id: "sgst",
    label: "sgst",
    minWidth: 40,
    align: "left",
  },
  {
    id: "totalGst",
    label: "Total Gst",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  invoiceNumber,
  invoiceDate,
  clientName,
  gstNumber,
  sacCode,
  totalAmount,
  cgst,
  sgst,
  totalGst
) {
  return {
    id,
    invoiceNumber,
    invoiceDate,
    clientName,
    gstNumber,
    sacCode,
    totalAmount,
    cgst,
    sgst,
    totalGst,
  };
}

export default function GstReport() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paymentList, setPaymentList] = React.useState([]);
  const [paymentData, setPaymentData] = React.useState([]);
  const [client, setClient] = React.useState(0);
  const [fromDate, setFromDate] = React.useState(0);
  const [toDate, setToDate] = React.useState(0);
  const [pushArrays, setPushArray] = React.useState([]);
  const [dateData, setDateData] = React.useState([]);
  const [companyName, setCompanyName] = React.useState(null);
  var [cgst, setCgst] = React.useState(null);
  var [sgst, setSgst] = React.useState(null);
  var [totGst, setTotGst] = React.useState(null);
  var [totAmt, setTotAmt] = React.useState(null);
  var handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getPaymentList = (event) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          "xjoin?_join=payment.paymentMaster,_j,client.clients&_on1=(payment.clientId,eq,client.id)&_fields=payment.id,payment.totalAmount,payment.address,payment.contactNumber,payment.conveyanceCharges,payment.discount,payment.gstType,payment.invoiceDate,payment.invoiceNumber,payment.poNumber,payment.subject,client.companyName,payment.paymentDate,payment.paymentMode,client.gstNumber,payment.cgst,payment.sgst"
      )
      .then((res) => {
        setPaymentList(res.data);
        setDateData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };
  const initiatInvoiceList = () => {
    var rowData = [];
    var totalCgst = 0;
    var totalSgst = 0;
    var totalGst = 0;
    var totAm = 0;
    for (let i = 0; i < dateData.length; i++) {
      totalCgst += dateData[i].payment_cgst;
      totalSgst += dateData[i].payment_sgst;
      totalGst += dateData[i].payment_cgst + dateData[i].payment_sgst;
      totAm += dateData[i].payment_totalAmount;
      rowData.push(
        createData(
          dateData[i].payment_id,
          dateData[i].payment_invoiceNumber,
          dateData[i].payment_invoiceDate
            ? moment(paymentList[i].payment_invoiceDate).format("DD-MM-YYYY")
            : "",

          dateData[i].client_companyName,
          dateData[i].client_gstNumber,
          "sacCode",
          dateData[i].payment_totalAmount,
          dateData[i].payment_cgst,
          dateData[i].payment_sgst,
          dateData[i].payment_cgst + dateData[i].payment_sgst
        )
      );
      setCgst(totalCgst);
      setSgst(totalSgst);
      setTotGst(totalGst);
      setTotAmt(totAm);
    }
    setPaymentData(rowData);
  };
 
  const FilterData = () => {
    if (companyName == null) {
      var resultProductData = paymentList.filter(
        (data) =>
          new Date(data.payment_invoiceDate) >= new Date(fromDate) &&
          new Date(data.payment_invoiceDate) <= new Date(toDate)
      );
    } else {
      var resultProductData = paymentList.filter(
        (datas) =>
        new Date(datas.payment_invoiceDate) >= new Date(fromDate) &&
        new Date(datas.payment_invoiceDate) <= new Date(toDate) &&
        datas.client_companyName == companyName.label
      );
    }
    setDateData(resultProductData);
    initializeDataTable(resultProductData);
  };

  const getClient = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        setClient(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const intializeClient = () => {
    var pushArray = [];
    for (let i = 0; i < client.length; i++) {
      pushArray.push({
        label: client[i].companyName,
      });
    }
    setPushArray(pushArray);
  };

  useEffect(() => {
    intializeClient();
  }, [client]);

  useEffect(() => {
    initiatInvoiceList();
  }, [dateData]);

  useEffect(() => {
    getPaymentList();
    getClient();
  }, []);

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={pushArrays}
              renderInput={(params) => (
                <TextField {...params} label="Company Name" />
              )}
              onChange={(event, value) => setCompanyName(value)}
            />
          </Grid>
          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={fromDate ? fromDate : new Date()}
                onChange={(e) => {
                  setFromDate(e);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Due Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={toDate ? toDate : new Date()}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              FilterData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Search
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={(e) => {
              FilterData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Print
          </Button>
        </Grid>
      </div>
      <br />
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id == "id") {
                        value = index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <br />
        {paymentData.length ? (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "10px" }}
            justifyContent="flex-end"
          >
            <Grid item xs={2} justify="flex-end">
              <TextField
                id="outlined-basic"
                label="Total CGST"
                disabled
                minvalue="100"
                value={cgst != null && cgst != 0 ? cgst : ""}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {paymentData.length ? (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "10px" }}
            justifyContent="flex-end"
          >
            <Grid item xs={2} justify="flex-end">
              <TextField
                id="outlined-basic"
                label="Total SGST"
                disabled
                minvalue="100"
                value={sgst != null && sgst != 0 ? sgst : ""}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {paymentData.length ? (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "10px" }}
            justifyContent="flex-end"
          >
            <Grid item xs={2} justify="flex-end">
              <TextField
                id="outlined-basic"
                label="Total GST"
                disabled
                minvalue="100"
                value={totGst != null && totGst != 0 ? totGst : ""}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {paymentData.length ? (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: "10px" }}
            justifyContent="flex-end"
          >
            <Grid item xs={2} justify="flex-end">
              <TextField
                id="outlined-basic"
                label="Total Amount"
                disabled
                value={totAmt != null && totAmt != 0 ? totAmt : ""}
                size="small"
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={paymentData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ToastContainer />
      </TableContainer>
    </>
  );
}
