/* eslint-disable no-new-func */
import unitConvertor from "../../utils/components/unitConvertor";
import { BASE_URL } from "../../global";
const axios = require("axios");

let global_selectors = {};
let global_ucUnit = null

function findTokens(str) {
  const regex = /\$[\w.:'' ]+/g;
  const matches = str.match(regex);

  if (matches) {
    return matches;
  } else {
    return [];
  }
}

async function getValueFromTable(token) {
  // Extract table name and column name from the token
  const [tableName, columnName, attribute] = token.substring(1).split(".");
  const id = Number(global_selectors[tableName]);
  // Form the SQL query using the table name and column name
  let where_clause = ` AND id=${id} `;
  if (attribute?.includes(":")) {
    let [_columnName, value] = attribute.split(":");
    where_clause = ` AND ${_columnName}=${value}`;
  }
  const query = `SELECT ${columnName} FROM ${tableName} WHERE 1=1 ${where_clause}`;
  const data = { query };

  try {
    const res = await axios.post(BASE_URL + "dynamic", data);
    return res.data;
  } catch (error) {
    // Handle error here
    console.error("Error:", error);
    throw error;
  }
}

async function resolveCondition(conditions) {
  for (const element of conditions) {
    let condition = element;
    if (condition == "default") continue;
    let updatedCondition = condition;
    let tokens = findTokens(condition).filter((token) => token != "default");
    for (const element of tokens) {
      let token = element;
      let value = await getValueFromTable(token);
      value = Object.values(value[0])[0];
      if(/\D/.test(value))
        value = `'${value}'`
      updatedCondition = updatedCondition.replace(token, value);
    }

    let res = Function("return " + updatedCondition)();
    if (res === true) {
      return condition;
    }
  }
  return "default";
}

function getPrecision(value) {
  const valueString = value.toString();
  const decimalIndex = valueString.indexOf('.');
  
  if (decimalIndex === -1) {
    return 0;
  }
  
  return valueString.length - decimalIndex - 1;
}

async function resolveFormula(sourceFormula) {
  let updatedFormula = sourceFormula;
  // find values from table references
  let tokens = findTokens(sourceFormula);
  for (let i = 0; i < tokens?.length; i++) {
    let token = tokens[i];
    let raw_value = await getValueFromTable(token);
    let value = 0;
    let unit = "";
    if (raw_value?.[0]) {
      raw_value = Object.values(raw_value?.[0])?.[0];
      // unit conversion
      if(raw_value){
        value = raw_value?.split("#")[0];
        unit = raw_value?.split("#")[1];
        if(unit && global_ucUnit){
          try{
            value = unitConvertor(value, unit, global_ucUnit)
          }
          catch(err){
            console.log("failed to convert unit, error: ", err)
          }
          
        }
      }
    }

    updatedFormula = updatedFormula?.replace(token, value);
  }
  try {
    let res = Function("return " + updatedFormula)();
    if(typeof(res) === 'number' && getPrecision(res) > 5)
      res = res.toFixed(5)
    return res;
  } catch (e) {
    if(typeof(res) === 'number' && getPrecision(updatedFormula) > 5)
      updatedFormula = updatedFormula.toFixed(5)
    return updatedFormula;
  }
}

export async function resolveSource(source, selectors, ucUnit) {
  if (!source) return 0;

  global_selectors = selectors;
  global_ucUnit = ucUnit?.split("_unit_")[1];
  
  // source  = [{"con": "for", "def": "for"}]
  let conditions = Object.keys(source || {});
  let condition = await resolveCondition(conditions);
  let value = await resolveFormula(source[condition]);
  return value;
}

export async function resolveVisibility(source, selectors, ucUnit) {
  if (!source) return 0;

  global_selectors = selectors;
  global_ucUnit = ucUnit?.split("_unit_")[1];
  
  // source  = [{"con": "for", "def": "for"}]
  let conditions = Object.keys(source || {});
  let condition = await resolveCondition(conditions);
  let value = await resolveFormula(source[condition]);
  return value;
}
