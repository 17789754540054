import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import Global from "../image/header.png";
import { BASE_URL, DOMAIN, MAX_COLS } from "./../../global";
import { ClassicTable } from "./../../utils/components/Styles";
import "./certificate.css";
import { Image } from "react-bootstrap";

const axios = require("axios");

export default function ViewCertificate(props) {
  const printComponentRef = React.useRef();

  let { certificateId } = useParams();

  certificateId = certificateId ? certificateId : props.id;

  const [staticReadingRows, setStaticReadingRows] = React.useState([]);
  const [readingRows, setReadingRows] = React.useState([]);
  const [staticCertificateReadings, setStaticCertificateReadings] =
    React.useState([]);

  const [certificate, setCertificate] = React.useState(
    (Math.random() + 1).toString(36).substring(7),
  )
  const [instrument, setInstrument] = React.useState(null)
  const [tables, setTables] = React.useState(null)
  const [staticTables, setStaticTables] = React.useState(null)
  const [clientId, setClientId] = React.useState('')
  const [instrumentArray, setInstrumentArray] = React.useState([])
  const [standardMasterArray, setStandardMasterArray] = React.useState([])
  const [instrumentName, setInstrumentName] = React.useState('')
  const [make, setMake] = React.useState('')
  const [calibrationProcedureNo, setCalibrationProcedureNo] = React.useState('')
  const [referenceStandards, setReferenceStandards] = React.useState('')
  const [lc, setLC] = React.useState('')
  const [dcNumber, setDcNumber] = React.useState('')
  const [ulrNumber, setUlrNumber] = React.useState(
    (Math.random() + 1).toString(36).substring(7)
  );
  const [ranges, setRanges] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [calibrationDate, setCalibrationDate] = React.useState("");
  const [dcDate, setDcDate] = React.useState("");
  const [nextDueDate, setNextDueDate] = React.useState("");
  const [receiptDat, setReceiptDate] = React.useState("");
  const [calProcRefNo, setCalProcRefNo] = React.useState("");
  const [mfgNo, setMfgNo] = React.useState("");
  const [rangeDbData, setRangeData] = React.useState([]);
  const [allRangeData, setRangeDataArray] = React.useState([]);
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    []
  );
  const [identificationNo, setIdentificationNo] = React.useState("");
  const [DUCID, setDUCID] = React.useState([]);
  const [calType, setCalType] = React.useState("");
  const [tableName, setTableName] = React.useState("");
  const [unitName, setUnitName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [accuracy, setAccuracy] = React.useState("");
  const [filterRangeData, setFilterRangeData] = React.useState([]);
  const [serialNo, setSerialNo] = React.useState("");
  const [selectedStandards, setSelectedStandards] = React.useState("");
  const [serviceReqNumber, setServiceReq] = React.useState("");
  const [poDate, setPoDate] = React.useState(null);
  const [poNo, setPoNo] = React.useState(null);
  const [startTemp, setStartTemp] = React.useState("");
  const [endTemp, setEndTemp] = React.useState("");
  const [dateOfIssue, setDateOfIssue] = React.useState("");
  const [models, setModel] = React.useState("");
  const [ConOfDuc, setConOfDuc] = React.useState("");
  const [calPoint, setCalPoint] = React.useState("");
  const [calMethod, setCalMethod] = React.useState("");
  const [locationOfInstrument, setLocationOfInstrument] = React.useState("");
  const [staticTableCount, setStaticTableCount] = React.useState("");
  const [srnNo, setSrnNo] = React.useState("");
  const [assetNo, setAssetNo] = React.useState("");
  const [configuration, setConfiguration] = React.useState({});
  const [disciplineName, setDisciplineName] = React.useState("");

  // api calls
  function fetchCertificate() {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(cer.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cer.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=cer.clientId,cer.id,cer.id,cer.instrumentId,cer.lc,cer.make,cer.model,cer.ConOfDuc,cer.ranges,cer.mfgNo,cer.deletedTableIds,cust.companyName,ins.instrumentName,ins.standardMasterIds,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,cer.dcNumber,cer.calibrationDate,cer.dcDate,cer.nextDueDate,cer.receiptDate,cer.calProcRefNo,cer.mfgNo,cer.identificationNo,cer.DUCID,cer.DUCID,cer.calibrationType,cer.specificValues,cer.location,cer.serialNumber,cer.additionalStandardIds,cer.serviceReqNumber,cer.accuracy,cer.poNumber,cer.poDate,cer.startTemp,cer.endTemp,cer.dateOfIssue,cer.model,cer.calPoint,cer.calMethod,cer.locationOfInstrument,cer.srnNo,cer.atmosphericPressure,cer.configuration,disc.name&_where=(cer.id,eq, ${certificateId})`,
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setClientId(res.data[0].cust_companyName)
          setInstrumentName(res.data[0].ins_instrumentName)
          setMake(res.data[0].cer_make)
          setModel(res.data[0].cer_model)
          setLC(res.data[0].cer_lc)
          setRanges(res.data[0].cer_ranges)
          setReferenceStandards(res.data[0].ins_referenceStandards)
          setSelectedStandards(res.data[0].cer_additionalStandardIds)
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo)
          setAddress(res.data[0].cust_address)
          setDcNumber(res.data[0].cer_dcNumber)
          setCalibrationDate(res.data[0].cer_calibrationDate)
          setDcDate(res.data[0].cer_dcDate)
          setNextDueDate(res.data[0].cer_nextDueDate)
          setReceiptDate(res.data[0].cer_receiptDate)
          setCalProcRefNo(res.data[0].cer_calProcRefNo)
          setMfgNo(res.data[0].cer_mfgNo)
          setIdentificationNo(res.data[0].cer_identificationNo)
          setDUCID(res.data[0].cer_DUCID)
          setCalType(res.data[0].cer_calibrationType)
          res.data[0].cer_specificValues
            ? setSpecificValuesFromTable(
                res.data[0].cer_specificValues.split(",")
              )
            : setSpecificValuesFromTable([]);
          setLocation(res.data[0].cer_location);
          setAccuracy(res.data[0].cer_accuracy);
          setSerialNo(res.data[0].cer_serialNumber);
          setServiceReq(res.data[0].cer_serviceReqNumber);
          setPoNo(res.data[0].cer_poNumber);
          setPoDate(res.data[0].cer_poDate);
          setStartTemp(res.data[0].cer_startTemp);
          setEndTemp(res.data[0].cer_endTemp);
          setDateOfIssue(res.data[0].cer_dateOfIssue);
          setModel(res.data[0].cer_model);
          setConOfDuc(res.data[0].cer_ConOfDuc);
          setCalPoint(res.data[0].cer_calPoint);
          setCalMethod(res.data[0].cer_calMethod);
          setLocationOfInstrument(res.data[0].cer_locationOfInstrument);
          setSrnNo(res.data[0].cer_srnNo);
          setConfiguration(JSON.parse(res.data[0].cer_configuration));
          setDisciplineName(res.data[0].disc_name);
        }

        fetchCertificateStaticTables(res.data[0].cer_instrumentId, "");
        fetchRangesData(res.data[0].cer_instrumentId);

        fetchInstrument(res.data[0].cer_instrumentId);
        if (res.data[0].cer_additionalStandardIds) {
          let standardMasterIds = res.data[0].cer_additionalStandardIds;

          var splitStData = standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
            });
        } else if (res.data[0].ins_standardMasterIds) {
          var splitStData = res.data[0].ins_standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
            });
        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
      });
  }

  function fetchRangesData(instrumentId) {
    let url = BASE_URL;
    axios
      .get(url + `ranges?_where=(instrumentId,eq,${parseInt(instrumentId)})`)
      .then((res) => {
        setRangeData(res.data);
      });
  }

  function fetchCertificateStaticTables(instrumentId, deletedTableIds) {
    let url = BASE_URL;
    deletedTableIds = "";
    axios
      .get(
        url +
          `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})~and(id,ne,${deletedTableIds})`
      )
      .then((res) => {
        setStaticTables(res.data);
        setStaticTableCount(res.data.length);
      })
      .catch((err) => {
        console.log("datasheet static tables data fetching error: ", err);
      });
  }

  function fetchStaticReadingRows() {
    let url = BASE_URL;
    axios
      .get(
        url +
          `certificateStaticReadings?_where=(certificateId,eq,${certificateId})`
      )
      .then((res) => {
        setStaticCertificateReadings(res.data);
      })
      .catch((err) => {
        console.log("static certificate tables data fetching error: ", err);
      });
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err);
      });
  }

  const getTableIndex = (tableId, isStatic = false) => {
    if (!isStatic) {
      for (let i = 0; i < tables.length; i++) {
        if (tables[i]["id"] == tableId) {
          return i;
        }
      }
    } else {
      for (let i = 0; i < staticTables.length; i++) {
        if (staticTables[i]["id"] == tableId) {
          return i;
        }
      }
    }
    return -1;
  };

  const initiateStaticReadingRows = () => {
    if (!(staticCertificateReadings && staticTables)) return null;

    let readingRows = [];
    staticCertificateReadings.forEach((reading) => {
      let tableIndex = getTableIndex(reading.tableId, true);

      if (tableIndex == -1) return null;
      let row = [];
      row.push(staticTables[tableIndex]["id"]);
      row.push(reading["id"]);

      for (var col in staticTables[tableIndex]) {
        if (
          !(
            staticTables[tableIndex][col] == 0 ||
            staticTables[tableIndex][col] == null
          ) &&
          ![
            "id", // avoide duplicate
            "unitType",
            "instrumentId",
            "tableName",
            "parameterName",
            "defaultConfiguration",
            "conditionFormatting",
            "lastModified",
            "status",
            "isUncertainty",
          ].includes(col)
        ) {
          row.push(reading[col]);
        }
      }
      if (staticTables[tableIndex]["isUncertainty"] == 1) {
        row.push(reading["uncertainty"] ?? "null");
      }
      readingRows.push(row);
      setTableName(staticTables[tableIndex]["tableName"]);
      setUnitName(staticTables[tableIndex]["unitType"]);
    });
    setStaticReadingRows(readingRows);
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  };

  const initiateRangesList = () => {
    let rangeDataArray = [];
    for (let i = 0; i < rangeDbData.length; i++) {
      rangeDataArray.push(
        rangeDbData[i].specificPoint1,
        rangeDbData[i].specificPoint2,
        rangeDbData[i].specificPoint3,
        rangeDbData[i].specificPoint4,
        rangeDbData[i].specificPoint5,
        rangeDbData[i].specificPoint6,
        rangeDbData[i].specificPoint7,
        rangeDbData[i].specificPoint8
      );
    }
    var filtered = rangeDataArray.filter(function (el) {
      return el != null && el != "";
    });
    setRangeDataArray(filtered);
  };

  useEffect(() => {
    initiateRangesList();
  }, [rangeDbData]);

  useEffect(() => {
    initiateStaticReadingRows();
  }, [staticCertificateReadings, staticTables]);

  useEffect(() => {
    fetchRangesData();
    getInstrumentsList();
    fetchCertificate();
    fetchStaticReadingRows();
  }, []);

  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null;
    defaultConfiguration = JSON.parse(defaultConfiguration);
    let hasCommonHeader = defaultConfiguration.hasCommonHeader;
    if (hasCommonHeader) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",");
      let cols = [];
      commonHeaderConfig.forEach((col) => {
        let [name, size] = col?.split(":");
        let classNames =
          "black tableCss" + (name != "$" ? "" : "black tableCss");
        cols.push(
          <TableCell
            className={classNames}
            style={{
              textAlign: "center",
              fontWeight: "bold",
              borderColor: "black !important",
              lineHeight: "20px !important",
            }}
            colSpan={Number(size)}
          >
            {name != "$" ? name : ""}
          </TableCell>
        );
      });

      return (
        <TableRow>
          {/* for SN */}
          <TableCell className="black tableCss" align="center"></TableCell>
          {cols}
          <TableCell className="black tableCss"></TableCell>
        </TableRow>
      );
    }
    return null;
  };

  const renderTableCell = (table, row, rowIndex, cell, cellIndex, onChange) => {
    let field = null;
    if (String(cell)?.includes("_unit_")) {
      if (table["c" + (cellIndex + 1)]?.match(/m+\d+/g)) {
        field = <div></div>;
      } else {
        let unit = cell.split("_unit_")[1];
        field = (
          <div>
            <select
              className="noprint"
              name="units"
              id="units"
              onChange={onChange}
            >
              <option value={unit} key={unit} selected>
                {unit}
              </option>
            </select>
            <span className="print-only">
              <b>{unit}</b>
            </span>
          </div>
        );
      }
    } else if (cell[0] == "$") {
      field = (
        <span className="">
          {!isNaN(cell.slice(1))
            ? Number(cell.slice(1)).toFixed(2)
            : cell.slice(1)}
        </span>
      );
    } else if (cell == "null") {
      field = (
        <span className="">
          {!isNaN(cell.slice(1)) ? Number(cell.slice(1)).toFixed(2) : ""}
        </span>
      );
    } else {
      field = <span>{row[cellIndex + 2]}</span>;
    }
    return (
      <TableCell className="tableCss" key={cellIndex} style={{}}>
        {field}
      </TableCell>
    );
  };
  // render static table
  const renderRows = (table, staticReadingRows) => {
    let i = 1;
    return staticReadingRows?.map((row, rowIndex) => {
      if (row[0] == table.id) {
        if (row[2]?.includes("_rh_")) {
          return (
            <TableRow key={rowIndex} className="tableCss">
              <TableCell
                className="tableCss"
                colSpan={row.length - 2 + 1}
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                {row[0 + 2].split("_rh_")[1]}
              </TableCell>
            </TableRow>
          );
        } else if (row[2]?.includes("_unit_")) {
          return (
            <TableRow key={rowIndex} className="tableCss">
              <TableCell className="tableCss"></TableCell>
              {row
                .slice(2, row.length) // 2: tableId + datasheetId
                .map((cell, cellIndex) =>
                  renderTableCell(table, row, rowIndex, cell, cellIndex)
                )}
            </TableRow>
          );
        }
        // else normal row
        return (
          <TableRow key={rowIndex} className="tableCss">
            <TableCell className="tableCss">{i++}</TableCell>
            {row
              .slice(2, row.length) // 2: tableId + datasheetId
              .map((cell, cellIndex) =>
                renderTableCell(table, row, rowIndex, cell, cellIndex)
              )}
          </TableRow>
        );
      }
    });
  };
  const renderStaticTables = () => {
    if (!staticTables) return null;
    return staticTables.map((table, tableIndex) => {
      let i = 0;
      return (
        <>
          {staticTables.length <= 1 ? (
            <div key={tableIndex} sx={{ mb: 5 }}>
              <Typography
                variant="h6"
                component="div"
                className="font black"
                style={{ fontWeight: "100 !important", float: "left" }}
              >
                <span className="fw">
                  {configuration?.customTableNames?.[table.id] ||
                    table.tableName}
                </span>
              </Typography>
              <div style={{ width: "100%", overflow: "hidden" }}>
                <ClassicTable>
                  <Table aria-label="simple table" className="tableCss">
                    <TableHead className="tableCss">
                      {renderCommonHeader(table?.defaultConfiguration)}

                      <TableRow className="tableCss">
                        <TableCell
                          className=" tableCss"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <Typography
                            style={{ textAlign: "center", fontWeight: "600" }}
                            className="font"
                          >
                            S.N.
                          </Typography>
                        </TableCell>
                        {/* render columns*/}
                        {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                          if (table["c" + i] != null) {
                            return (
                              <TableCell
                                key={"c" + i}
                                className="tableCss black"
                                align="center"
                                style={{
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    textAlign: "center",
                                    fontWeight: "600",
                                    color: "black",
                                  }}
                                  className="font"
                                >
                                  {table["c" + i]}
                                </Typography>
                              </TableCell>
                            );
                          }
                        })}
                        {table["isUncertainty"] == 1 && (
                          <TableCell
                            className="black tableCss"
                            align="center"
                            style={{ textAlign: "center" }}
                          >
                            <Typography
                              style={{
                                textAlign: "center",
                                fontWeight: "bold !important",
                                color: "black",
                              }}
                              className="font"
                            >
                              {"Expanded Uncertainty (±)"}
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody className="tableCss">
                      {renderRows(table, staticReadingRows)}
                    </TableBody>
                  </Table>
                </ClassicTable>
              </div>
              <br />
            </div>
          ) : (
            <div className="col-5">
              <ClassicTable>
                <Table aria-label="simple table" className="tableCss">
                  <TableHead className="tableCss">
                    {renderCommonHeader(table?.defaultConfiguration)}

                    <TableRow className="tableCss">
                      <TableCell
                        className=" tableCss"
                        align="center"
                        style={{ textAlign: "center" }}
                      >
                        <Typography
                          style={{ textAlign: "center", fontWeight: "600" }}
                          className="font"
                        >
                          S.N.
                        </Typography>
                      </TableCell>
                      {/* render columns*/}
                      {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                        if (table["c" + i] != null) {
                          return (
                            <TableCell
                              className="black tableCss"
                              align="center"
                              style={{ textAlign: "center", fontWeight: "600" }}
                              key={"c" + i + "ti" + tableIndex}
                            >
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  textAlign: "center",
                                  fontWeight: "600",
                                }}
                                className="font"
                              >
                                {table["c" + i]}
                              </Typography>
                            </TableCell>
                          );
                        }
                      })}
                      {table["isUncertainty"] == 1 && (
                        <TableCell
                          className="black tableCss"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <Typography
                            style={{
                              textAlign: "center",
                              fontWeight: "bold !important",
                              color: "black",
                            }}
                            className=""
                          >
                            {"Expanded Uncertainty (±)"}
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody className="tableCss">
                    {renderRows(table, staticReadingRows)}
                  </TableBody>
                </Table>
              </ClassicTable>
              <br />
            </div>
          )}
        </>
      );
    });
  };

  return (
    <>
      <div id="printOnly" ref={printComponentRef} className="printWrapper">
        <Table style={{ padding: "10px" }}>
          <TableHead style={{ border: "1px solid green" }}>
            <tr>
              <td style={{ border: "none" }}>
                <div class="header-space">&nbsp;</div>
              </td>
            </tr>
          </TableHead>
          <TableBody style={{ border: "1px solid blue" }}>
            <tr>
              <td style={{ border: "none" }}>
                <div class="content">
                  <div className="label" style={{ fontSize: "13px" }}>
                    <div>
                      <table style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            <th rowspan="3" width="45%">
                              Customer Details : <br />
                              <span>
                                M/S.{clientId},{address}
                              </span>
                            </th>
                            <th colspan="4">Details Of Service Request Card</th>
                            <th colspan="1"></th>
                          </tr>
                          <tr>
                            <td>SRC NO</td>
                            <td>SRC Date</td>
                            <td>Date Of Receipt Of Instrument</td>
                            <td>Job No</td>
                            <td>PAGE NO</td>
                          </tr>
                          <tr>
                            <td>{srnNo}</td>
                            <td>{moment(dcDate).format("DD-MM-YYYY")}</td>
                            <td>{moment(receiptDat).format("DD-MM-YYYY")}</td>
                            <td>{serviceReqNumber} B</td>
                            <td className="num">/02</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>

                      <hr style={{ marginBottom: "-2px" }} />
                      <h6>
                        <b>DETAILS OF THE INSTRUMENT UNDER CALIBRATION</b>
                      </h6>
                      <hr style={{ marginTop: "-1px" }} />
                      <div className="row">
                        <div className="col-3 text-start">
                          <span>
                            <b>1. Instrument Name :</b>
                          </span>
                          <br />
                          <span>
                            <b>2. Make :</b>
                          </span>
                          <br />
                          <span>
                            <b>3. Model :</b>
                          </span>
                          <br />
                          <span>
                            <b>4. Instrument S.No :</b>
                          </span>
                          <br />
                        </div>
                        <div className="col-3 text-start">
                          <span>
                            <b>{instrumentName}</b>
                          </span>
                          <br />
                          <span>{make}</span>
                          <br />
                          <span>{models}</span>
                          <br />
                          <span>{serialNo}</span>
                          <br />
                        </div>
                        <div className="col-3 text-start">
                          <span>
                            <b>5. Range :</b>
                          </span>
                          <br />
                          <span>
                            <b>6. Resolution/L/C :</b>
                          </span>
                          <br />
                          <span>
                            <b>7. Accuracy :</b>
                          </span>
                          <br />
                          <span style={{}}>
                            <b>8. Asset .No :</b>
                          </span>
                        </div>
                        <div className="col-3 text-start">
                          <span>{ranges}</span>
                          <br />
                          <span>{lc}</span>
                          <br />
                          <span>{accuracy}</span>
                          <br />
                          <span style={{}}>{DUCID}</span>
                        </div>
                      </div>
                      <hr style={{ marginBottom: "-2px" }} />
                      <h6
                        className="text-start"
                        style={{ marginBottom: "-2px" }}
                      >
                        <b>Inst. Condition at the time of Receipt :</b>{" "}
                        {ConOfDuc}{" "}
                      </h6>
                      <hr style={{ marginBottom: "-2px" }} />
                      <div className="row">
                        <div className="col-6 text-start">
                          <h6>
                            <b>Calibration Carried Out At :</b> {location}
                          </h6>
                        </div>
                        <div className="col-6 text-start">
                          <h6>
                            <b>Calibration Method :</b> {calMethod}{" "}
                          </h6>
                        </div>
                      </div>
                      <hr style={{ marginTop: "-1px" }} />
                      <div className="text-start">
                        <h6>
                          <b>Calibration Procedure/IS Standards Used :</b>{' '}
                          {calProcRefNo} / {referenceStandards}
                        </h6>
                      </div>
                      <hr style={{ marginBottom: "-1px" }} />
                      <div className="row">
                        <div className="col-3 text-start">
                          <span>
                            <b>Format No. :</b> GC/FM/023
                          </span>
                        </div>
                        <div className="col-3 text-start">
                          <span>
                            <b>Issue No. :</b> 03
                          </span>
                        </div>
                        <div className="col-3 text-start">
                          <span>
                            <b>Rev No. : </b>00
                          </span>
                        </div>
                        <div className="col-3 text-start">
                          <span>
                            <b>Issue Date. :</b>{" "}
                            {moment(dateOfIssue).format("DD-MM-YYYY")}
                          </span>
                        </div>
                      </div>
                      <hr />
                      <h6 style={{ fontWeight: "bold", marginRight: "3px" }}>
                        ENVOIRNMENTAL CONDITION :
                      </h6>
                      <hr style={{ marginBottom: "-1px" }} />
                      <div className="row">
                        <div className="col-6 text-start">
                          <span style={{}}>
                            <b>Temperature. :</b> {startTemp}°C,
                          </span>
                        </div>
                        <div className="col-6 text-end">
                          <span style={{ marginLeft: "4px" }}>
                            <b>Humidity :</b> ({endTemp} % RH)
                          </span>
                        </div>
                      </div>
                      <hr style={{ marginBottom: "-2px" }} />
                      <h6 style={{ fontWeight: "bold" }}>
                        DETAILS OF REFERENCE STANDARDS AND MAJOR EQUIPMENTS USED
                        FOR CALIBRATION
                      </h6>
                      <hr style={{ marginTop: "-1px" }} />
                      <table>
                        <thead>
                          <tr>
                            <th
                              style={{
                                width: "10px",
                                fontSize: "12px",
                              }}
                            >
                              S NO.
                            </th>
                            <th
                              style={{
                                width: "10px",
                                fontSize: "12px",
                              }}
                            >
                              Instrument name
                            </th>
                            <th
                              style={{
                                width: "10px",
                                fontSize: "12px",
                              }}
                            >
                              Make / Model
                            </th>
                            <th
                              style={{
                                width: "10px",
                                fontSize: "12px",
                              }}
                            >
                              S NO / Asset No
                            </th>
                            <th
                              style={{
                                width: "10px",
                                fontSize: "12px",
                              }}
                            >
                              Traceble to NPL via Certificate No
                            </th>
                            <th
                              width="10%"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Calibrated By.
                            </th>
                            <th
                              width="10%"
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              Valid Upto
                            </th>
                          </tr>
                        </thead>
                        {standardMasterArray.map((row, index) => (
                          <tbody>
                            <tr key={row.name}>
                              <td style={{ padding: "3px", width: "10px" }}>
                                {index + 1}
                              </td>
                              <td style={{ padding: "3px", width: "10px" }}>
                                {row.standardName}
                              </td>
                              <td style={{ padding: "3px", width: "10px" }}>
                                {row.make}
                              </td>
                              <td style={{ padding: "3px", width: "10px" }}>
                                MY41046838
                              </td>
                              <td style={{ padding: "3px", width: "10px" }}>
                                {row.traceability}
                              </td>
                              <td style={{ padding: "3px", width: "10px" }}>
                                ANSHAANKAN
                              </td>
                              <td
                                style={{
                                  padding: "5px",

                                  width: "10px",
                                }}
                              >
                                {moment(row.validUpto).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>

                    <div style={{ padding: "10px" }}>
                      <div style={{ marginTop: "1em" }}>
                        <br />
                        <div className="row mt-2 text-start">
                          <span
                            style={{ fontSize: "15px", fontWeight: "bold" }}
                          >
                            Discipline/Group : {disciplineName}
                          </span>
                        </div>
                        <div
                          className="page-break-before"
                          style={{ fontSize: "10px" }}
                        >
                          <div className="row">{renderStaticTables()}</div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            <div className="">
                              <h5 style={{ float: "left" }}>Remarks :</h5>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="text-start" style={{}}>
                        <span>
                          1) UUC - Indicates 'Unit Under Calibraton'.
                        </span>
                        <br />
                        <span>
                          2) This certificate refers only to the particular
                          item submitted for the calibration.
                        </span>
                        <br />
                        <span>
                          3) The Uncertiality in measurments which includes
                          systematic error of the reference facility and
                          random error due to repeatability of the measurement
                          value is insignificant/as given in the test result.
                          The confidence level is 95%.
                        </span>
                        <br />
                        <span>
                          4) The result reported in this certificate are valid
                          at the time of and under the stipulated condition of
                          measurment.
                        </span>
                        <br />
                        <span>
                          4a) The reported values are average of five set of
                          reading and the calibration point done as per
                          customer request also the liability of our work is
                          equivalent to the amount paid for the calibration of
                          instrument.
                        </span>
                        <br />
                        <span>
                          6) The certificate shall not be reproducted, except
                          in full, unless written permission for the
                          publication of an aapproved abstract has been
                          obtained from the director of M/S global
                          calibration.
                        </span>
                      </div>
                      <div className="row mt-2 ">
                        <span
                          style={{ fontSize: '15px', fontWeight: 'bold' }}
                        >
                          ----------END OF CALIBRATION CERTIFICATE----------
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </TableBody>
          <TableFooter style={{ border: "1px solid red" }}>
            <div class="footer-space"></div>
          </TableFooter>
        </Table>

        <div class="letterHead">
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Image
              className="img-fluid"
              src={Global}
              style={{ width: "100%", height: "160px" }}
              alt="letter head"
            />

            <div
              style={{
                height: "50px",
              }}
              className="idsTable"
            >
              <table width="100%">
                <thead>
                  <tr>
                    <th>Calibration Certificate No</th>
                    <th>ULR NO</th>
                    <th>Page No</th>
                    <th>Date Of calibration </th>
                    <th>Recommended due date of calibration</th>
                  </tr>
                </thead>
                <tbody>
                  <td>{certificate}</td>
                  <td>{ulrNumber}</td>
                  <td className="num">/02</td>
                  <td>{moment(calibrationDate).format("DD-MM-YYYY")}</td>
                  <td>{moment(nextDueDate).format("DD-MM-YYYY")}</td>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="certificate-footer">
          <div className="row" style={{ width: "100%", marginTop: "30px" }}>
            <div className="col col-4" style={{ textAlignLast: "end" }}>
              <span>Calibrated By</span>
            </div>
            <div className="col col-4" style={{ textAlignLast: "center" }}>
              <QRCode
                value={`${DOMAIN}/#/certificate/viewCertificate/${certificateId}`}
                align="center"
                size={80}
              />
            </div>
            <div className="col col-4">
              <span>
                Quality Manager
                <br />
                (J.Malhotra)
              </span>
            </div>
          </div>
        </div>
      </div>

      <Toolbar className="bt" style={{ padding: "0px", width: "100%" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 3 }}
          onClick={handlePrint}
        >
          Print
        </Button>
      </Toolbar>
    </>
  );
}
