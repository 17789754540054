import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Link } from "react-router-dom";
import QRCode from "react-qr-code";
import { BASE_URL } from "./../../global";
import {
    Grid,
    TextField,
    Toolbar
} from "@mui/material";
import moment from "moment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net")

const axios = require("axios");

const columns = [
    {
        id: "id",
        label: "Sr. No.",
        align: "left",
        minWidth: 40,
    },
    {
        id: "customerName",
        label: "Customer Name",
        align: "left",
        minWidth: 40,
    },
    {
        id: "instrumentName",
        label: "Instrument Name",
        align: "left",
        minWidth: 40,
    },
    {
        id: "range",
        label: "Range",
        minWidth: 40,
        align: "left",
    },
    {
        id: "lc",
        label: "LC",
        minWidth: 40,
        align: "left",
    },
    {
        id: "identificationNo",
        label: "Identification No.",
        minWidth: 40,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "dateOfCalibration",
        label: "Date of Calibration",
        minWidth: 40,
        align: "left",
        format: (value) => value.toFixed(2),
    },
    {
        id: "dueDate",
        label: "Due Date",
        minWidth: 40,
        align: "left",
        format: (value) => value.toFixed(2),
    }
];

function createData(
    id,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo
) {
    return {
        id,
        customerName,
        workOrderNo,
        instrument,
        identificationNo,
        dateOfCalibration,
        calProcRefNo,
    };
}

const rows = [
    createData(
        1,
        "Universal lab",
        "BFI/2112/BFI/22/1/129",
        "Digital Caliper",
        55,
        "1/20/2022 12:00:00 AM",
        "Thermometer rer"
    ),
];

const qrModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};
export default function ClientRenewalList() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [certificate, setCertificate] = useState(null);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [renewalDate, setRenewalDate] = useState("");
    const [dateData, setDateData] = useState("");

    const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
    const [qrModalOpen, setQrModalOpen] = React.useState(false);
    const _clientId = localStorage.getItem("clientId")
    const handleOpen = () => setQrModalOpen(true);
    const handleClose = () => setQrModalOpen(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // api calls
    function fetchCertificate() {
        let url = BASE_URL;
        axios
            .get(
                url +
                `xjoin?_join=ds.certificates,_j,ins.instruments,_j,cust.clients&_on1=(ds.instrumentId,eq,ins.id)&_on2=(cust.id,eq,ds.clientId)&_where=(cust.id,eq,${_clientId})&_fields=ds.lc,ds.clientId,cust.companyName,ins.instrumentName,ds.id,ds.ranges,ds.DUCID,ds.calibrationDate,ds.nextDueDate`
            )
            .then((res) => {
                setCertificate(res.data);
                console.log(res.data);
                initializeDataTable();
            })
            .catch((err) => {
                console.log("certificate data fetching error: ", err);
            });
    }

    const FilterData = () => {

        let resultProductData = [...certificate]
        // var startDate = moment(new Date()).format("DD-MM-YYYY");
        // var endDate = moment().subtract(15, 'day').format("DD-MM-YYYY");
        if (to && from) {
            let startDate = moment(from).format("DD-MM-YYYY");
            let endDate = moment(to).format("DD-MM-YYYY");

            resultProductData = certificate.filter(a => {
            var date = moment(a.ds_nextDueDate).format("DD-MM-YYYY");
            return (date >= endDate && date <= startDate);
        });
        }
        
        setDateData(resultProductData);
    };

    const initializeDataTable = () => {

        $("#datatable-keytable").DataTable({
            destroy: true,
            keys: true,
            dom:
                "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
                "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
                "<'row'<'col-sm-12' tr>>" +
                "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
            "bPaginate": false,
            "bLengthChange": true,
            "bFilter": true,
            "bInfo": false,
            "bAutoWidth": true,
            language: {
                "zeroRecords": " "
            },

            buttons: ["copy", "csv", "excel", "print"],
        });
    }

    useEffect(() => {
        if (certificate !== null)
            FilterData()
    }, [certificate])

    useEffect(() => {
        fetchCertificate();

    }, []);

    const renderQrModal = () => {
        return (
            <Modal
                open={qrModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={qrModalStyle}>
                    <Typography
                        id="transition-modal-title"
                        variant="h6"
                        component="h2"
                        align="center"
                    >
                        QR Code of Certificate
                    </Typography>
                    <hr />
                    <div align="center">
                        <QRCode value={qrCodeUrl} align="center" />
                        <hr />
                        <Button size="small" align="center">
                            Print
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };

    return (
        <div>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <br />
                <Grid container spacing={2} align="left"
                    style={{ display: "flex" }}>
                    <Grid item xs={2}>
                        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
                                <DatePicker
                                    label="From"
                                    value={from ? new Date(from) : ''}
                                    inputFormat="dd/MM/yyyy"
                                    format="dd/MM/yyyy"
                                    onChange={(newValue) => setFrom(newValue)}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                />
                            </LocalizationProvider>
                        </div>

                    </Grid>
                    <Grid item xs={2}>

                        <div style={{ marginRight: "10px" }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={to ? new Date(to) : ''}
                                    inputFormat="dd/MM/yyyy"
                                    format="dd/MM/yyyy"
                                    onChange={(newValue) => setTo(newValue)}
                                    renderInput={(params) => <TextField {...params} size="small" />}
                                    style={{ marginRight: "20px" }}
                                />
                            </LocalizationProvider>
                        </div>
                    </Grid>
                    <Toolbar style={{ padding: "0px" }} sx={{ mt: 0.5 }}>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ m: 0, ml: 2 }}
                            onClick={(e) => {
                                FilterData();
                            }}
                            disabled={!(to && from)}
                        >
                            Search
                        </Button>
                    </Toolbar>
                </Grid>

                <Table stickyHeader aria-label="sticky table" size="small" id="datatable-keytable">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <b>{column.label}</b>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dateData &&
                            dateData
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                        // key={row.code}
                                        >
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                                {row.cust_companyName}
                                            </TableCell>
                                            <TableCell>
                                                {row.ins_instrumentName}
                                            </TableCell>
                                            <TableCell>
                                            {row.ds_ranges?.replaceAll("||", ", ")?.replaceAll("|", " to ")?.replaceAll("#","")}
                                            </TableCell>
                                            <TableCell>
                                            {row.ds_lc?.replaceAll("||", ", ")?.replaceAll("|", " to ")?.replaceAll("#","")}
                                            </TableCell>
                                            <TableCell>
                                            {row.ds_DUCID}
                                            </TableCell>
                                            <TableCell>
                                            {moment(row.ds_calibrationDate).format("DD-MM-YYYY")}
                                            </TableCell>
                                            <TableCell>
                                            {moment(row.ds_nextDueDate).format("DD-MM-YYYY")}
                                            </TableCell>
                                        </TableRow>


                                    );
                                })}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            {renderQrModal()}
        </div>
    );
}
