import axios from "axios";
import { BASE_URL } from "../../global";


 // api calls
 export const checkDSStatusByCount = (setDBStatus) => {
    axios
      .get(BASE_URL + `datasheets/aggregate?_fields=id`)
      .then(async (res) => {
        // extract max id of DSs
        let ds_max_id = res.data[0]?.max_of_id
        axios
        .get(BASE_URL + `certificates/aggregate?_fields=id`)
        .then(async (res) => {
            console.log("ds_max_id", ds_max_id)
            console.log("cert_max_id", res.data[0]?.max_of_id)
            setDBStatus(res.data[0]?.max_of_id == ds_max_id ? "healthy" : "sick");
        })
        .catch((err) => {
          console.log("datasheet data fetching error: ", err);
        });
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }