import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
const axios = require("axios");
const materialtypeOp = ["select material type", "carbite", "steel"];

const mode = ["Source", "Measure"];

const parameters = {
  Source: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Oscilloscope",
    "Temperature Simulation",
    "AC Power @",
    "DC Power",
    "Conductivity meter",
    "pH Meter",
  ],
  Measure: [
    "AC Voltage @",
    "DC Voltage",
    "AC Current @",
    "DC Current",
    "Resistance (Direct Current)",
    "AC Resistance @",
    "Capacitance @",
    "Inductance @",
    "Frequency",
    "Temperature Simulation",
    "AC / DC High Voltage",
    "Time",
    "AC / DC High Current (By Direct)",
    "AC / DC High Current (By Clamp)",
  ],
};

const paratype = {
  Source: {
    Oscilloscope: ["Amplitude", "Time Base", "Frequency"],
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD(PT - 100)",
    ],
  },
  Measure: {
    "Temperature Simulation": [
      "B-Type",
      "E-Type",
      "J-Type",
      "K-Type",
      "N-Type",
      "R-Type",
      "S-Type",
      "T-Type",
      "U-Type",
      "RTD (PT-100)",
    ],
  },
};

export default function CreateStandard(props) {
  const [materialtype, setMaterialtype] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [standardName, setStandardName] = React.useState("");
  const [make, setMake] = React.useState("");
  const [traceability, setTraceability] = React.useState("");
  const [validUpto, setValidUpto] = React.useState("");
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [disciplines, setDisciplineArray] = React.useState([]);
  const [srfObject, createSrfObject] = React.useState({});
  const [standardRanges, setStandardRanges] = React.useState([]);
  const [curruntsetDecipline, setDeciplines] = React.useState("");
  const [calDate, setCalDate] = React.useState("");
  const [stId, setStId] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([]);
  const [readingRangeValue, setReadingRangeValue] = React.useState([]);
  const [disciplineList, setDisciplineList] = React.useState([]);
  const [deletedIds, setDeletedIds] = React.useState([]);
  const [updatedArray, setUpdatedArray] = React.useState([]);
  const [unitFilter, setUnitFilter] = React.useState([]);
  const [unitArray, setUnitArray] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [percent, setPercent] = React.useState(false);
  const [plus, setPlus] = React.useState(false);
  const [acc, setAccuracy] = React.useState(false);
  const [ids, setId] = React.useState("");
  const [image, setImage] = React.useState("");
  const [imageName, setImageName] = React.useState("");
  const [masterrange, setMatserRange] = React.useState("");
  const [masteraccuracy, setMasteraccuracy] = React.useState("");
  const [masterleastcount, setMasterleastcount] = React.useState("");
  const [location, setLocation] = React.useState("");

  const params = useParams();
  var readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "from_range",
        headerName: "Cal.Lower Point",
        editable: true,
      },
      {
        field: "to_range",
        headerName: "Cal. Higher Point",
        editable: true,
      },
      {
        field: "mode",
        headerName: "Mode",
        editable: true,
        toggle: 1,
      },
      {
        field: "parameter",
        headerName: "Parameter",
        editable: true,
        toggle: 1,
      },
      {
        field: "paratype",
        headerName: "Parameter Type",
        editable: true,
        toggle: 1,
      },
      {
        field: "uncertainty",
        headerName: "Uncertainty",
        editable: true,
      },
      {
        field: "accuracy",
        headerName: "Accuracy",
        editable: true,
      },
      {
        field: "percentOfOutput",
        headerName: "% Of Output",
        editable: true,
        toggle: 1,
      },
      {
        field: "plusValue",
        headerName: "+ Value",
        editable: true,
        toggle: 1,
      },
      {
        field: "stability",
        headerName: "Stability",
        editable: true,
      },
      {
        field: "uniformity",
        headerName: "Uniformity",
        editable: true,
      },

      {
        field: "reaction",
        headerName: "Standard Reading",
        editable: true,
      },
      {
        field: "gravity",
        headerName: "Least Count",
        editable: true,
      },
      {
        field: "drift",
        headerName: "Drift",
        editable: true,
      },
      {
        field: "cmv",
        headerName: "Conventional Mass Value",
        editable: true,
      },
      {
        field: "mpe",
        headerName: "MPE",
        editable: true,
      },
      {
        field: "materialDensity",
        headerName: "Material density",
        editable: true,
      },
      {
        field: "Action",
      },
    ],
  ];
  const handleChange = async (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleApi = () => {
    //call the api
    const url = "https://login.globalcalibration.com/fileUpload.php";

    const data = new FormData();
    data.append("fileToUpload", image);
    axios.post(url, data).then((res) => {
      if (res.status == 200) {
        toast("File Uploaded successfully !");
        setTimeout(refresh, 500);
      }
    });
  };

  const getDiscipline = () => {
    let url = BASE_URL;
    axios
      .get(url + "discipline?_where=(status,eq,1)")
      .then((res) => {
        setDisciplineArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching disciplines!");
      });
  };

  const getStandard = () => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=ds.standards,_j,disciplines.discipline,&_on1=(ds.disciplineId,eq,disciplines.id)&_fields=ds.location,ds.materialtype,ds.masterrange,ds.masteraccuracy,ds.masterleastcount,ds.standardName,ds.make,ds.id,ds.certificateUrl,ds.traceability,ds.validUpto,ds.id,ds.certificateNumber,disciplines.name,ds.stId,ds.calDate,disciplines.id,disciplines.disciplineKey&_where=(ds.id,eq,${params.id})~and(ds.status,eq,1)`
      )
      .then((res) => {
        setLocation(res.data[0]?.ds_location);
        setMaterialtype(res.data[0]?.ds_materialtype);
        setStandardName(res.data[0]?.ds_standardName);
        setMake(res.data[0]?.ds_make);
        setTraceability(res.data[0]?.ds_traceability);
        setValidUpto(res.data[0]?.ds_validUpto);
        setCertificateNumber(res.data[0]?.ds_certificateNumber);
        setId(res.data[0]?.ds_stId);
        setMatserRange(res.data[0]?.ds_masterrange);
        setMasteraccuracy(res.data[0]?.ds_masteraccuracy);
        setMasterleastcount(res.data[0]?.ds_masterleastcount);

        setDeciplines(
          res.data[0]?.disciplines_id + ", " + res.data[0]?.disciplines_name
        );
        setStId(res.data[0]?.ds_stId);
        setImageName(
          res.data[0]?.ds_certificateUrl
            ? res.data[0].ds_certificateUrl.split("/").pop()
            : "No File"
        );
        setImage(res.data[0]?.ds_certificateUrl);
        if (res.data[0]?.disciplines_disciplineKey == 0) {
          setPercent(false);
          setPlus(false);
          setAccuracy(false);
        } else {
          setPercent(true);
          setPlus(true);
          setAccuracy(false);
        }

        const unitArr = [""];
        for (let i = 0; i < unitArray?.length; i++) {
          if (
            parseInt(res.data[0]?.disciplines_id) == unitArray[i]?.disciplineId
          ) {
            unitArr.push(unitArray[i]);
          }
        }
        setUnits(unitArr);
        setCalDate(res.data[0]?.ds_calDate);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something Went Wrong with standards!");
      });
  };

  const getRanges = () => {
    axios
      .get(
        BASE_URL +
          `standardRanges/count?_where=(standardId,eq,${params.id})~and(status,eq,1)&_p=${page}&_size=${rowsPerPage}`
      )
      .then((res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });

    axios
      .get(
        BASE_URL +
          `standardRanges?_where=(standardId,eq,${params.id})~and(status,eq,1)&_sort=id&_p=${page}&_size=${rowsPerPage}`
      )
      .then((res) => {
        setStandardRanges(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong while fetching standardRanges!");
      });
  };
  const initiateRanges = () => {
    let rows = [];
    let rowsSpace = [];
    for (let i = 0; i < standardRanges.length; i++) {
      rowsSpace.push([
        "",
        ((standardRanges[i].rangeName || "|").split("|")[0] || "#").split(
          "#"
        )[1],
        ((standardRanges[i].rangeName || "|").split("|")[1] || "#").split(
          "#"
        )[1],
        (standardRanges[i].mode || "#").split("#")[1],
        (standardRanges[i].parameter || "#").split("#")[1],
        (standardRanges[i].paratype || "#").split("#")[1],
        (standardRanges[i].uncertainty || "#").split("#")[1],
        (standardRanges[i].accuracy || "#").split("#")[1],
        (standardRanges[i].percentOfOutput || "#").split("#")[1],
        (standardRanges[i].plusValue || "#").split("#")[1],
        (standardRanges[i].stability || "#").split("#")[1],
        (standardRanges[i].uniformity || "#").split("#")[1],
        (standardRanges[i].reactionTime || "#").split("#")[1],
        (standardRanges[i].gravity || "#").split("#")[1],
        (standardRanges[i].drift || "#").split("#")[1],
        (standardRanges[i].cme || "#").split("#")[1],
        (standardRanges[i].mve || "#").split("#")[1],
        (standardRanges[i].materialDensity || "#").split("#")[1],
      ]);
      rows.push([
        standardRanges[i].id,
        ((standardRanges[i].rangeName || "|").split("|")[0] || "#").split(
          "#"
        )[0],
        ((standardRanges[i].rangeName || "|").split("|")[1] || "#").split(
          "#"
        )[0],
        standardRanges[i].mode,
        standardRanges[i].parameter,
        standardRanges[i].paratype,
        standardRanges[i].uncertainty,
        standardRanges[i].accuracy,
        standardRanges[i].percentOfOutput,
        standardRanges[i].plusValue,
        standardRanges[i].stability,
        standardRanges[i].uniformity,
        standardRanges[i].reactionTime,
        standardRanges[i].gravity,
        standardRanges[i].drift,
        standardRanges[i].cme,
        standardRanges[i].mve,
        standardRanges[i].materialDensity,
      ]);
    }
    setReadingRows(rows);
    setReadingRangeValue(rowsSpace);
  };

  const getUnitMasterArray = () => {
    let url = BASE_URL;
    axios
      .get(url + "unit?_where=(status,eq,1)")
      .then((res) => {
        setUnitArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const UnitList = () => {
    var uArr = [];
    for (let i = 0; i < units.length; i++) {
      uArr.push({
        label: units[i].id + ", " + units[i].symbol,
      });
    }
    setUnitFilter(uArr);
  };

  useEffect(() => {
    getDiscipline();
    getRanges();
    getUnitMasterArray();
  }, []);

  useEffect(() => {
    UnitList();
  }, [units]);

  useEffect(() => {
    initiateDisciplineList();
  }, [disciplines]);
  useEffect(() => {
    initiateRanges();
  }, [standardRanges]);
  useEffect(() => {
    getStandard();
  }, [unitArray]);

  const initiateDisciplineList = () => {
    let disciplineList = [];
    for (let i = 0; i < disciplines.length; i++) {
      disciplineList.push({
        label: disciplines[i].id + ", " + disciplines[i].name,
      });
    }
    setDisciplineList(disciplineList);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const updateData = (url) => {
    let row = {
      standardName: standardName,
      stId: ids,
      make: make,
      certificateNumber: certificateNumber,
      traceability: traceability,
      calDate: moment(calDate).format("YYYY-MM-DD"),
      validUpto: validUpto ? moment(validUpto).format("YYYY-MM-DD") : null,
      certificateUrl: url ? url : null,
      disciplineId: srfObject.disciplineId,
      masterrange: srfObject.masterrange,
      masteraccuracy: srfObject.masteraccuracy,
      masterleastcount: srfObject.masterleastcount,
      materialtype:
        srfObject.materialtype === "select material type"
          ? ""
          : srfObject.materialtype,
      location: location,
    };
    console.log("updated row : ", row);
    // var config = {
    //   method: "patch",
    //   url: `https://login.globalcalibration.com/api/standards/${params.id}`,
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    //   data: row
    // }

    // axios(config)
    axios
      .patch(BASE_URL + `standards/${params.id}`, row)
      .then((res) => {
        submitRanges();
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
        props.setLoader(false);
      });
  };
  const handleSubmit = (event) => {
    props.setLoader(true);
    if (image) {
      const url =
        "https://login.globalcalibration.com/api/fileUpload/fileUpload.php";
      const data = new FormData();
      data.append("fileToUpload", image);
      axios.post(url, data).then((res) => {
        if (res.status == 200) {
          updateSrfObject("certificateUrl", res.data);
          updateData(res.data);
        }
      });
    } else {
      updateData();
    }
  };

  function sendRequest(data) {
    return new Promise((resolve, reject) => {
      if (data.id) {
        axios
          .patch(BASE_URL + `standardRanges/${data.id}`, data)
          .then((res) => {
            resolve();
          })
          .catch((error) => {
            props.setLoader(false);
            toast.error("Something Went Wrong!");
          });
      } else {
        delete data.id;
        axios
          .post(BASE_URL + `standardRanges`, data)
          .then((res) => {
            resolve();
          })
          .catch((error) => {
            props.setLoader(false);
            toast.error("Something Went Wrong!");
          });
      }
    });
  }

  const submitRanges = async () => {
    let rows = [];
    let rangeIds = [];
    for (let i = 0; i < readingRows.length; i++) {
      rows.push({
        standardId: params.id,
        fromRange: readingRows[i][1],
        toRange: readingRows[i][2],
        mode: readingRows[i][3],
        parameter: readingRows[i][4],
        paratype: readingRows[i][5],
        uncertainty: readingRows[i][6],
        accuracy: readingRows[i][7],
        percentOfOutput: readingRows[i][8],
        plusValue: readingRows[i][9],
        stability: readingRows[i][10],
        uniformity: readingRows[i][11],
        reactionTime: readingRows[i][12],
        gravity: readingRows[i][13],
        drift: readingRows[i][14],
        id: readingRows[i][0],
      });
      Number(readingRows[i][0]) && rangeIds.push(readingRows[i][0]);
    }

    const finalArr = [];
    for (let i = 0; i < rows.length; i++) {
      // for (let j = 0; j < readingRangeValue.length; j++) {
      finalArr.push({
        id: rows[i].id,
        standardId: params.id,
        rangeName: `${rows[i].fromRange}#${
          readingRangeValue[i][1] != null ? readingRangeValue[i][1] : ""
        }|${rows[i].toRange}#${
          readingRangeValue[i][2] != null ? readingRangeValue[i][2] : ""
        }`,
        mode: rows[i].mode,
        parameter: rows[i].parameter,
        paratype: rows[i].paratype,
        uncertainty:
          readingRangeValue[i][6] != null && rows[i].uncertainty !== null
            ? rows[i].uncertainty.split("#")[0] + "#" + readingRangeValue[i][6]
            : rows[i].uncertainty,
        accuracy:
          readingRangeValue[i][7] != null && rows[i].accuracy !== null
            ? rows[i].accuracy.split("#")[0] + "#" + readingRangeValue[i][7]
            : rows[i].accuracy,
        percentOfOutput:
          readingRangeValue[i][8] != null && rows[i].percentOfOutput !== null
            ? rows[i].percentOfOutput.split("#")[0] +
              "#" +
              readingRangeValue[i][8]
            : rows[i].percentOfOutput,
        plusValue:
          readingRangeValue[i][9] != null && rows[i].plusValue !== null
            ? rows[i].plusValue.split("#")[0] + "#" + readingRangeValue[i][9]
            : rows[i].plusValue,
        stability:
          readingRangeValue[i][10] != null && rows[i].stability !== null
            ? rows[i].stability.split("#")[0] + "#" + readingRangeValue[i][10]
            : rows[i].stability,
        uniformity:
          readingRangeValue[i][11] != null && rows[i].uniformity
            ? rows[i].uniformity.split("#")[0] + "#" + readingRangeValue[i][11]
            : rows[i].uniformity,
        reactionTime:
          readingRangeValue[i][12] != null && rows[i].reactionTime
            ? rows[i].reactionTime.split("#")[0] +
              "#" +
              readingRangeValue[i][12]
            : rows[i].reactionTime,
        gravity:
          readingRangeValue[i][13] != null && rows[i].gravity != null
            ? rows[i].gravity.split("#")[0] + "#" + readingRangeValue[i][13]
            : rows[i].gravity,
        drift:
          readingRangeValue[i][14] != null && rows[i].drift !== null
            ? rows[i].drift.split("#")[0] + "#" + readingRangeValue[i][14]
            : rows[i].drift,
      });
    }
    // insert/update records
    let patch = [];
    let post = [];
    finalArr.map((row) => {
      if (row.id) {
        patch.push(row);
      } else {
        delete row.id;
        post.push(row);
      }
    });
    Promise.all(
      patch
        .map((row) => {
          return axios
            .patch(BASE_URL + `standardRanges/${row.id}`, row)
            .then((res) => {
              return res;
            })
            .catch((error) => {
              toast.error("Something Went Wrong!");
              return error;
            });
        })
        .concat(
          post.length > 0
            ? [
                axios
                  .post(BASE_URL + `standardRanges/bulk`, post)
                  .then((res) => {
                    return res;
                  })
                  .catch((error) => {
                    toast.error("Something Went Wrong!");
                    return error;
                  }),
              ]
            : []
        )
    ).then((res) => {
      props.setLoader(false);
      toast.success("Standards updated successfully!");
    });
  };

  // data operations
  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    console.log(rowIndex, colIndex, value);
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const addRangeRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    if (tableIndex !== undefined) {
      newReadingRows.push([...readingRows[tableIndex]]);
      newReadingRows[newReadingRows.length - 1][0] = 0;
    } else
      newReadingRows.push([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ]);
    setReadingRows([...newReadingRows]);

    let newReadingRowss = [...readingRangeValue];
    if (tableIndex !== undefined)
      newReadingRowss.push([...readingRangeValue[tableIndex]]);
    else
      newReadingRowss.push([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
    setReadingRangeValue([...newReadingRowss]);
    setTotalRows(totalRows + 1);
  };

  const deleteReadingRow = (rowIndex, id) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined && id) {
      let url = BASE_URL;
      axios
        .delete(url + `standardRanges/${id}`)
        .then((res) => {
          // toast("standards Deleted Successfully !");
          // setTimeout(refresh, 500);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
    setTotalRows(totalRows - 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  useEffect(() => {
    getRanges();
  }, [rowsPerPage, page]);

  readingColumns = updatedArray.length ? updatedArray : readingColumns;

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Master EQP
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={disciplines.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            value={curruntsetDecipline}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              console.log("newInputValue :  ", newInputValue);
              var disciplineKeys = false;
              disciplines.forEach((el) => {
                if (el.id == newInputValue.split(",")[0]) {
                  if (el.disciplineKey == "0") {
                    disciplineKeys = true;
                  }
                }
              });

              const unitArr = [""];
              for (let i = 0; i < unitArray.length; i++) {
                if (
                  parseInt(newInputValue.split(",")[0]) ==
                  unitArray[i].disciplineId
                ) {
                  unitArr.push(unitArray[i]);
                }
              }
              setUnits(unitArr);

              updateSrfObject(
                "disciplineId",
                parseInt(newInputValue.split(",")[0])
              );

              if (disciplineKeys) {
                setPercent(false);
                setPlus(false);
                setAccuracy(false);
              } else {
                setPercent(true);
                setPlus(true);
                setAccuracy(false);
              }
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Master EQP Name *"
            size="small"
            value={standardName}
            fullWidth
            variant="outlined"
            onChange={(e) => setStandardName(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="id/Sr No. "
            size="small"
            value={ids}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setId(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Make/Model "
            size="small"
            value={make}
            fullWidth
            variant="outlined"
            onChange={(e) => setMake(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Tracability "
            size="small"
            fullWidth
            value={certificateNumber}
            variant="outlined"
            onChange={(e) => setCertificateNumber(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="outlined-basic"
            label="Cal. Agency."
            size="small"
            fullWidth
            value={traceability}
            variant="outlined"
            onChange={(e) => setTraceability(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Cal Date *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={calDate ? new Date(calDate) : ""}
              onChange={(newValue) => setCalDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Due Date  *"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={validUpto ? new Date(validUpto) : ""}
              // onChange={(newValue) => {
              //   updateSrfObject("validUpto", newValue);
              // }}
              onChange={(newValue) => setValidUpto(newValue)}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="location"
            label="Location "
            size="small"
            fullWidth
            variant="outlined"
            value={location}
            onChange={(e) => {
              updateSrfObject("location", e.target.value);
              setLocation(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="masterrange"
            label="Range "
            size="small"
            fullWidth
            variant="outlined"
            value={masterrange}
            onChange={(e) => {
              updateSrfObject("masterrange", e.target.value);
              setMatserRange(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="masteraccuracy"
            label="Accuracy/Class "
            size="small"
            fullWidth
            variant="outlined"
            value={masteraccuracy}
            onChange={(e) => {
              updateSrfObject("masteraccuracy", e.target.value);
              setMasteraccuracy(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="masterleastcount"
            label="Least Count "
            size="small"
            fullWidth
            variant="outlined"
            value={masterleastcount}
            onChange={(e) => {
              updateSrfObject("masterleastcount", e.target.value);
              setMasterleastcount(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label=" File Name"
            size="small"
            fullWidth
            value={imageName}
            inputProps={{ readOnly: true }}
            disabled
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={materialtypeOp.map((e) => ({
              label: e,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Material Type " />
            )}
            value={materialtype === "" ? "select material type" : materialtype}
            onInputChange={(event, newInputValue) => {
              updateSrfObject("materialtype", newInputValue);
              setMaterialtype(newInputValue);
            }}
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      <h4 style={{ "margin-bottom": "0px" }}>Master Ranges</h4>
      <Button
        style={{ float: "right" }}
        onClick={(e) => {
          addRangeRow();
        }}
      >
        <AddIcon />
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0]
                  .filter((e) => e.toggle === undefined || !plus)
                  .map((column, index) => (
                    <TableCell key={column.field}>
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {index + 1}
                    <Button
                      onClick={(e) => {
                        addRangeRow(index);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 3 &&
                      cellIndex != 4 &&
                      cellIndex != 5 &&
                      cellIndex != 7 &&
                      cellIndex != 8 &&
                      cellIndex != 9 &&
                      cellIndex != 0
                    ) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell?.split("#")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3 && !plus) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "150px" }}
                            size="small"
                            id="combo-box-demo"
                            options={mode.map((e) => ({
                              label: e,
                            }))}
                            value={cell}
                            renderInput={(params) => (
                              <TextField {...params} label="Mode *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            disabled={plus}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4 && !plus) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "250px" }}
                            size="small"
                            id="combo-box-demo"
                            options={
                              parameters[row[3]]
                                ? parameters[row[3]].map((e) => ({
                                    label: e,
                                  }))
                                : []
                            }
                            value={cell}
                            renderInput={(params) => (
                              <TextField {...params} label="Parameter *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            disabled={plus || !row[3] ? true : false}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5 && !plus) {
                      return (
                        <TableCell>
                          {plus ||
                          !(paratype[row[3]] && paratype[row[3]][row[4]]) ? (
                            <TextField
                              size="small"
                              disabled={acc}
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            ></TextField>
                          ) : (
                            <Autocomplete
                              style={{ width: "150px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={
                                paratype[row[3]] && paratype[row[3]][row[4]]
                                  ? paratype[row[3]][row[4]].map((e) => ({
                                      label: e,
                                    }))
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Parameter Type *"
                                />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                              // disabled={
                              //   plus ||
                              //   !(paratype[row[3]] && paratype[row[3]][row[4]])
                              //     ? true
                              //     : false
                              // }
                            />
                          )}
                          {/* <Autocomplete
                            style={{ width: "150px" }}
                            size="small"
                            id="combo-box-demo"
                            options={
                              paratype[row[3]] && paratype[row[3]][row[4]]
                                ? paratype[row[3]][row[4]].map((e) => ({
                                    label: e,
                                  }))
                                : []
                            }
                            defaultValue={cell}
                            renderInput={(params) => (
                              <TextField {...params} label="Parameter Type *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            disabled={
                              plus ||
                              !(paratype[row[3]] && paratype[row[3]][row[4]])
                                ? true
                                : false
                            }
                          /> */}
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 7) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={acc}
                            value={acc ? null : cell?.split("#")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 8 && !plus) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            value={cell?.split("#")[0]}
                            disabled={plus}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            disabled={plus}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 9 && !plus) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={plus}
                            value={cell?.split("#")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            disabled={plus}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(index, row[0]);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
      </div>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            // props.setLoader(true);
            handleSubmit();
          }}
        >
          Update Standard
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ m: 0, ml: 2 }}
          onClick={() => {
            handleSubmit();
          }}
        >
          Print
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
