import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { BASE_URL } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

const axios = require("axios")

export default function AuditTable() {
    const [page, setPage] = React.useState(0);
    const [totalRows, setTotalRows] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [auditData, setAuditData] = useState([]);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [usersArray, setUsersArray] = React.useState([]);
    const [allUsers, setUsers] = React.useState([]);
    const [datasheetReadings, setDatasheetReadings] = React.useState([]);
    const [finalData, setFinalData] = React.useState([]);
    const [searchBy, setSearchBy] = React.useState("");
    const [searchKey, setSearchKey] = React.useState("");
    const [userType, setUserType] = React.useState(localStorage.getItem("type"))
    const [userId, setUserId] = React.useState(localStorage.getItem("id"))
    const columns =
        [
            {
                id: "id",
                label: "Sr. No.",
                align: "left",
                minWidth: 50
            },
            {
                id: "tablename",
                label: "Table Name",
                align: "left",
                minWidth: 300
            },
            {
                id: "time",
                label: "Time",
                align: "left",
                minWidth: 300
            },
            {
                id: "user",
                label: "user",
                align: "left",
                minWidth: 250
            },
            {
                id: "action",
                label: "Action",
                align: "left",
                minWidth: 100
            },
        ]


    
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }


    function fetchAudit() {
        let whereConditions = ""//"where status = 1 "
        let pagination_settings = ""
        if (searchBy !== "" && searchKey != "") {
            let colName = {
                "Table Name" : "tablename",
                "User Name" : "user"
            }[searchBy]
            whereConditions += `and ${colName} like '%${searchKey}%' `
        }

        if (to != null && from != null) {
            whereConditions += ` and time between '${moment(
                from
            ).format("YYYY-MM-DD")}' and '${moment(to).format("YYYY-MM-DD")}' `
        }

        if (rowsPerPage != -1) {
            pagination_settings = `limit ${rowsPerPage} offset ${rowsPerPage * Number(page)
                }`
        }

        let data = {
            query: `select a.tablename as tablename, usr.userName as user, a.time as time, a.action as action from audit as a 
            join users as usr on usr.id = a.user ${whereConditions} order by usr.id DESC ${pagination_settings}`
        }
        axios
            .post(BASE_URL + `dynamic`, data)
            .then((res) => {
                setAuditData([...res.data])
            })
            .catch((err) => {
                console.log("datasheet data fetching error: ", err)
            })
        axios
            .post(BASE_URL + `dynamic`, {
                query: `select count(a.tablename) as count from audit as a 
        join users as usr on usr.id = a.user ${whereConditions}`
            })
        .then (res => setTotalRows(res.data[0].count ? res.data[0].count : 0))
    }

    var refresh = () => {
        window.location.reload(false)
    }

    const getUserList = () => {
        let url = BASE_URL
        axios
            .get(url + "users?_where=(type,eq,2)")
            .then((res) => {
                setUsersArray(res.data)
            })
            .catch((error) => {
                toast.error("Something Went Wrong!")
            })
    }

    const initializeuserData = () => {
        var pushCArray = []
        for (let i = 0; i < usersArray.length; i++) {
            pushCArray.push({
                label: usersArray[i].id + ", " + usersArray[i].userName
            })
        }
        setUsers(pushCArray)
    }

    const search = () => {
        fetchAudit()
    }

    useEffect(() => {
        getUserList();
    }, []);

    useEffect(() => {
        initializeuserData()
    }, [usersArray])

    useEffect(() => {
        fetchAudit()
    }, [rowsPerPage, page])

    const TablePaginationActions = (props) => {
        const theme = useTheme()
        const { count, page, rowsPerPage, onPageChange } = props

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0)
        }

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1)
        }

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1)
        }

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
        }

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page">
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page">
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page">
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page">
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        )
    }

    return (
        <>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <br />
                <div
                    className="mb-2"
                    style={{ display: "flex", float: "left", justifyContent: "flex-end" }}>

                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
                            <DatePicker
                                label="From"
                                value={from ? new Date(from) : ''}
                                inputFormat="dd/MM/yyyy"
                                format="dd/MM/yyyy"
                                onChange={(newValue) => setFrom(newValue)}
                                renderInput={(params) => <TextField {...params} size="small" />}
                            />
                        </LocalizationProvider>
                    </div>

                    <div style={{ marginRight: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="To"
                                value={to ? new Date(to) : ''}
                                inputFormat="dd/MM/yyyy"
                                format="dd/MM/yyyy"
                                onChange={(newValue) => setTo(newValue)}
                                renderInput={(params) => <TextField {...params} size="small" />}
                                style={{ marginRight: "20px" }}
                            />
                        </LocalizationProvider>
                    </div>

                    <Autocomplete
                        sx={{
                            m: 0,
                            minWidth: 180,
                            marginLeft: "10px"
                        }}
                        size="small"
                        id="combo-box-demo"
                        options={[
                            { key: "user", label: "User Name" },
                            { key: "tablename", label: "Table Name" }
                        ]}
                        renderInput={(params) => <TextField {...params} label="Search By" />}
                        onInputChange={(event, newInputValue) => {
                            setSearchBy(newInputValue)
                        }}
                    />
                    <TextField
                        id="outlined-basic"
                        label={"enter " + searchBy}
                        size="small"
                        variant="outlined"
                        onChange={(e) => setSearchKey(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ m: 0 }}
                        style={{ marginLeft: "10px" }}
                        onClick={() => {
                            search()
                        }}>
                        <SearchIcon />
                    </Button>
                </div>

                <div style={{ margin: "10px" }}>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        class="table-responsive"
                        size="small"
                        id="datatable-keytable">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}>
                                        <b>{column.label}</b>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {auditData &&
                                auditData.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            <TableCell>
                                                {rowsPerPage * Number(page) + index + 1}
                                            </TableCell>

                                            <TableCell>{row.tablename}</TableCell>
                                            <TableCell>{ moment(row.time).format("DD/MM/yyy HH:mm:ss")}</TableCell>
                                            <TableCell>{row.user}</TableCell>
                                            <TableCell>{row.action}</TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        component="div"
                        colSpan={3}
                        SelectProps={{
                            inputProps: {
                                "aria-label": "rows per page"
                            },
                            native: true
                        }}
                        ActionsComponent={TablePaginationActions}
                    />
                    <ToastContainer />
                </div>
            </TableContainer>
        </>

    );
}
